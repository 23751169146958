import React from "react";

import "../../assets/css/pages/incidents/incidents_page.css";
import WorkPlaceTab from "../../components/incidents/workplaceTab.jsx";
import DashBoardContainer from "./dashboardContainer.jsx";
import { ArrowRight01Icon } from "hugeicons-react";
import { Link } from "react-router-dom";
const BreadCrumbs = () => {
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link> <ArrowRight01Icon />
      <Link to={"/incidents/"}>Incidents</Link> <ArrowRight01Icon />
      <Link className="current-page"> Workplace Violence List</Link>
    </div>
  );
};

const WorkPlacePage = ({ content }) => {

  return (
    <div className="dashboard-container">
      <DashBoardContainer
        content={<WorkPlaceTab />}
        breadCrumbs={<BreadCrumbs />}
      />
    </div>
  );
};

export default WorkPlacePage;
