import React from "react";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import api from "../../api";
import { API_URL } from "../../api";
import { Stack } from "@mui/material";
import { PieChart } from "@mui/x-charts";
import { ArrowRight04Icon } from "hugeicons-react";
import { Link } from "react-router-dom";
import ModifyPageLoader from "../../pages/dashboard/incidents/generalIncident/modify/loader";

const LostFoundPieChart = () => {
  const [lostFoundData, setLostFoundData] = useState([]);
  const [failedFetchingOverviewData, setFailedFetchingOverviewData] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchLostFoundData = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/overview/lost-and-found-incident/`
        );
        if (response.status === 200) {
          console.log(response.data);
          setLostFoundData(response.data);
          setIsLoading(false);
        }
      } catch (error) {
        setFailedFetchingOverviewData(true);
        setIsLoading(false);
        console.error(error);
        if (error.response.data) {
          window.customToast.error(error.response.data.message);
        } else {
          window.customToast.error("Failed to fetch incident reports");
        }
      }
    };
    fetchLostFoundData();
  }, []);

  const data = [
    {
      label: "Lost",
      value:
        lostFoundData?.data?.length > 0 && lostFoundData.data[0]?.percentage,
      color: "#145C9E",
    },
    {
      label: "Found",
      value:
        lostFoundData?.data?.length > 0 && lostFoundData.data[1]?.percentage,
      color: "#BBDDFC",
    },
  ];
  function PieChartWithPaddingAngle() {
    return (
      <Stack direction="row" className="pie-chart">
        <PieChart
          series={[
            {
              startAngle: -90,
              endAngle: 90,
              paddingAngle: 5,
              innerRadius: 60,
              outerRadius: 80,
              data,
            },
          ]}
          margin={{ right: 5 }}
          width={200}
          height={200}
          slotProps={{
            legend: { hidden: true },
          }}
        />
        <div className="label-center edit-label-center">
          <span>Incidents</span>
          {/* <h4>{lostFoundData[0] && lostFoundData[0]["Total incidents"]}</h4> */}
          <h4>{lostFoundData?.total_incidents}</h4>
        </div>
      </Stack>
    );
  }
  return isLoading ? (
    <ModifyPageLoader />
  ) : failedFetchingOverviewData ? (
    "Error fetching the data"
  ) : (
    <div className="general-incident-progress">
      <div className="header-row lost-found">
        <h4>Lost and Found Property </h4>
      </div>
      <div className="pie-chart-container">
        <PieChartWithPaddingAngle />

        <div className="stats edit-stats">
          <div className="stat">
            <div className="circle blue"></div>
            <span className="label">Lost:</span>
            <span className="value">
              {lostFoundData.data.length > 0 &&
                lostFoundData.data[0]?.percentage}
              %
            </span>
          </div>
          <div className="stat">
            <div className="circle light-blue"></div>
            <span className="label">Found:</span>
            <span className="value">
              {lostFoundData.data.length > 0 &&
                lostFoundData.data[1]?.percentage}
              %
            </span>
          </div>
        </div>
        <Link className="view-details-orange">View Details</Link>
      </div>
    </div>
  );
};

export default LostFoundPieChart;
