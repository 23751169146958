import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import api, { API_URL, cleanedData, formatDateForBackend } from "../../api";
import { useAuthentication } from "../../contexts/authContext";
import DashboardContainer from "./dashboardContainer";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Keyboard, Pagination, Navigation } from "swiper/modules";
import {
  GoogleDocIcon,
  DocumentAttachmentIcon,
  Settings05Icon,
  JobLinkIcon,
  MedicineSyrupIcon,
  Loading02Icon,
  House01Icon,
  DepartementIcon,
  Calendar01Icon,
  SquareIcon,
  FilterIcon,
} from "hugeicons-react";

import "../../assets/css/pages/overview/overview.css";
import { ArrowRight01Icon } from "hugeicons-react";
import { Link } from "react-router-dom";
import ModifyPageLoader from "./incidents/generalIncident/modify/loader";
import DashboardProgress from "../../components/dashaboard/dashboardProgress";
import DrugReactionPieChart from "../../components/dashaboard/drugReactionPieChart";
import WorkplaceChart from "../../components/dashaboard/workplaceChart";
import ComplaintsTable from "../../components/dashaboard/complaintsTable";
import InvestigationGraph from "../../components/dashaboard/investigationGraph";
import LostFoundPieChart from "../../components/dashaboard/lostFoundPieChart";
import MedicationErrorChart from "../../components/dashaboard/medicationErrorChart";
import StaffPieChart from "../../components/dashaboard/staffPieChart";
import GrievanceGraph from "../../components/dashaboard/grievanceGraph";

const OverViewPageContent = () => {
  const [isFiltering, setIsFiltering] = useState(false);
  const [overviewData, setOverviewData] = useState([]);
  const [numbers, setNumbers] = useState([]);
  const [failedFetchingOverviewData, setFailedFetchingOverviewData] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchOverviewData = async () => {
      try {
        const response = await api.get(`${API_URL}/incidents/overview/`);
        if (response.status === 200) {
          console.log(response.data.overview);
          setOverviewData(response.data.overview);
          setNumbers(response.data.overview.numbers);
          setIsLoading(false);
        }
      } catch (error) {
        setFailedFetchingOverviewData(true);
        setIsLoading(false);
        console.error(error);
        if (error.response.data) {
          window.customToast.error(error.response.data.message);
        } else {
          window.customToast.error("Failed to fetch incident reports");
        }
      }
    };
    fetchOverviewData();
  }, []);

  return isLoading ? (
    <ModifyPageLoader />
  ) : failedFetchingOverviewData ? (
    "Error fetching the data"
  ) : (
    <div className="overview-content">
      <Filters
        setIsFiltering={setIsFiltering}
        isFiltering={isFiltering}
        setNumbers={setNumbers}
      />
      {overviewData.numbers && (
        <div className={`numbers ${isFiltering ? "filtering" : ""}`}>
          <div className="number general">
            <div className="icon">
              <GoogleDocIcon size={24} variant={"stroke"} />
            </div>
            <div className="content">
              <h3 className="number-title">General Incidents</h3>
              <div className="count">{numbers.general} </div>
            </div>
          </div>
          <div className="number grievance">
            <div className="icon">
              <DocumentAttachmentIcon size={24} variant={"stroke"} />
            </div>
            <div className="content">
              <h3 className="number-title">Grievance Incidents</h3>
              <div className="count">{numbers.grievances} </div>
            </div>
          </div>

          <div className="number employee-incident">
            <div className="icon">
              <GoogleDocIcon size={24} variant={"stroke"} />
            </div>
            <div className="content">
              <h3 className="number-title">Staff Incidents</h3>
              <div className="count">{numbers.employee_incidents} </div>
            </div>
          </div>

          <div className="number lost-and-found">
            <div className="icon">
              <Settings05Icon size={24} variant={"stroke"} />
            </div>
            <div className="content">
              <h3 className="number-title">Lost & Found Property report</h3>
              <div className="count">{numbers.lost_and_founds} </div>
            </div>
          </div>
          <div className="number drug-adverse-reaction">
            <div className="icon"></div>
            <div className="content">
              <h3 className="number-title">
                Anaphylaxis/Adverse Drug Reaction
              </h3>
              <div className="count">{numbers.adverse_drug_reactions} </div>
            </div>
          </div>

          <div className="number workplace-violence">
            <div className="icon">
              <JobLinkIcon size={24} variant={"stroke"} />
            </div>
            <div className="content">
              <h3 className="number-title">
                Workplace Violence Incidence Report
              </h3>
              <div className="count">{numbers.workplace_violence} </div>
            </div>
          </div>
          <div className="number medical-error">
            <div className="icon">
              <MedicineSyrupIcon size={24} variant={"stroke"} />
            </div>
            <div className="content">
              <h3 className="number-title">Medical Error/NearMiss</h3>
              <div className="count">{numbers.medical_errors} </div>
            </div>
          </div>
        </div>
      )}
      <div className={`charts-container ${isFiltering ? "filtering" : ""}`}>
        <div className="charts-row">
          <Swiper
            spaceBetween={30}
            keyboard={{
              enabled: true,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Keyboard, Pagination, Navigation]}
            breakpoints={{
              1400: {
                // When the viewport is >= 1024px
                slidesPerView: 3,
                spaceBetween: 30,
              },
              768: {
                // When the viewport is >= 768px
                slidesPerView: 2,
                spaceBetween: 30,
              },
              640: {
                // When the viewport is >= 640px
                slidesPerView: 1,
                spaceBetween: 30,
              },
            }}
            className="mySwiper"
          >
            <SwiperSlide>
              <DashboardProgress />
            </SwiperSlide>
            <SwiperSlide>
              <DrugReactionPieChart />
            </SwiperSlide>
            <SwiperSlide>
              <WorkplaceChart />
            </SwiperSlide>
            <SwiperSlide>
              <LostFoundPieChart />
            </SwiperSlide>
            <SwiperSlide>
              <MedicationErrorChart />
            </SwiperSlide>
            <SwiperSlide>
              <StaffPieChart />
            </SwiperSlide>
            <SwiperSlide>
              <GrievanceGraph />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="graph-row">
          <InvestigationGraph />
          <ComplaintsTable />
        </div>
      </div>
    </div>
  );
};
const BreadCrumbs = () => {
  return (
    <div className="breadcrumbs">
      <ArrowRight01Icon /> <Link className="current-page"> Overview</Link>
    </div>
  );
};
const OverViewPage = () => {
  const { loading, isAuth } = useAuthentication();
  console.log("is auth", isAuth);

  if (loading) {
    // You can return a loading indicator here
    return <div>Loading...</div>;
  }
  return (
    <DashboardContainer
      content={<OverViewPageContent />}
      breadCrumbs={<BreadCrumbs />}
    />
  );
};

export default OverViewPage;

const Filters = ({ setIsFiltering, isFiltering, numbers, setNumbers }) => {
  const [facilities, setFacilities] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [fetchingFacilities, setFetchingFacilities] = useState(false);
  const [fetchingDepartments, setFetchingDepartments] = useState(false);
  const [activeFilter, setActiveFilter] = useState("");
  const [selectedFacility, setSelectedFacility] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedDuration, setSelectedDuration] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");

  const handleActiveFilter = (filter) => {
    setActiveFilter(activeFilter === filter ? "" : filter);
  };

  const handleSelectedFacility = (facility) => {
    setSelectedFacility(selectedFacility === facility ? "" : facility);
    fetchDepartments(facility.id);
  };

  const handleSelectedDepartment = (dept) => {
    setSelectedDepartment(selectedDepartment === dept ? "" : dept);
  };

  const handleSelectedDuration = (duration) => {
    console.log("Selected Duration", duration);

    setSelectedDuration(duration);

    const today = new Date();
    let resultDate = new Date();

    resultDate.setDate(today.getDate() - duration.days);

    const newDateString = resultDate.toISOString().split("T")[0];

    console.log(newDateString);
  };

  const handleSetDuration = (endDate) => {
    setSelectedEndDate(endDate);
    setSelectedDuration({
      days: 2,
      name: `${formatDateForBackend(selectedDate)} - ${formatDateForBackend(
        endDate
      )}`,
    });

    setActiveFilter("");
  };

  const dayOptions = [
    { days: 30, name: "Last 30 Days" },
    { days: 7, name: "Last 7 Days" },
    { days: 1, name: "Today" },
  ];

  const handleFilter = async () => {
    console.log(
      "Filter by",
      selectedFacility.name,
      selectedDepartment.name,
      selectedDate
    );
    const filterData = {
      facility: selectedFacility.id,
      department: selectedDepartment.id,
      date_range: {
        start_date: formatDateForBackend(selectedDate),
        end_date: formatDateForBackend(selectedEndDate),
      },
    };
    try {
      setIsFiltering(true);
      const response = await api.post(
        `/incidents/overview/filter/`,
        cleanedData(filterData)
      );
      if (response.status === 200) {
        setNumbers(response.data.numbers);
        console.log(response.data.numbers);
        setIsFiltering(false);
      }
    } catch (error) {
      console.error(error);
      setIsFiltering(false);
    }
  };

  useEffect(() => {
    const fetchFacilities = async () => {
      try {
        setFetchingFacilities(true);
        const response = await api.get("/facilities/");
        if (response.status === 200) {
          setFacilities(response.data.data);
        }
        setFetchingFacilities(false);
      } catch (error) {
        setFetchingFacilities(false);
      }
    };
    fetchFacilities();
  }, []);

  const fetchDepartments = async (facilityId) => {
    if (facilityId) {
      try {
        setFetchingDepartments(true);
        const response = await api.get(
          `/facilities/${facilityId}/departments/`
        );
        if (response.status === 200) {
          setDepartments(response.data.departments);
          console.log(response.data.departments);
          setFetchingDepartments(false);
        }
      } catch (error) {
        console.error(error);
        setFetchingDepartments(false);
      }
    }
  };
  return (
    <div className="overview-filters">
      <div
        onClick={() => handleActiveFilter("facilities")}
        className={`facilities filter ${
          activeFilter === "facilities" ? "open" : "close"
        }`}
      >
        <House01Icon />
        <span>{selectedFacility.name || "All facilities"}</span>
        {fetchingFacilities ? (
          <Loading02Icon className="loading-icon" />
        ) : (
          <ArrowRight01Icon />
        )}

        <div className="filters-options">
          {facilities && facilities.length > 0
            ? facilities.map((facility, index) => (
                <div
                  key={index}
                  onClick={() => handleSelectedFacility(facility)}
                  className="filter-option"
                >
                  <SquareIcon size={18} />
                  <label htmlFor={facility.name}>{facility.name}</label>
                </div>
              ))
            : ""}
        </div>
      </div>

      <div
        onClick={() => handleActiveFilter("departments")}
        className={`departments filter ${
          activeFilter === "departments" ? "open" : "close"
        }`}
      >
        <DepartementIcon />
        <span>{selectedDepartment.name || "All departments"}</span>
        {fetchingDepartments ? (
          <Loading02Icon className="loading-icon" />
        ) : (
          <ArrowRight01Icon />
        )}
        <div className="filters-options">
          {departments &&
            departments.map((department, index) => (
              <div
                key={index}
                onClick={() => handleSelectedDepartment(department)}
                className="filter-option"
              >
                <SquareIcon size={18} />
                <label htmlFor={department.name}>{department.name}</label>
              </div>
            ))}
        </div>
      </div>

      <div
        onClick={() => handleActiveFilter("dates")}
        className={`dates filter ${
          activeFilter === "dates" ? "open" : "close"
        }`}
      >
        <Calendar01Icon />
        <span>{selectedDuration.name || "Last 30 days"}</span>
        <ArrowRight01Icon />
        <div className="filters-options">
          {dayOptions.map((option, index) => (
            <div
              key={index}
              onClick={() => handleSelectedDuration(option)}
              className="filter-option"
            >
              <SquareIcon size={18} />
              <label htmlFor={option.name}>{option.name}</label>
            </div>
          ))}
          <div className="date-range">
            From
            <input
              onClick={(e) => e.stopPropagation()}
              type="date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
            />
            To
            <input
              onClick={(e) => e.stopPropagation()}
              type="date"
              value={selectedEndDate}
              onChange={(e) => handleSetDuration(e.target.value)}
            />
          </div>
        </div>
      </div>

      <button
        disabled={isFiltering}
        onClick={handleFilter}
        type="button"
        className="secondary-button"
      >
        <FilterIcon />
        Apply filters
      </button>
    </div>
  );
};
