import React, { useEffect, useState } from "react";
import "../../assets/css/main/main.css";
import api, { API_URL, exportExcel } from "../../api";

import TableActionsPopup from "../general/popups/tableActionPopup";
import {
  MoreHorizontalSquare01Icon,
  FilterHorizontalIcon,
  CancelSquareIcon,
  PencilEdit01Icon,
  PencilEdit02Icon,
  EyeIcon,
  SquareIcon,
  Tap01Icon,
  CheckmarkSquare01Icon,
  FileExportIcon,
} from "hugeicons-react";
import { useNavigate } from "react-router-dom";
import SliceText from "../general/sliceText";
import DateFormatter from "../../pages/dashboard/incidents/dateFormatter";
import CustomSelectInput from "./forms/inputs/customSelect";
import CustomDatePicker from "./forms/inputs/datePicker";
import {
  useDepartments,
  usePermission,
} from "../../contexts/permissionsContext";
import { SortByNumberIcon, SortDateIcon } from "./incidentaTab";
const handleSearch = (items, searchString) => {
  if (searchString.length > 2) {
    const results = items.filter((item) => {
      return (
        (item.patient_visitor_name &&
          item.patient_visitor_name
            .toLowerCase()
            .includes(searchString.toLowerCase())) ||
        (item.incident_type &&
          item.incident_type
            .toLowerCase()
            .includes(searchString.toLowerCase())) ||
        (item.follow_up &&
          item.follow_up.toLowerCase().includes(searchString.toLowerCase()))
      );
    });
    return results;
  }

  return [];
};

const IncidentsTab = () => {
  const [errorFetching, setErrorFetching] = useState("");
  const [isFetching, setIsFetching] = useState(true);
  const [incidentData, setIncidentData] = useState([]);
  const [searchResults, setSearchResults] = useState("");
  const [resultsFound, setResultsFound] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [filterByDate, setFilterByDate] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [openActionIndex, setOpenActionIndex] = useState("");

  const [selectedItems, setSelectedItems] = useState([]);
  const [isSearchingTheDatabase, setIsSearchingTheDatabase] = useState(false);

  const navigate = useNavigate();
  const permissions = usePermission();
  const department = useDepartments();
  const [data, setData] = useState([]); // To hold the table data // To hold filtered data
  const [filters, setFilters] = useState({
    start_date: "",
    end_date: "",
    status: "",
  });

  // Fetch data from the backend

  // Handle filter application
  const applyFilters = () => {
    const newFilteredData = data.filter((item) => {
      const incidentDate = new Date(item.date_of_incident);
      const startDate = filters.start_date
        ? new Date(filters.start_date)
        : null;
      const endDate = filters.end_date ? new Date(filters.end_date) : null;

      const withinDateRange =
        (!startDate || incidentDate >= startDate) &&
        (!endDate || incidentDate <= endDate);

      return (
        withinDateRange &&
        (!filters.status.toLowerCase() ||
          item.status.toLowerCase() === filters.status.toLowerCase())
      );
    });
    console.log("filters", filters);
    console.log("new filtered data", newFilteredData);
    setIncidentData(newFilteredData); // Update filtered data state
    toggleOpenFilters();
  };

  // Clear filters
  const clearFilters = () => {
    setFilters({
      start_date: "",
      end_date: "",
      status: "",
    });
    setIncidentData(data); // Reset filtered data to all data
  };
  const toggleAction = (index) => {
    setOpenActionIndex(index);
    setOpenAction(!openAction);
  };
  const handleRowClick = (incidentId) => {
    navigate(`/incident/workplace_violence/${incidentId}`);
  };
  const navigateToModify = (incidentId) => {
    navigate(`/incident/workplace_violence/${incidentId}/modify/`);
  };

  const handleNonClickableColumnClick = (event) => {
    event.stopPropagation();
  };

  const search = (string) => {
    setIsSearching(true);
    const results = incidentData.filter(
      (item) =>
        (item.report_facility &&
          item.report_facility?.name
            .toLowerCase()
            .includes(string.toLowerCase())) ||
        (item.incident_typ &&
          item.incident_type.toLowerCase().includes(string.toLowerCase()))
    );

    if (results.length < 1) {
      setIsSearchingTheDatabase(true);
      setTimeout(() => {
        setIsSearchingTheDatabase(false);
      }, 3000);
    }

    setSearchResults(results);
  };

  const toggleOpenFilters = () => {
    setOpenFilters(!openFilters);
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = String(date.getFullYear()).slice(0);
    return `${year}-${month}-${day}`;
  }

  const handleSelectedItems = (item) => {
    if (!selectedItems.includes(item)) {
      setSelectedItems([...selectedItems, item]);
    } else {
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem.id !== item.id)
      );
    }
  };

  const handleSelectAll = (items) => {
    if (selectedItems !== items) {
      setSelectedItems(items);
    } else {
      setSelectedItems([]);
    }
  };

  useEffect(() => {
    const fetchIncidentData = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/workplace_violence/`
        );
        if (response.status === 200) {
          const formattedData = response.data.incidents.map((item) => ({
            ...item,
            date_of_incident: formatDate(item.date_of_incident),
          }));
          setIncidentData(formattedData);
          setData(formattedData);
          setIsFetching(false);
          console.log(response.data.incidents);
        }
      } catch (error) {
        if (error.response) {
          setErrorFetching(error.response.data.error);
          setIsFetching(false);
        } else {
          setErrorFetching("An error occurred while fetching incident data.");
          setIsFetching(false);
        }
      }
    };
    fetchIncidentData();
    setIsFetching(false);
  }, []);

  return isFetching ? (
    <div className="getting-data">
      <p>Getting data..</p>
    </div>
  ) : permissions.includes("Super User") ||
    permissions.includes("Admin") ||
    (permissions.includes("Manager") &&
      department &&
      department.includes("Human Resources")) ||
    (permissions.includes("Director") &&
      department &&
      department.includes("Human Resources")) ? (
    <div>
      {errorFetching ? (
        <div className="error-message">
          <p>{errorFetching}</p>
        </div>
      ) : (
        <div className="tab-container incidents-tab">
          <div className="tab-header">
            <div className="title-container-action">
              <div className="title-container">
                <h2 className="title">Workplace Violence List</h2>
                <p>15 incident available </p>
              </div>
            </div>

            <div className="filters">
              {openFilters ? (
                <div className="filters_popup">
                  <div onClick={toggleOpenFilters} className="close-icon">
                    <CancelSquareIcon size={24} variant={"stroke"} />
                  </div>

                  <h3>Filter incident data</h3>
                  <div className="filter-buttons">
                    <CustomSelectInput
                      options={["Draft", "Open", "Closed"]}
                      placeholder={"Filter by status"}
                      selected={filters.status}
                      setSelected={(value) =>
                        setFilters({ ...filters, status: value })
                      }
                      name="status"
                      id="status"
                    />

                    <div className="filter-range">
                      <span>Start date</span>
                      <CustomDatePicker
                        selectedDate={filters.start_date}
                        setSelectedDate={(value) =>
                          setFilters({ ...filters, start_date: value })
                        }
                        placeholderText="Select a date"
                        dateFormat="yyyy-MM-dd"
                      />
                    </div>

                    <div className="filter-range">
                      <span>End date</span>
                      <CustomDatePicker
                        selectedDate={filters.end_date}
                        setSelectedDate={(value) =>
                          setFilters({ ...filters, end_date: value })
                        }
                        placeholderText="Select a date"
                        dateFormat="yyyy-MM-dd"
                      />
                    </div>

                    <div className="pop-up-buttons">
                      <button onClick={clearFilters} className="outline-button">
                        <CancelSquareIcon size={20} variant={"stroke"} />
                        Clear
                      </button>
                      <button
                        onClick={applyFilters}
                        className="secondary-button"
                      >
                        <div className="icon">
                          <FilterHorizontalIcon size={20} variant={"stroke"} />
                        </div>
                        <span>Filter</span>
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <input
                onChange={(e) => {
                  search(e.target.value);
                }}
                // value={searchString}
                type="search"
                name="systemSearch"
                id="systemSearch"
                placeholder="Search the system"
              />

              {selectedItems.length > 0 ? (
                permissions.includes("Super User") ||
                permissions.includes("Admin") ||
                permissions.includes("Quality - Risk Manager") ||
                (permissions.includes("Manager") &&
                  department &&
                  department.includes("Human Resources")) ||
                (permissions.includes("Director") &&
                  department &&
                  department.includes("Human Resources")) ? (
                  <button
                    onClick={() =>
                      exportExcel(selectedItems, "wp_incident_list")
                    }
                    className="secondary-button"
                  >
                    {" "}
                    <FileExportIcon /> <span>Export</span>
                  </button>
                ) : (
                  ""
                )
              ) : (
                <button
                  onClick={toggleOpenFilters}
                  className="date-filter-button"
                >
                  <div className="icon">
                    <FilterHorizontalIcon variant={"stroke"} />
                  </div>
                  <span>Filter</span>
                </button>
              )}
            </div>
          </div>

          <div className="incident-list">
            {isSearching ? (
              <div className="search-results">
                {isSearchingTheDatabase ? (
                  <div className="searching_database">
                    <p>Searching database</p>
                  </div>
                ) : searchResults && searchResults.length > 0 ? (
                  <div className="results-table">
                    <div className="results-count">
                      <span className="count">{searchResults.length}</span>{" "}
                      results found
                    </div>
                    <>
                      <WorkPlaceViolenceTable
                        incidentData={searchResults}
                        handleSelectAll={handleSelectAll}
                        selectedItems={selectedItems}
                        handleSelectedItems={handleSelectedItems}
                        handleNonClickableColumnClick={
                          handleNonClickableColumnClick
                        }
                        navigateToModify={navigateToModify}
                        handleRowClick={handleRowClick}
                        setIncidentData={setSearchResults}
                      />
                      <div className="mobile-table">
                        {permissions.includes("Super User") ||
                        permissions.includes("Admin") ||
                        permissions.includes("Quality - Risk Manager") ||
                        (permissions.includes("Manager") &&
                          department &&
                          department.includes("Human Resources")) ||
                        (permissions.includes("Director") &&
                          department &&
                          department.includes("Human Resources")) ? (
                          <button
                            onClick={() => handleSelectAll(searchResults)}
                            type="button"
                            className="tertiary-button"
                          >
                            {" "}
                            {selectedItems === searchResults ? (
                              <CheckmarkSquare01Icon />
                            ) : (
                              <SquareIcon />
                            )}{" "}
                            Select all
                          </button>
                        ) : (
                          ""
                        )}

                        {searchResults &&
                          searchResults.map((incident, index) => (
                            <IncidentTableCard
                              key={index}
                              incident={incident}
                              handleRowClick={handleRowClick}
                              selectedItems={selectedItems}
                              handleSelectedItems={handleSelectedItems}
                            />
                          ))}
                      </div>
                    </>
                  </div>
                ) : (
                  <div className="no-data-found">
                    <p>No data found with your search found</p>
                  </div>
                )}
              </div>
            ) : (
              <>
                <WorkPlaceViolenceTable
                  incidentData={incidentData}
                  handleSelectAll={handleSelectAll}
                  selectedItems={selectedItems}
                  handleSelectedItems={handleSelectedItems}
                  handleNonClickableColumnClick={handleNonClickableColumnClick}
                  navigateToModify={navigateToModify}
                  handleRowClick={handleRowClick}
                  setIncidentData={setIncidentData}
                />
                <div className="mobile-table">
                  {permissions.includes("Super User") ||
                  permissions.includes("Admin") ||
                  permissions.includes("Quality - Risk Manager") ||
                  (permissions.includes("Manager") &&
                    department &&
                    department.includes("Human Resources")) ||
                  (permissions.includes("Director") &&
                    department &&
                    department.includes("Human Resources")) ? (
                    <button
                      onClick={() => handleSelectAll(incidentData)}
                      type="button"
                      className="tertiary-button"
                    >
                      {" "}
                      {selectedItems === incidentData ? (
                        <CheckmarkSquare01Icon />
                      ) : (
                        <SquareIcon />
                      )}{" "}
                      Select all
                    </button>
                  ) : (
                    ""
                  )}

                  {incidentData &&
                    incidentData.map((incident, index) => (
                      <IncidentTableCard
                        key={index}
                        incident={incident}
                        handleRowClick={handleRowClick}
                        selectedItems={selectedItems}
                        handleSelectedItems={handleSelectedItems}
                      />
                    ))}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className="no-access-text">You don't have access to this page</div>
  );
};
const WorkPlaceViolenceTable = ({
  incidentData,
  handleNonClickableColumnClick,
  handleRowClick,
  navigateToModify,
  selectedItems,
  handleSelectedItems,
  handleSelectAll,
  setIncidentData,
}) => {
  const permission = usePermission();
  const department = useDepartments();
  const [sortDesc, setSortDesc] = useState(false);
  const [nameAZ, setNameAZ] = useState(false);
  const [dateRecent, setDateRecent] = useState(false);

  const handleSortById = () => {
    const results = handleSorting(
      incidentData,
      "number",
      sortDesc ? "desc" : "asc",
      "id"
    );
    setIncidentData(results);
    setSortDesc(!sortDesc);
  };

  const handleSortByName = () => {
    const results = handleSorting(
      incidentData,
      "name",
      nameAZ ? "desc" : "asc",
      "name"
    );
    setIncidentData(results);
    setNameAZ(!nameAZ);
  };

  const handleFilterByDate = () => {
    const results = handleSorting(
      incidentData,
      "datetime",
      dateRecent ? "desc" : "asc",
      "date"
    );
    setIncidentData(results);
    setDateRecent(!dateRecent);
  };

  const handleSorting = (items, sortBy, direction, field) => {
    console.log(items);
    console.log("sorting items:", sortBy, direction, field);
    const sortByNumber = (field, direction) => {
      return [...items].sort((a, b) => {
        // Prioritize based on the presence of `original_report`
        const aValue = a.original_report ? a.original_report : a.id;
        const bValue = b.original_report ? b.original_report : b.id;

        // Ascending or descending sort
        const result = aValue - bValue;
        return direction && direction === "asc" ? result : -result;
      });
    };

    const sortByFacilityName = (field) => {
      return [...items].sort((a, b) => {
        const nameA = a.patient_name?.user?.first_name || "";
        const nameB = b.patient_name?.user?.first_name || "";
        const result = nameA.localeCompare(nameB);
        return direction === "asc" ? result : -result;
      });
    };

    const sortByDateTime = (field) => {
      return [...items].sort((a, b) => {
        const dateA = new Date(a.date_of_incident);
        const dateB = new Date(b.date_of_incident);
        const result = dateA - dateB;
        return direction === "asc" ? result : -result;
      });
    };

    switch (sortBy) {
      case "number":
        return sortByNumber(field);
      case "name":
        return sortByFacilityName(field);
      case "datetime":
        return sortByDateTime(field);
      default:
        return items; // Return unsorted if the sortBy criteria doesn't match
    }
  };
  return (
    <table>
      <thead>
        <tr>
          {permission.includes("Super User") ||
          permission.includes("Admin") ||
          (permission.includes("Manager") &&
            department &&
            department.includes("Human Resources")) ||
          (permission.includes("Director") &&
            department &&
            department.includes("Human Resources")) ? (
            <th>
              <div onClick={() => handleSelectAll(incidentData)}>
                {" "}
                {selectedItems === incidentData ? (
                  <CheckmarkSquare01Icon />
                ) : (
                  <SquareIcon />
                )}
              </div>
            </th>
          ) : (
            ""
          )}

          <th>No</th>
          <th className="sort-cell">
            ID
            <SortByNumberIcon
              setSortDesc={setSortDesc}
              handleSortById={handleSortById}
              sortDesc={sortDesc}
            />{" "}
          </th>
          <th>Facility</th>
          <th>Type of incident</th>
          <th>
            Physical injury <br /> description
          </th>
          <th className="sort-cell">
            Incident data & time
            <SortDateIcon
              setSortDesc={setDateRecent}
              handleSortById={handleFilterByDate}
              sortDesc={dateRecent}
            />
          </th>

          <th>Severity</th>
          <th>Status</th>
          <th className="action-col">Action</th>
        </tr>
      </thead>
      <tbody>
        {incidentData.length > 0 ? (
          incidentData.map((incident, index) => (
            <tr
              onDoubleClick={() =>
                handleRowClick(
                  incident.original_report
                    ? incident.original_report
                    : incident.id
                )
              }
              key={index}
              className={`table-card ${
                selectedItems.includes(incident) ? "selected" : ""
              }`}
            >
              {permission.includes("Super User") ||
              permission.includes("Admin") ||
              (permission.includes("Manager") &&
                department &&
                department.includes("Human Resources")) ||
              (permission.includes("Director") &&
                department &&
                department.includes("Human Resources")) ? (
                <td>
                  <div
                    onClick={() => handleSelectedItems(incident)}
                    className="icon"
                  >
                    {selectedItems.includes(incident) ? (
                      <CheckmarkSquare01Icon color="orange" />
                    ) : (
                      <SquareIcon />
                    )}
                  </div>
                </td>
              ) : (
                ""
              )}

              <td>{index + 1}</td>
              <td>{incident.original_report || incident.id} </td>
              <td>{incident.report_facility?.name || "Not provided"}</td>
              <td>{incident.incident_type || "Not provided"}</td>
              <td>
                <SliceText
                  text={incident.physical_injury_description || "Not provided"}
                  maxLength={20}
                />
              </td>
              <td>
                {(
                  <div>
                    <DateFormatter dateString={incident.date_of_incident} />
                    ,&nbsp; {incident.time_of_incident}
                  </div>
                ) || "-"}
              </td>

              <td>{incident.severity_level || "Not Provided"}</td>
              <td>
                <p
                  className={`follow-up ${
                    incident.status === "Draft"
                      ? "in-progress"
                      : incident.status === "Closed"
                      ? "closed"
                      : "Open"
                  }`}
                >
                  {incident.status || "Not specified"}
                </p>
              </td>
              <td
                onClick={(event) => handleNonClickableColumnClick(event)}
                className="action-col"
              >
                <div className="table-actions">
                  {(permission.includes("Super User") ||
                    permission.includes("Admin") ||
                    permission.includes("Manager")) &&
                    !incident.is_resolved && (
                      <PencilEdit02Icon
                        size={20}
                        onClick={() =>
                          navigateToModify(
                            incident.original_report
                              ? incident.original_report
                              : incident.id
                          )
                        }
                      />
                    )}

                  {permission.includes("Super User") ||
                  permission.includes("Admin") ||
                  (permission.includes("Manager") &&
                    department &&
                    department.includes("Human Resources")) ||
                  (permission.includes("Director") &&
                    department &&
                    department.includes("Human Resources")) ? (
                    <EyeIcon
                      size={20}
                      onClick={() =>
                        handleRowClick(
                          incident.original_report
                            ? incident.original_report
                            : incident.id
                        )
                      }
                    />
                  ) : (
                    ""
                  )}
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td>No data found</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
export default IncidentsTab;

const IncidentTableCard = ({
  incident,
  items,
  handleRowClick,
  selectedItems,
  handleSelectedItems,
}) => {
  const permission = usePermission();
  const department = useDepartments();
  return (
    <div
      className={`table-card ${
        selectedItems.includes(incident) ? "selected" : ""
      }`}
    >
      <div className="card-header">
        <div className="id-number">
          {permission.includes("Super User") ||
          permission.includes("Admin") ||
          (permission.includes("Manager") &&
            department &&
            department.includes("Human Resources")) ||
          (permission.includes("Director") &&
            department &&
            department.includes("Human Resources")) ? (
            <div onClick={() => handleSelectedItems(incident)} className="icon">
              {selectedItems.includes(incident) ? (
                <CheckmarkSquare01Icon color="orange" />
              ) : (
                <SquareIcon />
              )}
            </div>
          ) : (
            ""
          )}

          <span>ID</span>
          <span>{incident.original_report || incident.id} </span>
        </div>

        <div
          onClick={() =>
            handleRowClick(
              incident.original_report ? incident.original_report : incident.id
            )
          }
          className="card-actions"
        >
          <Tap01Icon />
          <span>View more</span>
        </div>
      </div>
      {items}
      <div className="card-content-items">
        <div className="item">
          <label htmlFor="">Facility: </label>
          <span>{incident?.report_facility?.name || "Not provided"}</span>
        </div>
        <div className="item">
          <label htmlFor="">Incident Type: </label>
          <span>{incident?.incident_type || "Not provided"}</span>
        </div>
        <div className="item">
          <label htmlFor="">Physical injury description: </label>
          <span>{incident?.physical_injury_description}</span>
        </div>
        <div className="item">
          <label htmlFor="">Incident Date & Time: </label>
          <span>
            {" "}
            {(
              <span>
                <DateFormatter dateString={incident?.date_of_incident} />,
                &nbsp; {incident?.time_of_incident}
              </span>
            ) || "-"}
          </span>
        </div>

        <div className="item">
          <label htmlFor="">Severity: </label>
          <span>{incident?.severity_rating || "Not provided"}</span>
        </div>
        <div className="item">
          <label htmlFor="">Status: </label>
          <span
            className={`follow-up ${
              incident.status === "Draft"
                ? "in-progress"
                : incident.status === "Closed"
                ? "closed"
                : "Open"
            }`}
          >
            {incident?.status || "Not specified"}
          </span>
        </div>
      </div>
    </div>
  );
};
