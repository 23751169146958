import { Loading02Icon } from 'hugeicons-react'
import React, { useState } from 'react'
import LogoText from '../../components/branding/logoText'

const VerifyCode = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [passwordResetCode, setPasswordResetCode] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")


    const handleVerifyCode = () => {
        if (!passwordResetCode) {
            setErrorMessage("Please enter the verification code")
            return
        }
        setIsLoading(true)
        // verify code with the sent code
        setTimeout(() => {
            setIsLoading(false)
            window.location.href = "/forgot-password/new-password/"
        }, 2000)
    }

    return (
        <div className='auth-pages'>
            {/* verify code */}
            <div className='form'>
                <LogoText text={'Verify code'} />
                <h1>Enter the code that was sent to your email</h1>
                {
                    errorMessage && <div className="error-message">{errorMessage}</div>
                }
                {
                    successMessage && <div className="success-message">{successMessage}</div>
                }
                <input onChange={(e => setPasswordResetCode(e.target.value))} type="text" placeholder="Verification code" />
                <button onClick={handleVerifyCode} type='button'>{isLoading ? <Loading02Icon size={18} className='loading-icon' /> : 'Verify code'}</button>
            </div>
        </div>
    )
}

export default VerifyCode
