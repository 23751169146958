import toast from "react-hot-toast";
import React, { useEffect, useState, useRef } from "react";
import { validateStep } from "../../validators/generalIncidentForm";

import Grivance from "../../../successMessage/grivance";
import api, { API_URL, TINYEMCE_API_KEY } from "../../../../api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { Editor } from "@tinymce/tinymce-react";
import mediaAPI from "../../../../mediaApi";
import CustomDatePicker from "../inputs/datePicker";
import RichTexField from "../inputs/richTexField";
import { Cancel01Icon, FloppyDiskIcon, Loading02Icon } from "hugeicons-react";
import { MAX_FILE_SIZE } from "../../../../api";
import postDocumentHistory from "../documentHistory/postDocumentHistory";
import FormCompleteMessage from "../formCompleteMessage";
import BackToPage from "../../backToPage";
import FormatDate from "../../../general/formatDateTime";
import { usePermission } from "../../../../contexts/permissionsContext";
import DateFormatter from "../../../../pages/dashboard/incidents/dateFormatter";

const PartiesInvolved = ({ data, handleRemovePartyInvolved }) => {
  return data && data.length > 0 ? (
    <div className="parties-involved-list">
      {data.map((party, index) => (
        <div key={index} className="party">
          <span>{party.name}</span>
          <div
            onClick={() => handleRemovePartyInvolved(index)}
            className="icon"
          >
            <Cancel01Icon size={18} fontVariant={"stroke"} />
          </div>
        </div>
      ))}
    </div>
  ) : (
    ""
  );
};
const ModifyGrievanceInvestigationForm = ({ data, incidentId }) => {
  const permission = usePermission();
  const [incident, setIncident] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const currentStepRef = useRef(currentStep);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    investigativeFindings: "",
    conductedBy: data.conducted_by,
    startDate: data.start_date,
    endDate: "",
    review: data.review,
    actionsTaken: "",
    feedbackDate: "",
    personAtMeeting: "",
    relationship: "",
    meeting: false,
    telephone: false,
    extensionLetterDate: "",
    responseLetterDate: "",
    dateClosed: "",
  });
  const [content, setContent] = useState(data.findings);
  const [startDate, setStartDate] = useState(data.start_date);
  const [endDate, setEndDate] = useState(data.end_date);
  const [findingsInterviews, setFindingsInterviews] = useState(
    data.interviews_findings
  );
  const [medicalRecordFindings, setMedicalRecordFindings] = useState(
    data.medical_record_findings
  );

  const [conclusions, setConclusions] = useState(data.conclusion);
  const [actionsTaken, setActionsTaken] = useState(data.action_taken);
  const [feedbackDate, setFeedbackDate] = useState(data.date_of_feedback);
  const [feedback, setFeedBack] = useState(data.feedback);

  const [partiesInvolved, setPartiesInvolved] = useState(data.involved_parties);
  const [newPartyName, setNewPartyName] = useState("");
  const [newPartyRelationship, setNewPartyNameRelationship] = useState("");
  const [partyInvolved, setPartyInvolved] = useState({});
  const [severityRating, setSeverityRating] = useState(data.severity_rating);
  const [extensionLaterFile, setExtensionLaterFile] = useState("");
  const [responseLaterFile, setResponseLaterFile] = useState("");
  const [extensionLetterDate, setExtensionLetterDate] = useState(
    data.date_extension_letter_sent
  );
  const [responseLetterDate, seRResponseLetterDate] = useState(
    data.date_response_letter_sent
  );
  const [dateClosed, setDateClosed] = useState(data.date_matter_closed);
  const responseLetterFileInputRef = useRef("");
  const extensionLetterFileInputRef = useRef("");
  const [files1, setFiles1] = useState([]);
  const [files2, setFiles2] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadingDocuments, setUploadingDocuments] = useState(false);

  const handleFileChange1 = (event) => {
    setFiles1(event.target.files);
  };

  const handleFileChange2 = (event) => {
    setFiles2(event.target.files);
  };

  useEffect(() => {
    // get documents
    const fetchIncidentDocuments = async () => {
      try {
        const response = await api.get(
          `/incidents/grievance-investigation/${data.id}/documents/`
        );
        if (response.status === 200) {
          setUploadedFiles(response.data);
          console.log("documents updated successfully");
          console.log(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchIncidentDocuments();
  }, []);

  const handleFileChange = async (event) => {
    const formData = new FormData();
    const files = event.target.files;

    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    try {
      setUploadingDocuments(true);
      console.log([...formData]);

      const response = await mediaAPI.post(
        `/incidents/grievance-investigation/${data.id}/documents/new/`,
        formData
      );

      if (response.status === 200 || response.status === 201) {
        console.log(response.data.files);
        setUploadingDocuments(false);
        window.customToast.success("Files uploaded successfully");
        setUploadedFiles(response.data.files);
      }
    } catch (error) {
      window.customToast.error("Error uploading documents");
      setUploadingDocuments(false);
      console.log(error);
    }
  };

  async function handleFileSubmit(incidentId) {
    setIsLoading(true);
    setUploadingDocuments(true);
    const formData2 = new FormData();
    for (const file1 of files1) {
      formData2.append("files", file1);
    }
    for (const file2 of files2) {
      formData2.append("files", file2);
    }

    try {
      const response = await mediaAPI.post(
        `${API_URL}/incidents/grievance-investigation/${incidentId}/documents/new/`,
        formData2,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        window.customToast.success("Media Posted Successfully");
        console.log(response);

        setUploadedFiles(response.data.files);

        const incidentData = {
          findings: content,
          conducted_by: formData.conductedBy,
          start_date: startDate,
          end_date: endDate,
          interviews_findings: findingsInterviews,
          medical_record_findings: medicalRecordFindings,
          feedback: feedback,
          review: formData.review,
          action_taken: actionsTaken,
          date_of_feedback: feedbackDate,
          conclusion: conclusions,
          involved_parties: partiesInvolved,
          date_extension_letter_sent: extensionLetterDate,
          extension_letter_sent: true,
          extension_letter_copy: response.data.files[0].url,
          date_response_letter_sent: responseLetterDate,
          response_letter_copy: response.data.files[1].url,
          date_matter_closed: dateClosed,
        };

        handleModify(incidentData);
        setIsLoading(false);
        setUploadingDocuments(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }
  //   const handleExtensionLaterFile = (event) => {
  //     const file = event.target.files[0];

  //     if (file && file.size > MAX_FILE_SIZE) {
  //       window.customToast.error("File must not exceed 12MB");
  //       setExtensionLaterFile("");
  //       if (extensionLetterFileInputRef.current) {
  //         extensionLetterFileInputRef.current.value = "";
  //       }
  //       return;
  //     }
  //     setExtensionLaterFile(event.target.files[0]);
  //   };

  //   const handleResponseLaterFile = (event) => {
  //     setResponseLaterFile(event.target.files[0]);
  //     const file = event.target.files[0];

  //     if (file && file.size > MAX_FILE_SIZE) {
  //       window.customToast.error("File must not exceed 12MB");
  //       setExtensionLaterFile("");
  //       if (responseLetterFileInputRef.current) {
  //         responseLetterFileInputRef.current.value = "";
  //       }
  //       return;
  //     }
  //   };
  const handlePartyInvolved = (name, relationship_to_patient) => {
    if (name === "" || relationship_to_patient === "") {
      window.customToast.error(
        "Please enter a valid party name and relationship"
      );
      return;
    }
    const newPartyInvolved = {
      name,
      relationship_to_patient,
    };

    setPartiesInvolved([...partiesInvolved, newPartyInvolved]);
    setNewPartyName("");
    setNewPartyNameRelationship("");
    setPartyInvolved([]);
    console.log(partiesInvolved);
  };

  const handleRemovePartyInvolved = (index) => {
    setPartiesInvolved(partiesInvolved.filter((_, i) => i !== index));
    console.log(partiesInvolved);
  };
  const handleConclusions = (content, editor) => {
    setConclusions(content);
  };

  const handleActionsTaken = (content, editor) => {
    setActionsTaken(content);
  };

  const handleEditorChange = (content, editor) => {
    setContent(content);
  };
  const handleInputChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]:
        type === "checkbox" ? checked : type === "file" ? files[0] : value,
    }));
  };

  const handleModify = async (incidentData) => {
    setIsLoading(true);

    try {
      const response = await api.patch(
        `incidents/grievance-investigation/${incidentId}/modify/`,
        incidentData
      );
      console.log("response: " + response);
      if (response.status === 200) {
        setIsLoading(false);
        window.customToast.success("Incident updated successfully");
        setIncident(response.data);
        console.log(response.data);
        console.log("Modified parties: ", partiesInvolved);

        postDocumentHistory(incidentId, "modified this incident", "modify");
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      if (error.response) {
        window.customToast.error(
          error.response.data.message ||
            error.response.data.error ||
            "Error while updating the incident"
        );
      } else {
        window.customToast.error("Unknown error while updating the incident");
      }
    }
  };
  return (
    <div className="modify-incident">
      <div className="modify-page-header">
        <BackToPage
          link={"/incident/grievance/investigation/"}
          pageName={"Grievance Investigation incident"}
        />
        <h2 className="title">Modifying Grievance Investigation Incident</h2>

        <button
          className="tertiary-button"
          onClick={() => {
            handleFileSubmit(incidentId);
          }}
        >
          {isLoading ? (
            <>
              <Loading02Icon className="loading-icon" size={18} />
              <span>Saving changes</span>
            </>
          ) : (
            <>
              <FloppyDiskIcon size={20} />
              <span>Save changes</span>
            </>
          )}
        </button>
      </div>
      <form>
        <div className="stepn inputs-group">
          <div className="field">
            <label htmlFor="investigativeFindings">Interviews</label>
            <RichTexField
              value={findingsInterviews}
              onEditorChange={setFindingsInterviews}
            />
          </div>

          <div className="field">
            <label htmlFor="investigativeFindings">
              Medical Record Findings
            </label>
            <RichTexField
              value={medicalRecordFindings}
              onEditorChange={setMedicalRecordFindings}
            />
          </div>
          <div className="field">
            <label htmlFor="investigativeFindings">
              Other Observation/Findings
            </label>
            <RichTexField value={content} onEditorChange={handleEditorChange} />
          </div>
          <div className="field">
            <label htmlFor="conductedBy">Investigation conducted by :</label>
            <input
              type="text"
              name="conductedBy"
              id="conductedBy"
              onChange={handleInputChange}
              value={formData.conductedBy}
              placeholder="Enter name"
            />
          </div>

          <div className="half">
            <div className="field">
              <label htmlFor="">Start date</label>
              <CustomDatePicker
                selectedDate={startDate}
                setSelectedDate={setStartDate}
              />
            </div>
            <div className="field">
              <label htmlFor="">End date</label>
              <CustomDatePicker
                selectedDate={endDate}
                setSelectedDate={setEndDate}
              />
            </div>
          </div>
        </div>
        <div className="step inputs-group">
          <div className="field">
            <label htmlFor="review">Conclusions of Reviews</label>
            <RichTexField
              value={conclusions}
              onEditorChange={handleConclusions}
            />
          </div>
          <div className="field">
            <label htmlFor="actions">Actions Taken</label>
            <RichTexField
              value={actionsTaken}
              onEditorChange={handleActionsTaken}
            />
          </div>
          <div className="half">
            <div className="field">
              <h3>Feedback to Patient and Family</h3>
              <div className="check-boxes">
                <div className="check-box">
                  <input
                    type="checkbox"
                    name="meeting"
                    id="meeting"
                    onChange={() => setFeedBack("Meeting")}
                    checked={feedback === "Meeting"}
                  />
                  <label htmlFor="meeting">Meeting</label>
                </div>
                <div className="check-box">
                  <input
                    type="checkbox"
                    name="telephone"
                    id="telephone"
                    onChange={() => setFeedBack("Telephone")}
                    checked={feedback === "Telephone"}
                  />
                  <label htmlFor="telephone">Telephone conversation</label>
                </div>
              </div>
            </div>
          </div>

          <div className="field">
            <label htmlFor="feedbackDate">Date of feedback</label>
            <CustomDatePicker
              selectedDate={feedbackDate}
              setSelectedDate={setFeedbackDate}
            />
          </div>
          <h3>Parties Involved</h3>
          <PartiesInvolved
            data={partiesInvolved}
            handleRemovePartyInvolved={handleRemovePartyInvolved}
          />
          <div className="half">
            <div className="field">
              <label htmlFor="personAtMeeting">Person at Meeting/Call</label>
              <input
                onChange={(e) => setNewPartyName(e.target.value)}
                value={newPartyName}
                type="text"
                name="personAtMeeting"
                id="personAtMeeting"
                placeholder="Enter witness name"
              />
            </div>
            <div className="field">
              <label htmlFor="relationship">Relationship To Patient</label>
              <input
                onChange={(e) => setNewPartyNameRelationship(e.target.value)}
                value={newPartyRelationship}
                type="text"
                name="relationship"
                id="relationship"
                placeholder="Enter Relationship"
              />
            </div>
          </div>

          <button
            className="width-fit-content"
            onClick={() =>
              handlePartyInvolved(newPartyName, newPartyRelationship)
            }
            type="button"
          >
            Add new party
          </button>
        </div>
        <div className="step inputs-group">
          <div className="field">
            <label htmlFor="extensionLetterDate">
              Date extension letter sent to patient and/or family
            </label>
            <CustomDatePicker
              selectedDate={extensionLetterDate}
              setSelectedDate={setExtensionLetterDate}
            />
          </div>

          <div className="field full">
            <h3>Document List</h3>
            {uploadedFiles.length > 0 ? (
              <div className="files-list">
                {uploadedFiles.map((file, index) => (
                  <div className="file-container">
                    <div className="icon">
                      <img
                        src="/images/file_types/pdf2-svgrepo-com 1.svg"
                        alt=""
                      />
                    </div>
                    <div className="image-content">
                      <p className="file-title">{file.name}</p>
                      <small>
                        {<DateFormatter dateString={file.created_at} />}
                      </small>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <span>No attached documents</span>
            )}
          </div>

          {uploadingDocuments ? (
            "Uploading ..."
          ) : (
            <div className="half">
              <div className="field">
                <label htmlFor="extensionLetterCopy">
                  Attach copy of letter and certified receipt received
                </label>
                <input
                  id="extensionLetterFileInput"
                  type="file"
                  onChange={handleFileChange1}
                  multiple
                />
              </div>
              <div className="field">
                <label htmlFor="responseLetterCopy">
                  Attach copy and certified receipt received
                </label>
                <input
                  id="responseLetterFileInput"
                  type="file"
                  onChange={handleFileChange2}
                  multiple
                />
              </div>
            </div>
          )}

          {(permission.includes("Super User") ||
            permission.includes("Admin") ||
            permission.includes("Quality - Risk Manager")) && (
            <div className="field">
              <label htmlFor="severityRating">Severity rating</label>
              <input
                value={severityRating}
                onChange={(e) => setSeverityRating(e.target.value)}
                type="text"
                name="severityRating"
                id="severityRating"
                placeholder="Severity rating"
              />
            </div>
          )}
          <div className="half">
            <div className="field">
              <label htmlFor="responseLetterDate">
                Date written response letter sent to patient and/or family
              </label>
              <CustomDatePicker
                selectedDate={responseLetterDate}
                setSelectedDate={seRResponseLetterDate}
              />
            </div>

            <div className="field">
              <label htmlFor="dateClosed">Date when matter closed:</label>
              <CustomDatePicker
                selectedDate={dateClosed}
                setSelectedDate={setDateClosed}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ModifyGrievanceInvestigationForm;
