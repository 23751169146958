import React, { useEffect, useState, useRef } from 'react'
import { timeZonesList } from '../../constants'
import { ArrowDown01Icon, Search01Icon, ArrowDownRight02Icon, ArrowRight01Icon, ArrowRight02Icon, Loading01Icon, Loading02Icon, Cancel01Icon } from 'hugeicons-react'

import api from '../../api'
import ErrorMessage from '../messages/ErrorMessage'
import SuccessMessage from '../messages/SuccessMessage'
import SearchableSelect from '../form/SeachableSelect'

const PreferencesForm = ({ setShowPreferencesFrom }) => {
    const [loading, setLoading] = useState()
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [timezone, setTimezone] = useState("")
    const [timezones, setTimezones] = useState([])


    const handleCloseSubmit = async () => {
        if (!timezone) {
            setErrorMessage("Please select a timezone")
            return
        }
        setLoading(true)
        const preferencesData = {
            "user_timezone": timezone.value
        }
        console.log(preferencesData)

        try {
            setErrorMessage("")
            setSuccessMessage("")
            const response = await api.post(`/accounts/preferences/set/`, preferencesData)
            if (response.status === 200) {
                setLoading(false)
                setSuccessMessage("Preferences set successfully");
                setTimeout(() => {
                    setShowPreferencesFrom(false)
                    localStorage.setItem("hasSeenTimezone", true)
                }, 3000);
            }
        } catch (error) {
            if (error.response) {
                setErrorMessage(
                    error.response.data.message ||
                    error.response.data.error ||
                    "Error setting preferences"
                );
            } else {
                setErrorMessage("Unknown error setting preferences");
            }

            setLoading(false)
            console.log('error setting preferences', error)
        }
        // setShowPreferencesFrom(false)
    }

    const handleClose = () => {
        setShowPreferencesFrom(false)
        localStorage.setItem("hasSeenTimezone", true)
    }
    return (
        < div className="preference-popup-content">
            <Cancel01Icon onClick={handleClose} className='close-icon' />
            <div className='preferences-form'>
                <h3>Preferences</h3>
                <p>For better experiences, please set your preferences</p>
                {
                    errorMessage && <ErrorMessage message={errorMessage} />
                }
                {
                    successMessage && <SuccessMessage message={successMessage} />
                }
                <form>
                    {
                        timeZonesList && timeZonesList.length > 0 &&
                        <div className="field">
                            <label htmlFor="timezone">Timezone</label>
                            <SearchableSelect options={timeZonesList} onChange={setTimezone} value={timezone.label} placeholder='Select timezone' />
                        </div>
                    }
                </form>

                <button disabled={loading} onClick={handleCloseSubmit} className='primary-button' type="button">
                    {
                        loading ? <Loading02Icon size={20} className='loading-icon' /> : <ArrowRight02Icon size={20} />
                    }
                    <span>Save</span>
                </button>
            </div>
        </div>
    )
}

export default PreferencesForm

