import React from 'react'

const SupportTicketDoc = () => {
    return (
        <div>
            <iframe src="https://scribehow.com/embed/Submitting_Support_Tickets_for_quality_control__FZ90p9CSRwWxRJ_WDlQvPg?as=scrollable" width="100%" height="720" allowfullscreen frameborder="0"></iframe>
        </div>
    )
}

export default SupportTicketDoc
