import {
  Cancel01Icon,
  Delete01Icon,
  EyeIcon,
  InformationCircleIcon,
} from "hugeicons-react";
import React from "react";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import api from "../../../api";

const DraftPopup = ({ incidentType, incidentString }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [incident, setIncident] = useState(null);

  useEffect(() => {
    const fetchDrafts = async () => {
      // API call to fetch drafts data
      try {
        const response = await api.get(`incidents/overview/draft/user/`);
        if (response.status === 200) {
          setIncident(response.data[incidentType]);
          console.log(response.data[incidentType]);
          setPopupOpen(response.data[incidentType].length > 0 ? true : false);

          console.log(response.data);
        }
      } catch (error) {
        window.customToast.error(error.message);
        console.error(error);
      }
    };

    fetchDrafts();
  }, []);

  const toggleDraftPopup = () => {
    setPopupOpen(!popupOpen);
    console.log(popupOpen);
  };

  return (
    incident?.length > 0 &&
    popupOpen && (
      <div className="warning-message">
        <div className="row">
          <div className="icon-container">
            <InformationCircleIcon className="orange-color" />
          </div>
          <div className="col">
            <h4>You have uncleared drafts</h4>

            <p>
              Your last draft incidents were not submitted. Click the{" "}
              <span className="orange-color">"View drafts"</span> to view or{" "}
              <span className="orange-color">"Clear drafts"</span> to clear
              them.
            </p>
          </div>
        </div>
        <div className="actions-buttons">
          <Link
            to="/users/profile/"
            onClick={() => {
              localStorage.setItem("setDraftActive", "drafts");
            }}
            className="action-button"
          >
            <span>
              <EyeIcon className="icon" />
            </span>
            <span>View drafts</span>
          </Link>
        </div>
        <Cancel01Icon className="cancel-icon" onClick={toggleDraftPopup} />
      </div>
    )
  );
};

export default DraftPopup;
