import React, { useState } from 'react'
import { QRCodeForm } from './mfa'
import ErrorMessage from '../../../components/messages/ErrorMessage'

const MultiFactorVerifyPage = () => {
    const [username, setUsername] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    return (
        <div className='mfa-page'>
            <div className="container">
                <div className="instructions">
                    <h1>Open your MFA App</h1>
                    <p>MFA is enabled on this account. To gain access, please open your authenticator app and get the code for verification</p>
                    <div className="help">
                        <h4>Steps</h4>
                        <div className="steps">
                            <div>1. Enter the code from your MFA</div>
                            <div>2. Click verify button</div>
                            <div>3. Wait for the confirmation</div>
                        </div>
                    </div>
                    <QRCodeForm />
                    {username && <p>Username: {username}</p>}
                    {errorMessage && <ErrorMessage message={errorMessage} />}
                    <small>Having an issue logging in, contact admin</small>
                </div>
                <div className="qr-code">
                    <img className='qr-code-image' src="/images/login/mfa-image.svg" alt="" />
                </div>
            </div>
        </div>
    )
}

export default MultiFactorVerifyPage