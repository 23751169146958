import { Cancel01Icon, InformationCircleIcon, Link01Icon, Link05Icon } from 'hugeicons-react'
import React, { useState } from 'react'

const NoteMessage = ({ message, hideMessage, handleHideMessage, actionLink }) => {
    const [showMessage, setShowMessage] = useState(true)

    const toggleMessage = () => {
        setShowMessage(false)
        handleHideMessage()
    }
    return (
        <>
            {message && showMessage && <div className="note-message">
                <div className="message">
                    <InformationCircleIcon className='message-icon' />
                    <p>{message}</p>
                    {
                        actionLink && <a className='link message-icon' href=""><span>Learn more</span> <Link05Icon size={18} /></a>

                    }
                </div>
                {
                    hideMessage && <Cancel01Icon onClick={toggleMessage} className='message-icon' />
                }
            </div>}
        </>
    )
}

export default NoteMessage
