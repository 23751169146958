import { Loading02Icon } from 'hugeicons-react'
import React, { useState } from 'react'
import LogoText from '../../components/branding/logoText'
import { API_URL } from '../../api'
import axios from 'axios'

const ResetPassword = () => {
    const [userEmail, setUserEmail] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [codeSent, setCodeSent] = useState(false)
    const [passwordResetCode, setPasswordResetCode] = useState("")
    const [codeIsVerified, setCodeIsVerified] = useState(false)
    const [newPassword, setNewPassword] = useState("")
    const [confirmNewPassword, setConfirmNewPassword] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [passwordLengthIsCorrect, setPasswordLengthIsCorrect] = useState(false)
    const [passwordHasNoSpaces, setPasswordHasNoSpaces] = useState(false)
    const [passwordHasNumbers, setPasswordHasNumbers] = useState(false)
    const [passwordHasSpecialCharacters, setPasswordHasSpecialCharacters] = useState(false)

    const handleSendResetCode = async () => {
        if (!passwordResetCode) {
            setErrorMessage("Please enter the verification code")
            return
        }
        if (!newPassword || !confirmNewPassword) {
            setErrorMessage("Please enter both a new password and confirm it")
            return
        }
        if (newPassword !== confirmNewPassword) {
            setErrorMessage("Passwords do not match")
            return
        }

        //  verify if password is strong enough. it should be at least 8 characters with signs, laters and numbers
        // you can use regex for this validation
        // if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(newPassword)) {
        //     setErrorMessage("Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character")
        //     return
        // }
        // if (Object.keys(newPassword).length >= 8) {
        //     setPasswordLengthIsCorrect(true)
        // }
        // if (/\s/.test(newPassword)) {
        //     setPasswordHasNoSpaces(true)
        // }
        // if (/[0-9]/.test(newPassword)) {
        //     setPasswordHasNumbers(true)
        // }
        // if (/[!@#$%^&*(),.?":{}|<>]/.test(newPassword)) {
        //     setPasswordHasSpecialCharacters(true)
        // }

        setIsLoading(true)
        // send reset code to userEmail
        setTimeout(() => {
            setCodeSent(true)
            setIsLoading(false)
        }, 2000)
        setIsLoading(true)
        const email = localStorage.getItem("restPasswordUserEmail")
        if (!email) {
            setErrorMessage("User email not found, check your settings for cookies")
            setIsLoading(false)
            return
        }
        try {
            const passwordData = {
                "code": passwordResetCode,
                "new_password": newPassword,
                "email": localStorage.getItem("restPasswordUserEmail")
            }

            console.log(passwordData)
            const response = await axios.post(`${API_URL}/accounts/reset-password/`, passwordData)
            if (response.status === 200) {
                setSuccessMessage("Password reset successful")
                localStorage.removeItem("restPasswordUserEmail")
                setTimeout(() => {
                    setIsLoading(false)
                    window.location.href = "/"
                }, 2000)
                setIsLoading(false)
            }
        } catch (error) {
            if (error.response) {
                setErrorMessage(error.response.message || error.response.error || "Failed to reset password. Check the code you submitted")
            } else {
                setErrorMessage("Unknown error while resetting your  password")
            }
            setIsLoading(false)
        }
        // send reset code to userEmail
    }

    return (
        <div className='auth-pages'>
            <div className='form'>
                <LogoText text={'Reset password'} />
                <h1>Enter your new password</h1>
                {
                    errorMessage && <div className="error-message">{errorMessage}</div>
                }
                {
                    successMessage && <div className="success-message">{successMessage}</div>
                }
                <input onChange={(e => setPasswordResetCode(e.target.value))} type="text" placeholder="Verification code" />
                <input onChange={(e) => setNewPassword(e.target.value)} type="password" name="" id="" placeholder='New password' />
                <input onChange={(e) => setConfirmNewPassword(e.target.value)} type="password" name="" id="" placeholder='Confirm new password' />
                <button onClick={handleSendResetCode} type='button'>{isLoading ? <Loading02Icon className='loading-icon' /> : 'Reset password'}</button>
            </div>
        </div>
    )
}

export default ResetPassword
