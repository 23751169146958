import React, { useState } from 'react'
import { ArrowLeft02Icon, ArrowRight03Icon, Loading02Icon, ResetPasswordIcon, SquareLockPasswordIcon } from "hugeicons-react"
import { Link } from 'react-router-dom'
import api from '../../../api'
const ChangePassword = ({ setShowChangePasswordForm }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const handleChangePassword = async () => {
        const passwordData = {
            "old_password": oldPassword,
            "new_password": newPassword,
        }
        try {
            setIsLoading(true)
            setErrorMessage("")
            setSuccessMessage("")
            console.log(passwordData)
            const response = await api.post(`/accounts/change-password/`, passwordData)
            if (response.status === 200) {
                setSuccessMessage("Password changed successfully")
                setIsLoading(false)
                console.log(response.data)
                setOldPassword("")
                setNewPassword("")
                localStorage.removeItem("access")
                setTimeout(() => {
                    window.location.reload()
                }, 2000);
            }
        } catch (error) {
            if (error.response) {
                setErrorMessage(error.response.data.message || error.response.data.error || 'Error while changing password')
            } else {
                setErrorMessage('Unknown error changing password')
            }
            console.error(error)
            setIsLoading(false)
        }
    }
    return (
        <div className="change-password-popup">
            <div className='change-password'>
                <h3>Change password</h3>
                <p>To change password, enter your old password and a new one, then click change password button</p>
                <p>If you forgo your password, <Link className='link' to={'/forgot-password/'}>reset it here</Link></p>
                {errorMessage && <div className="error-message">{errorMessage}</div>}
                {successMessage && <div className="success-message">{successMessage}</div>}
                <form action="">
                    <div className="field">
                        <label htmlFor="">Old password</label>
                        <input value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} type="password" name="oldPassword" id="oldPassword" placeholder='Old password' />
                    </div>
                    <div className="field">
                        <label htmlFor="">New password</label>
                        <input value={newPassword} onChange={(e) => setNewPassword(e.target.value)} type="password" name="newPassword" id="newPassword" placeholder='New password' />
                    </div>
                </form>
                <div className="buttons">
                    <button onClick={() => setShowChangePasswordForm(false)} type="button" className="tertiary-button"> <ArrowLeft02Icon size={16} /> Cancel</button>
                    <button disabled={isLoading} onClick={handleChangePassword} type="button" className="primary-button">
                        {
                            isLoading
                                ? <>
                                    <Loading02Icon className='loading-icon' size={18} />
                                    Change password
                                </>

                                : <>
                                    <SquareLockPasswordIcon size={18} />
                                    Change password
                                </>

                        }
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword
