import { jsPDF } from "jspdf";
import * as XLSX from "xlsx";
import axios from "axios";
import { useEffect, useState } from "react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
export const MAX_FILE_SIZE = 12 * 1024 * 1024; // 12 MB in bytes

export const USER_ENV = process.env.REACT_APP_ENV;
export const API_URL = process.env.REACT_APP_API_URL;
export const AuthClientId = process.env.REACT_APP_MS_CLIENT_ID;
export const Auth_CompStaffing_Tenant =
  process.env.REACT_APP_MS_COMPSTAFIFNG_TENANT;
export const Auth_Ms_redirectUri = process.env.REACT_APP_MS_REDIRECT_URL;
export const TINYEMCE_API_KEY = process.env.REACT_APP_TINYEMCE_API_KEY;
export const docUrl = process.env.REACT_APP_DOC_URL;
const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("access");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;

export const cleanedData = (data) => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const currentTime = new Date().toLocaleString("en-US", {
    timeZone: userTimezone,
    hour12: false,
  });

  return Object.entries(data).reduce(
    (acc, [key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        acc[key] = value;
      }
      return acc;
    },
    {
      meta: {
        timezone: userTimezone,
        currentTime: currentTime,
        device: navigator.userAgent,
      },
    }
  );
};

export const calculateAge = (dateOfBirth) => {
  const today = new Date();
  const birthDate = new Date(dateOfBirth);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const exportTableToPDF = (headers, data, options = {}) => {
  const {
    title = "Report",
    fontSize = 14,
    textColor = "#000000",
    font = "helvetica",
  } = options;

  const doc = new jsPDF();

  doc.setFont(font);
  doc.setFontSize(fontSize);
  doc.setTextColor(textColor);

  doc.text(title, 14, 10);

  doc.setLineWidth(0.5);
  doc.line(14, 12, 200, 12);

  doc.setFontSize(12);
  doc.text("Generated on: " + new Date().toLocaleString(), 14, 18);

  doc.autoTable({
    head: [headers],
    body: data,
    startY: 22,
    theme: "striped",
    headStyles: {
      fillColor: [22, 160, 133],
      textColor: [255, 255, 255],
    },
    alternateRowStyles: {
      fillColor: [240, 240, 240],
    },
    styles: {
      fontSize: 10,
      font: font,
    },
  });

  doc.save(`${title}.pdf`);
};

export const exportExcel = (data, name) => {
  const workbook = XLSX.utils.book_new();

  const worksheet = XLSX.utils.json_to_sheet(data);

  XLSX.utils.book_append_sheet(workbook, worksheet, name || "cohesive");

  XLSX.writeFile(workbook, name + ".xlsx" || "cohesive_doc.xlsx");
};

export const formatDateTime = (date) => {
  if (date) {
    const newDate = new Date(date).toLocaleString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false, // Ensures 24-hour format
    });

    return newDate;
  } else {
    return "N/A";
  }
};

export const getDateByOption = (option) => {
  const today = new Date();
  let resultDate = new Date();
  resultDate.setDate(today.getDate() - option);
  return resultDate.toISOString().split("T")[option];
};

export const formatDateForBackend = (dateStr, isEndDate = false) => {
  console.log("date is", dateStr);
  const [year, month, day] = dateStr.split("-");
  console.log("month is", month, "day is", day, "year is", year);
  let formattedDate = `${day}-${month}-${year}`;
  console.log("formatted date is", formattedDate);

  // If it's the end date, include the end of day time
  if (isEndDate) {
    return `${formattedDate}`;
  }
  // Otherwise, start of the day
  return `${formattedDate}`;
};

export const checkCurrentAccount = () => {
  const facility = localStorage.getItem("activeAccount");
  let facility_id = null;
  if (facility && facility !== undefined) {
    facility_id = JSON.parse(facility).id;
    return facility_id;
  } else {
    return null;
  }
};

export const useHandleHideMessage = (message) => {
  console.log("Use handleHideMessage");
};
