import React, { useEffect } from "react";
import DateFormatter from "../dateFormatter";

const formatTime = (time) => {
  const [hours, minutes, seconds] = time.split(":");
  const date = new Date();
  date.setHours(parseInt(hours, 10));
  date.setMinutes(parseInt(minutes, 10));
  date.setSeconds(parseInt(seconds, 10));
  return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
};

const LostFoundDetailsContentTab = ({ data }) => {
 

  return (
    <div className="incident-details">
      <div className="report">
        <div className="person_report">
          <h3>Name of person taking report </h3>
          <small>{data.person_taking_report_info?.user?.last_name || "Not provided"} {data.person_taking_report_info?.user?.first_name || "Not provided"}</small>
        </div>

        <div className="reporting_relationship">
          <div className="reporting">
            <h3>Individual reporting loss</h3>
            <small>
              {data.person_reporting_info?.user?.last_name || "Not provided"} {data.person_reporting_info?.user?.first_name || "Not provided"}
            </small>
          </div>

          <div className="relationship">
            <h3>Relationship</h3>
            <small>{data.relation_to_patient || "Not provided"}</small>
          </div>
        </div>

        <div className="reporting_relationship">
          <div className="relationship">
            <h3>Date of birth</h3>
            <small><DateFormatter dateString={data.person_reporting_info?.date_of_birth}/></small>
          </div>
          <div className="reporting">
            <h3>Age</h3>
            <small>{data.person_reporting_info?.age || "Not provided"}</small>
          </div>
        </div>
      </div>

      <div className="property_found">
        <h2>Property was found:</h2>
        <div className="location">
            <h3>
              Location where <br></br>property was returned
            </h3>
            <small>{data.location_returned || "Not provided"}</small>
          </div>
        <div className="returned">
          <div className="property_returned">
            <h3>Property returned to</h3>
            <small>{data.returned_to || "Not provided"}</small>
          </div>

          <div className="date_time_returned">
            <h3>Date & Time returned</h3>
            <small>
              <DateFormatter dateString={data.date_reported} />,
              {" "}
              {data.time_reported
                ? formatTime(data.time_reported)
                : "Not provided"}
            </small>
          </div>
        </div>

        <div className="found">
          <div className="location">
            <h3>
              Location where <br></br>property was found
            </h3>
            <small>{data.location_found || "Not provided"}</small>
          </div>

          <div className="date_time_found">
            <h3>Date & Time found</h3>
            <small>
              <DateFormatter dateString={data.date_found} />,
              {" "}
              {data.time_found
                ? formatTime(data.time_found)
                : "Not provided"}
            </small>
          </div>
        </div>

        <div className="property_found_by">
          <h3>Person who found property:</h3>
          <small>{data.found_by || "Not provided"}</small>
        </div>
      </div>
    </div>
  );
};

export default LostFoundDetailsContentTab;
