import {
  Cancel01Icon,
  CheckmarkSquare01Icon,
  GoogleDocIcon,
  InformationCircleIcon,
  Loading02Icon,
  PlaneIcon,
  QuestionIcon,
  SentIcon,
  SquareIcon,
  Tick01Icon,
} from "hugeicons-react";
import React, { useState } from "react";
import { priorityLevels, supportSubjects } from "../../../constants";
import api, { docUrl } from "../../../api";

import "../../../assets/css/pages/supportForm/support-form.css";
import { Link } from "react-router-dom";
const SupportForm = ({ handleShowPopup }) => {
  console.log(docUrl);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState({});
  const [selectedPriority, setSelectedPriority] = useState({});
  const [description, setDescription] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [showOtherInput, setShowOtherInput] = useState(false);

  const handleSelectedSubject = (subject) => {
    if (Object.keys(selectedSubject).length > 0) {
      setSelectedSubject("");
      setShowOtherInput(false);
    } else {
      setSelectedSubject(subject);
      setShowOtherInput(false);
    }
  };

  const handleShowOtherIssue = () => {
    setShowOtherInput(!showOtherInput);
  };
  const handleOtherChange = (value) => {
    setSelectedSubject({
      subject: value,
      explanation: "",
    });
  };
  const handleSubmit = async () => {
    setErrorMessage("");
    if (
      !selectedSubject.subject ||
      !selectedPriority.priority ||
      description === ""
    ) {
      setErrorMessage(
        "Please fill all required fields, for us to understand well how we can help!"
      );
      return;
    }
    setIsLoading(true);
    try {
      const tickedData = {
        title: selectedSubject.subject,
        priority: selectedPriority.priority,
        description: description,
      };
      const response = await api.post(`/support/new/`, tickedData);
      if (response.status === 201 || response.status === 200) {
        setErrorMessage("");
        setIsLoading(false);
        setSuccess(true);
        setTimeout(() => {
          handleShowPopup();
        }, 10000);
      }
    } catch (error) {
      if (error.response) {
        setErrorMessage(
          error.response.data.message ||
            error.response.data.error ||
            "Error submitting ticket"
        );
      } else {
        setErrorMessage("Unknown error submitting ticket");
      }
      console.log(error);
      setIsLoading(false);
    }
  };
  return (
    <div className="support-form-popup">
      {success ? (
        <div className="success-container">
          <div className="icon">
            <Tick01Icon size={100} />
          </div>
          <h3 className="title">Your ticket submitted successfully!</h3>
          <p>
            Your ticked has been saved, and the team were notified. Assigned
            personnel will get back to you as soon as possible
          </p>
          <button
            onClick={handleShowPopup}
            type="button"
            className="secondary-button"
          >
            Close this window
          </button>
        </div>
      ) : (
        <div className="support-form">
          <Cancel01Icon onClick={handleShowPopup} className="close-icon" />
          <div className="notes">
            <div className="icon">
              <QuestionIcon size={28} />
            </div>
            <h2 className="title">Support software</h2>
            {/* <p>You may use this form to support a ticket if you face any issue or needs explanation</p> */}
          </div>

          <h3>Select a subject</h3>
          <div className="documentation-link">
            {" "}
            <span>Need help navigating the platform ? Here is the </span>{" "}
            <a target="_blank" href={`${docUrl}`} className="">
              <GoogleDocIcon size={14} />
              documentation
            </a>
          </div>
          {selectedSubject.subject || showOtherInput ? (
            <div className="selected-subject">
              <h4
                onClick={() => handleSelectedSubject(selectedSubject)}
                className="subject"
              >
                Issue: {selectedSubject.subject}
              </h4>
              <div className="explanation">
                <InformationCircleIcon color="#145C9E" />
                <p>{selectedSubject.explanation}</p>
              </div>
              <div className="priorities-levels field">
                <h4>What's the priority level</h4>
                <div className="priorities">
                  {priorityLevels.map((level, index) => (
                    <div
                      onClick={() => setSelectedPriority(level)}
                      className="priority"
                    >
                      {selectedPriority === level ? (
                        <CheckmarkSquare01Icon />
                      ) : (
                        <SquareIcon />
                      )}
                      <p>{level.priority}</p>
                    </div>
                  ))}
                </div>
              </div>
              <form>
                {showOtherInput && (
                  <input
                    onChange={(e) => handleOtherChange(e.target.value)}
                    type="text"
                    name=""
                    id=""
                    placeholder="Enter subject here"
                  />
                )}
                <textarea
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Please describe your issue"
                  rows={4}
                />
              </form>
              {errorMessage && (
                <div className="error-message">
                  <p>{errorMessage}</p>
                </div>
              )}
              <button
                disabled={isLoading}
                onClick={handleSubmit}
                type="button"
                className="primary-button"
              >
                {isLoading ? (
                  <Loading02Icon className="loading-icon" />
                ) : (
                  <>
                    <SentIcon />
                    Submit ticket
                  </>
                )}
              </button>
            </div>
          ) : (
            <div className="subjects">
              {supportSubjects.map((subject, index) => (
                <div key={index} className="subject">
                  <p onClick={() => handleSelectedSubject(subject)}>
                    {subject.subject}
                  </p>
                  <p></p>
                </div>
              ))}
              <div onClick={handleShowOtherIssue} className="subject">
                <p>Other issue</p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SupportForm;
