import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DashBoardContainer from "../dashboard/dashboardContainer";
import { Link } from "react-router-dom";
import {
  ArrowRight01Icon,
  Cancel01Icon,
  CancelSquareIcon,
  CheckmarkSquare02Icon,
  PlusSignIcon,
  UserAdd02Icon,
} from "hugeicons-react";
import api from "../../api";

import "../../assets/css/pages/users/users.css";
import NewUserForm from "../../components/incidents/forms/admin/newUserForm";
import DateFormatter from "../dashboard/incidents/dateFormatter";
import { usePermission } from "../../contexts/permissionsContext";
const UsersListPageContent = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showNewUserForm, setShowNewUserForm] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchingTheDatabase, setIsSearchingTheDatabase] = useState(false);

  const [isSearching, setIsSearching] = useState(false);
  const [isEmpty, setIsEmpty] = useState(localStorage.getItem("isEmpty"));
  const permission = usePermission();

  const usersWithFullname = users.map((user, index) => ({
    ...user, // spread the existing properties of the user
    full_name: `${user.last_name} ${user.first_name}`, // Add a unique key based on the index of the user
  }));

  const search = (string) => {
    setIsSearching(true);
    localStorage.setItem("isEmpty", true);
    const results = usersWithFullname.filter((item) => {
      return (
        (item.id &&
          item.id.toString().toLowerCase().includes(string.toLowerCase())) ||
        (item?.first_name &&
          item?.first_name.toLowerCase().includes(string.toLowerCase())) ||
        (item?.last_name &&
          item?.last_name.toLowerCase().includes(string.toLowerCase())) ||
        (item?.full_name &&
          item?.full_name.toLowerCase().includes(string.toLowerCase())) ||
        (Array.isArray(item.department) &&
          item?.department.some((dep) =>
            dep.name.toLowerCase().includes(string.toLowerCase())
          )) ||
        (item?.email &&
          item?.email.toLowerCase().includes(string.toLowerCase()))
      );
    });
    if (results.length < 1) {
      setIsSearchingTheDatabase(true);
      setTimeout(() => {
        setIsSearchingTheDatabase(false);
      }, 3000);
    }
    setIsEmpty(false);
    localStorage.setItem("isEmpty", false);
    setSearchResults(results);
  };

  const handleShowNewUserForm = () => {
    setShowNewUserForm(!showNewUserForm);
  };
  const handleRowClick = (userId) => {
    navigate(`/users/profile/${userId}/`);
  };
  useEffect(() => {
    // get users from api
    setIsEmpty(localStorage.getItem("isEmpty"));

    const fetchUsers = async () => {
      try {
        const response = await api.get(`accounts/users/list/`);
        if (response.status === 200) {
          setUsers(response.data);

          console.log(usersWithFullname);
          console.log(response.data);
          setIsLoading(false);
        }
      } catch (error) {
        if (error.response) {
          setErrorMessage(
            error.response.data.message ||
              error.response.data.error ||
              "Error setting a list of users"
          );
        } else {
          setErrorMessage("Unknown error fetching users");
        }
        console.log(error);
        setIsLoading(false);
      }
    };
    fetchUsers();
  }, []);

  return isLoading ? (
    <div className="dashboard-page-content">
      <p>Loading...</p>
    </div>
  ) : permission.includes("Super User") ||
    permission.includes("Admin") ||
    permission.includes("User Editor") ? (
    <div className="dashboard-page-content">
      {showNewUserForm && (
        <div className="new-user-form-popup">
          <div className="popup-content">
            <Cancel01Icon
              onClick={handleShowNewUserForm}
              className="close-icon"
            />
            <NewUserForm />
          </div>
        </div>
      )}
      <div className="actions">
        <div className="title">
          <div className="all-users">
            <h3>All users</h3>
          </div>

          <div className="results-count users-results-count">
            <span className="count">
              {isEmpty
                ? users.length
                : searchResults.length > 0
                ? searchResults.length
                : users.length}
            </span>
            <span>user(s) found</span>
          </div>
        </div>

        <div className="filters">
          <input
            onChange={(e) => {
              search(e.target.value);
            }}
            type="search"
            name=""
            id=""
            placeholder="Search user by name or email"
          />
        </div>
        <button
          type="button"
          onClick={handleShowNewUserForm}
          className="button tertiary-button new-user-button"
        >
          <UserAdd02Icon size={20} />
          <span>New user</span>
        </button>
      </div>
      {/* users table */}
      <table className="users-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Status</th>
            <th>Email</th>
            <th>Phone number</th>
            <th>Department</th>
            <th>Date Added</th>
          </tr>
        </thead>
        <tbody>
          {isSearching ? (
            isSearchingTheDatabase ? (
              <div className="searching_database">
                <p>Searching database</p>
              </div>
            ) : searchResults && searchResults.length > 0 ? (
              searchResults.map((user, index) => (
                <tr key={index} onClick={() => handleRowClick(user.id)}>
                  <td>{user.id}</td>
                  <td>
                    {user.last_name} {user.first_name}
                  </td>
                  <td>
                    {user.is_active ? (
                      <div className="table-data-list">
                        <CheckmarkSquare02Icon color="green" size={18} />
                        Active{" "}
                      </div>
                    ) : (
                      <div className="table-data-list">
                        <CancelSquareIcon color="tomato" size={18} /> Inactive
                      </div>
                    )}
                  </td>
                  <td>{user.email}</td>
                  <td>{user.phone_number}</td>
                  <td>
                    {user.department?.slice(0, 2).map((dep, index) => (
                      <span className="table-data-list">{dep.name}</span>
                    ))}
                  </td>
                  <td>
                    <DateFormatter dateString={user.date_created} />
                  </td>
                </tr>
              ))
            ) : (
              <div className="no-data-found">
                <p>No data found with your search</p>
              </div>
            )
          ) : (
            users &&
            users.map((user, index) => (
              <tr key={index} onClick={() => handleRowClick(user.id)}>
                <td>{user.id}</td>
                <td>
                  {user.last_name} {user.first_name}
                </td>
                <td>
                  {user.is_active ? (
                    <div className="table-data-list">
                      <CheckmarkSquare02Icon color="green" size={18} />
                      Active{" "}
                    </div>
                  ) : (
                    <div className="table-data-list">
                      <CancelSquareIcon color="tomato" size={18} /> Inactive
                    </div>
                  )}
                </td>
                <td>{user.email}</td>
                <td>{user.phone_number}</td>
                <td>
                  {user.department?.slice(0, 2).map((dep, index) => (
                    <span className="table-data-list">{dep.name}</span>
                  ))}
                </td>
                <td>
                  <DateFormatter dateString={user.date_created} />
                </td>
              </tr>
            ))
          )}

          <tr></tr>
        </tbody>
      </table>

      <div className="mobile-users">
        {users &&
          users.map((user, index) => (
            <div key={index} className="user-card">
              <p>
                {user.last_name} {user.first_name}
              </p>
              <small>{user.email}</small>
            </div>
          ))}
      </div>
    </div>
  ) : (
    ""
  );
};

const BreadCrumbs = () => {
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link>
      <ArrowRight01Icon />
      <Link to={"/admin/"}>Admin</Link>
      <ArrowRight01Icon />
      <Link className="current-page">Users</Link>
    </div>
  );
};
const UsersListPage = () => {
  return (
    <DashBoardContainer
      breadCrumbs={<BreadCrumbs />}
      content={<UsersListPageContent />}
    />
  );
};

export default UsersListPage;
