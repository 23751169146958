import { colors } from "@mui/material";
import {
  Alert02Icon,
  AlertCircleIcon,
  Cancel01Icon,
  Tick01Icon,
} from "hugeicons-react";
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

const ToastManager = () => {
  const [toasts, setToasts] = useState([]);

  const addToast = ({ type, message }) => {
    const id = Date.now();
    setToasts((prevToasts) => [
      ...prevToasts,
      { id, type, message, visible: true },
    ]);

    setTimeout(() => {
      removeToast(id);
    }, 10000);
  };

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  const handleCancel = (id) => {
    removeToast(id);
  };

  useEffect(() => {
    window.customToast = {
      success: (message) => addToast({ type: "success", message }),
      error: (message) => addToast({ type: "error", message }),
    };
  }, []);

  const getBackgroundColor = (type) => {
    switch (type) {
      case "success":
        return "linear-gradient(171.83deg, #D9FFEF -29.53%, #FFFFFF 120.07%)";
      case "error":
        return "linear-gradient(152.05deg, #FFEDED 7.8%, #FFFFFF 97.31%)";
      default:
        return "background: linear-gradient(201.1deg, #FFF6EE 5.88%, #FFFFFF 133.35%)";
    }
  };

  const getColor = (type) => {
    switch (type) {
      case "success":
        return "#1A9B65";
      case "error":
        return "#FF7171";
      default:
        return "#F77400";
    }
  };

  const getBorderColor = (type) => {
    switch (type) {
      case "success":
        return "#DAFFF0";
      case "error":
        return "#FFEDE5";
      default:
        return "#FFF3E9";
    }
  };
  return ReactDOM.createPortal(
    <div style={styles.container}>
      {toasts.map((toast, index) => (
        <div
          key={toast.id}
          style={{
            ...styles.toast,
            background: getBackgroundColor(toast.type),
            color: "#787878",
            border: "2px solid #FFFFFF",
            opacity: toast.visible ? "1" : "0",
            transform: toast.visible ? "translateY(0)" : "translateY(-200px)",
            transition: "transform 0.5s ease-out, opacity 0.5s ease-out",
            fontFamily: "Plus Jakarta Sans",
          }}
        >
          <div
            style={{
              ...styles.iconContainer,
              borderColor: getBorderColor(toast.type),
            }}
          >
            {toast.type === "success" ? (
              <Tick01Icon
                style={{
                  ...styles.icon,
                  color: getColor(toast.type),
                }}
                variant={"stroke"}
                size={30}
              />
            ) : toast.type === "error" ? (
              <Alert02Icon
                style={{
                  ...styles.icon,
                  color: getColor(toast.type),
                }}
                variant={"stroke"}
                size={30}
              />
            ) : (
              <AlertCircleIcon
                style={{
                  ...styles.icon,
                  color: getColor(toast.type),
                }}
                variant={"stroke"}
                size={30}
              />
            )}
          </div>

          <div style={styles.content}>
            <h1 style={styles.title}>
              {toast.type === "success"
                ? "Success"
                : toast.type === "error"
                ? "Error"
                : "Warning"}
            </h1>
            <div style={styles.toastMessage}>{toast.message}</div>
          </div>

          <Cancel01Icon
            onClick={() => handleCancel(toast.id)}
            size={18}
            color={"#000000"}
            variant={"stroke"}
            strokeWidth={3}
            style={styles.cancelButton}
          />
        </div>
      ))}
    </div>,
    document.body
  );
};

const styles = {
  container: {
    position: "fixed",
    top: "20px",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 1000,
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  toast: {
    padding: "10px 15px",
    paddingRight: "40px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    minWidth: "200px",
    display: "flex",
    gap: "12px",
    fontWeight: "bold",
    maxWidth: "500px",
    transition: "transform 0.5s ease-out, opacity 0.5s ease-out",
    position: "relative",
  },

  content: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },

  title: {
    fontSize: "18px",
    color: "#000000",
  },

  iconContainer: {
    backgroundColor: "#ffffff",
    padding: "12px",
    height: "50px",
    width: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "12px",
    border: "1px solid",
  },

  toastMessage: {
    flex: 1,
    fontWeight: 500,
  },
  cancelButton: {
    color: "#3C3D37",
    cursor: "pointer",
    fontWeight: "bold",
    opacity: "0.7",
    transition: "opacity 0.3s ease",
    position: "absolute",
    top: "10px",
    right: "10px",
  },
};

export default ToastManager;
