import React from "react";
import toast from "react-hot-toast";
import { useState, useEffect } from "react";
import api from "../../api";
import { API_URL } from "../../api";
import { Stack } from "@mui/material";
import { PieChart } from "@mui/x-charts";
import { ArrowRight04Icon } from "hugeicons-react";
import { Link } from "react-router-dom";
import ModifyPageLoader from "../../pages/dashboard/incidents/generalIncident/modify/loader";

const MedicationErrorChart = () => {
  const [medicationErrorData, setMedicationErrorData] = useState([]);
  const [
    medicationErrorDataTotalIncident,
    setMedicationErrorDataTotalIncident,
  ] = useState([]);
  const [failedFetchingOverviewData, setFailedFetchingOverviewData] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchMedicationData = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/overview/medication-error-incident/`
        );
        if (response.status === 200) {
          console.log(response.data);
          setMedicationErrorData(response.data.data);
          setMedicationErrorDataTotalIncident(response.data.total_incidents);
          setIsLoading(false);
        }
      } catch (error) {
        setFailedFetchingOverviewData(true);
        setIsLoading(false);
        console.error(error);
        if (error.response.data) {
          window.customToast.error(error.response.data.message);
        } else {
          window.customToast.error("Failed to fetch incident reports");
        }
      }
    };
    fetchMedicationData();
  }, []);

  const colors = [
    "#145C9E",
    "#BBDDFC",
    "#F87C47",
    "#208781",
    "#9FE4E0",
    "#FFDBCC",
  ];
  const data =
    medicationErrorData.length > 0 &&
    medicationErrorData.map((el, index) => {
      return {
        label: String(el["description error"]).toUpperCase(),
        value: el.percentage,
        color: colors[index],
      };
    });

  function PieChartWithPaddingAngle() {
    return (
      <Stack direction="row" className="pie-chart">
        <PieChart
          series={[
            {
              paddingAngle: 4,
              innerRadius: 30,
              outerRadius: 40,
              data,
            },
          ]}
          margin={{ right: 5 }}
          width={200}
          height={200}
          legend={{ hidden: true }}
        />
      </Stack>
    );
  }
  return isLoading ? (
    <ModifyPageLoader />
  ) : failedFetchingOverviewData ? (
    "Error fetching the data"
  ) : (
    <div className="general-incident-progress">
      <div className="header-row">
        <h4>Medication Error</h4>
        <Link className="view-details">
          <span>View Details</span> <ArrowRight04Icon />
        </Link>
      </div>
      <div className="workplace-row">
        <span>Total Incidents:</span>
        <h4>{medicationErrorDataTotalIncident}</h4>
      </div>
      <div className="pie-chart-container edit-pie-chart-container">
        <PieChartWithPaddingAngle />

        <div className="stats edit-medication-stats">
          <div className="stat">
            <div className="circle-wrapper">
              <div className="circle blue"></div>
              <span className="label">TRANSCRIBING:</span>
            </div>

            <span className="value">
              {medicationErrorData.length > 0 &&
                medicationErrorData[0]?.percentage}
              %
            </span>
          </div>
          <div className="stat">
            <div className="circle-wrapper">
              <div className="circle light-blue"></div>
              <span className="label">PRESCRIBING:</span>
            </div>

            <span className="value">
              {medicationErrorData.length > 0 &&
                medicationErrorData[1]?.percentage}
              %
            </span>
          </div>
          <div className="stat">
            <div className="circle-wrapper">
              <div className="circle orange"></div>
              <span className="label">DISPENSING:</span>
            </div>

            <span className="value">
              {medicationErrorData.length > 0 &&
                medicationErrorData[2]?.percentage}
              %
            </span>
          </div>
          <div className="stat">
            <div className="circle-wrapper">
              <div className="circle green"></div>
              <span className="label">ADMINISTERING:</span>
            </div>
            <span className="value">
              {medicationErrorData.length > 0 &&
                medicationErrorData[3]?.percentage}
              %
            </span>
          </div>
          <div className="stat">
            <div className="circle-wrapper">
              <div className="circle light-green"></div>
              <span className="label">PROCUREMENT & STRORAGE:</span>
            </div>

            <span className="value">
              {medicationErrorData.length > 0 &&
                medicationErrorData[4]?.percentage}
              %
            </span>
          </div>
          <div className="stat">
            <div className="circle-wrapper">
              <div className="circle light-orange"></div>
              <span className="label">MONITORING:</span>
            </div>

            <span className="value">
              {medicationErrorData.length > 0 &&
                medicationErrorData[5]?.percentage}
              %
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicationErrorChart;
