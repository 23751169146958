import React from "react";
import toast from "react-hot-toast";
import { useState, useEffect } from "react";
import api from "../../api";
import { API_URL } from "../../api";
import ModifyPageLoader from "../../pages/dashboard/incidents/generalIncident/modify/loader";
import { BarChart } from "@mui/x-charts";

const InvestigationGraph = () => {
  const [investigationData, setInvestigationData] = useState([]);
  const [grievanceInvestigationData, setGrievanceInvestigationData] = useState(
    []
  );
  const [failedFetchingOverviewData, setFailedFetchingOverviewData] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchEmployeeInvestigationData = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/overview/staff-health-investigation-incident/`
        );
        if (response.status === 200) {
          console.log("Graph", response.data.data);
          setInvestigationData(response.data.data);
          setIsLoading(false);
        }
      } catch (error) {
        setFailedFetchingOverviewData(true);
        setIsLoading(false);
        console.error(error);
        if (error.response.data) {
          window.customToast.error(error.response.data.message);
        } else {
          window.customToast.error("Failed to fetch incident reports");
        }
      }
    };
    fetchEmployeeInvestigationData();
    const fetchGrievanceInvestigationData = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/overview/grievance-investigation-incident/`
        );
        if (response.status === 200) {
          console.log("Grievance", response.data.data);
          setGrievanceInvestigationData(response.data.data);
          setIsLoading(false);
        }
      } catch (error) {
        setFailedFetchingOverviewData(true);
        setIsLoading(false);
        console.error(error);
        if (error.response.data) {
          window.customToast.error(error.response.data.message);
        } else {
          window.customToast.error("Failed to fetch incident reports");
        }
      }
    };
    fetchGrievanceInvestigationData();
  }, []);
  function BasicBars() {
    return (
      <BarChart
        style={{ width: "100%", height: "100%" }}
        xAxis={[
          {
            scaleType: "band",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
        ]}
        series={[
          {
            data: investigationData.map((data, index) => {
              return data.total_incidents;
            }),
            color: "#F87C47",
          },
          {
            data: grievanceInvestigationData.map((data, index) => {
              return data.total_incidents;
            }),

            color: "#145C9E",
          },
        ]}
      />
    );
  }
  return isLoading ? (
    <ModifyPageLoader />
  ) : failedFetchingOverviewData ? (
    "Error fetching the data"
  ) : (
    <div className="graph">
      <div className="header-row">
        <span>Investigation Results</span>
        <div className="legends">
          <div className="legend">
            <div className="circle blue"></div>
            <span>Employee</span>
          </div>
          <div className="legend">
            <div className="circle orange"></div>
            <span>Grievance</span>
          </div>
        </div>
      </div>
      <div
        className="main-graph"
        style={{ width: "100%", height: "100%", maxHeight: "315px" }}
      >
        <BasicBars />
      </div>
    </div>
  );
};

export default InvestigationGraph;
