import React from "react";
import "../../../../assets/css/pages/incidents/medication/medication_details.css";
import DateFormatter from "../dateFormatter";
const MedicationGeneralInfo = ({ data, incidentStatuses }) => {
  return (
    <div className="incident-details">
      <div className="number-mrn">
        <div className="phone-number">
          <small>Drug ordered</small>
          <h4>{data.drug_ordered || "Not provided"}</h4>
        </div>
        <div className="mrn">
          <small>Route</small>
          <h4>{data.drug_ordered_route || "Not provided"}</h4>
        </div>
      </div>

      <div className="number-mrn">
        <div className="phone-number">
          <small>Drug Given</small>
          <h4>{data.drug_given || "Not provided"}</h4>
        </div>
        <div className="mrn">
          <small>Route</small>
          <h4>{data.drug_given_route || "Not provided"}</h4>
        </div>
        {/* <div className="mrn">
          <small>Dose</small>
          <h4>
            {data.dose || "Not provided"}
          </h4>
        </div> */}
      </div>
      <div className="number-mrn">
        <div className="phone-number">
          <small>Date of report</small>
          <h4>
            <DateFormatter dateString={data.date_of_report} />{" "}
          </h4>
        </div>
        <div className="mrn">
          <small>Time of report</small>
          <h4>{data.time_of_report || "Not provided"}</h4>
        </div>
      </div>

      <div className="number-mrn">
        <div className="location">
          <small>What Happened?</small>
          <h4>{data.what_happened || "Not provided"}</h4>
        </div>
        <div className="location">
          <small>Form of error</small>
          <h4>{data.form_of_error || "Not provided"}</h4>
        </div>
      </div>

      <div className="number-mrn">
        <div className="location">
          <small>Description of error</small>
          <h4>{data.description_of_error || "Not provided"}</h4>
        </div>
        <div className="location">
          <small>Contributing factors</small>
          <h4>{data.contributing_factors || "Not provided"}</h4>
        </div>
      </div>
    </div>
  );
};

export default MedicationGeneralInfo;
