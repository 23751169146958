import React, { useEffect, useState } from "react";
import SideBar from "../../components/dashaboard/sideBar";
import GrievanceTab from "../../components/incidents/incidentaTab";
import PopUp from "../../components/general/popup";
import "../../assets/css/pages/incidents/incidents_page.css";
import NoteMessage from "../../components/messages/NoteMessage.jsx";

// forms
import FormChoicesPopup from "../../components/incidents/forms/incidentFormsChoices.jsx";
import GeneralIncidentForm from "../../components/incidents/forms/generalIncidentForm";
import LostAndFoundForm from "../../components/incidents/forms/lostAndFound";
import EmployeeIncidentForm from "../../components/incidents/forms/employeeIncidentForm.jsx";
import MedicationErrorForm from "../../components/incidents/forms/medicationError.jsx";
import GrievanceForm from "../../components/incidents/forms/grievanceForm.jsx";
import DrugReactionForm from "../../components/incidents/forms/drugReactionForm.jsx";
import WorkplaceViolenceIncidentForm from "../../components/incidents/forms/workPlaceViolenceForm.jsx";
import HealthIncidentInvestigationForm from "../../components/incidents/forms/healthIncidentForm.jsx";
import VerbalComplaintForm from "../../components/incidents/forms/verbalComplaintForm.jsx";
import NewContentFullContainer from "../../components/general/newContentFullContainer.jsx";
import GrievanceInvestigationForm from "../../components/incidents/forms/grivanceInvestigationForm.jsx";
import { Link } from "react-router-dom";
import {
  Archive01Icon,
  ArrowDown01Icon,
  ArrowRight01Icon,
  ArrowRight02Icon,
  ArrowUp01Icon,
  Cancel01Icon,
  CheckmarkCircle01Icon,
  CircleIcon,
  CustomerService01Icon,
  Delete01Icon,
  DepartementIcon,
  Dumbbell01Icon,
  Home01Icon,
  House01Icon,
  InformationCircleIcon,
  ListViewIcon,
  Loading02Icon,
  Logout01Icon,
  Note01Icon,
  Notification01Icon,
  Notification02Icon,
  Sad02Icon,
  Setting07Icon,
  Settings01Icon,
  SmileDizzyIcon,
  SmileIcon,
  TickDouble02Icon,
} from "hugeicons-react";
import { useAuthentication } from "../../contexts/authContext.jsx";
import SubmitComplaintForm from "../../components/profile/froms/submitComplaint.jsx";
import SubmitComplaintFormNew from "../../components/profile/froms/submitComplaintNew.jsx";
import { usePermission } from "../../contexts/permissionsContext.jsx";
import api, { formatDateTime } from "../../api.js";

const DashBoardContainer = ({ content, breadCrumbs, pageTitle }) => {
  const [path, setPath] = useState();
  const permissions = usePermission();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isFormChoicesOpen, setIsFormCHoicesOpen] = useState(false);
  const [SelectedForm, setSelectedForm] = useState(null);
  const [showProfileCard, setShowProfileCard] = useState(false);

  function getNextMonday() {
    const today = new Date(); // Current date
    const currentDay = today.getDay(); // Get the day of the week (0 = Sunday, ..., 6 = Saturday)

    // Calculate days until the next Monday
    const daysUntilNextMonday = (8 - currentDay) % 7 || 7;

    // Move to the next Monday
    today.setDate(today.getDate() + daysUntilNextMonday);

    // Array of month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Format the date as "Month Day, Year"
    const formattedDate = `${
      monthNames[today.getMonth()]
    } ${today.getDate()}, ${today.getFullYear()}`;
    return formattedDate;
  }

  const nextScheduledUpdate = getNextMonday();

  const handleShowProfileCard = () => {
    setShowProfileCard(!showProfileCard);
  };

  const [currentTab, setCurrentTab] = useState("incidents");

  const toggleTabs = (tab) => {};

  const toggleFormChoicesOpen = () => {
    setIsFormCHoicesOpen(!isFormChoicesOpen);
  };
  const tootlePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    // get current link line Overview/User/Profile
    // remove the last part "Profile", then add it to the currrent tab
    const pathParts = window.location.pathname.split("/");
    const tab = pathParts[pathParts.length - 1];
    setCurrentTab(tab);
    setPath(window.location.pathname);
  });

  return (
    <div className="dashboard-container">
      <SideBar isMobileMenuOpen={isMobileMenuOpen} />

      <div className="dashboard-content">
        <div className="dash-header">
          <div
            onClick={toggleMobileMenu}
            className={
              isMobileMenuOpen
                ? "mobile-menu-button open"
                : "mobile-menu-button"
            }
          >
            <div className="bar first"></div>
            <div className="bar middle"></div>
            <div className="bar last"></div>
          </div>
          <h4 className="page-title">{pageTitle}</h4>

          <div className="header-actions">
            <button
              onClick={toggleFormChoicesOpen}
              className="new-action-button primary-button"
            >
              <i className="fa-solid fa-plus"></i>
              <span>Add new</span>

              {isFormChoicesOpen ? (
                <FormChoicesPopup
                  tootlePopup={tootlePopup}
                  setSelectedForm={setSelectedForm}
                />
              ) : (
                ""
              )}
            </button>

            <NotificationsContainer />

            <div onClick={handleShowProfileCard} className="profile">
              <img src={require("../../assets/img/branding/logo.png")} alt="" />
            </div>
            {showProfileCard && <ProfileCard />}
          </div>
        </div>
        <NoteMessage
          message={`Next Scheduled Update: ${nextScheduledUpdate}`}
        />
        <div className="breadcrumbs-container">{breadCrumbs}</div>
        <div className="page-content">
          {isPopupOpen ? (
            <PopUp
              tootlePopup={tootlePopup}
              isPopupOpen={isPopupOpen}
              popupContent={
                SelectedForm === "general" ? (
                  <GeneralIncidentForm togglePopup={tootlePopup} />
                ) : SelectedForm === "lostAndFound" ? (
                  <LostAndFoundForm togglePopup={tootlePopup} />
                ) : SelectedForm === "employee" ? (
                  <EmployeeIncidentForm togglePopup={tootlePopup} />
                ) : SelectedForm === "medicationError" ? (
                  <MedicationErrorForm togglePopup={tootlePopup} />
                ) : SelectedForm === "grievance" ? (
                  <GrievanceForm togglePopup={tootlePopup} />
                ) : SelectedForm === "reactionReport" ? (
                  <DrugReactionForm togglePopup={tootlePopup} />
                ) : SelectedForm === "workPlaceViolence" ? (
                  <WorkplaceViolenceIncidentForm togglePopup={tootlePopup} />
                ) : SelectedForm === "verbalComplaint" ? (
                  <VerbalComplaintForm togglePopup={tootlePopup} />
                ) : SelectedForm === "complaintForm" ? (
                  <SubmitComplaintFormNew
                    hasHeight={false}
                    handleSubmitComplaint={tootlePopup}
                  />
                ) : (
                  ""
                )
              }
            />
          ) : (
            ""
          )}

          <div>{content}</div>
        </div>
      </div>
    </div>
  );
};

export default DashBoardContainer;

export const ProfileCard = () => {
  const { logout } = useAuthentication();
  const [activeAccount, setActiveAccount] = useState();
  const [switchAccounts, setSwitchAccounts] = useState(false);
  const [user, setUser] = useState({});
  const permissions = usePermission();
  console.log(permissions);
  useEffect(() => {
    const userObj = localStorage.getItem("userData");
    const activeAccount = localStorage.getItem("activeAccount");
    if (userObj && userObj !== "undefined") {
      setUser(JSON.parse(userObj));
      console.log(JSON.parse(userObj));

      if (activeAccount && activeAccount !== "undefined") {
        setActiveAccount(JSON.parse(activeAccount));
      } else {
        setActiveAccount(user.facility);
        localStorage.setItem("activeAccount", JSON.stringify(user.facility));
      }
    }
  }, []);

  const handleSwitchAccounts = () => {
    setSwitchAccounts(!switchAccounts);
  };

  const switchActiveAccount = (account) => {
    setActiveAccount(account);
    localStorage.setItem("activeAccount", JSON.stringify(account));
    setSwitchAccounts(false);
  };
  return (
    <div className="profile-card">
      <div className="profile-card-container">
        <div className="profile">
          <img src={require("../../assets/img/branding/logo.png")} alt="" />
        </div>
        <div className="name-role">
          <h3 className="name">
            {user && user.first_name} {user && user.last_name}
          </h3>
          <small className="role">{user && user.email}</small>
        </div>
      </div>
      {user && user.accounts?.length > 0 && (
        <div className={`switch-accounts ${switchAccounts ? "show" : "hide"}`}>
          <div onClick={handleSwitchAccounts} className="current-account">
            <Link className="link">
              {" "}
              <House01Icon size={20} />
              <span>{activeAccount && activeAccount.name}</span>
            </Link>
            <ArrowRight01Icon className="icon" />
          </div>
          <div className={`accounts-list`}>
            {user &&
              user.accounts?.map((account, index) => (
                <div
                  key={index}
                  className="account"
                  onClick={() => switchActiveAccount(account)}
                >
                  <span>{account.name}</span>
                </div>
              ))}
          </div>
        </div>
      )}
      <div className="links">
        <Link className="link">
          <DepartementIcon size={20} />{" "}
          <span>{user && user.department?.name}</span>
        </Link>
        <Link to={"/users/profile/"} className="link">
          {" "}
          <Note01Icon size={20} /> <span>My profile</span>
        </Link>
        <Link to={"/support/"} className="link">
          <CustomerService01Icon size={20} /> <span>Support</span>
        </Link>
        <>
          {(permissions && permissions.includes("Admin")) ||
          permissions.includes("Quality - Risk Manager") ? (
            <Link to={"/admin/"} className="link">
              <Setting07Icon size={20} /> <span>Admin</span>
            </Link>
          ) : (
            ""
          )}
        </>
        <div className="divider"></div>
        <div onClick={logout} className="link special">
          <Logout01Icon size={20} /> <span>Logout</span>
        </div>
      </div>
    </div>
  );
};

export const FacilityCard = () => {
  const [activeAccount, setActiveAccount] = useState({});
  useEffect(() => {
    const savedAccount = localStorage.getItem("activeAccount");
    if (savedAccount && savedAccount !== "undefined") {
      setActiveAccount(JSON.parse(savedAccount));
    }
  }, []);
  return (
    <div className="facility-card">
      <House01Icon color="gray" />
      <p className="facility">Facility: </p>
      <p>{activeAccount?.name}</p>
    </div>
  );
};

export const NotificationsContainer = () => {
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState(
    localStorage.getItem("userNotifications")
      ? JSON.parse(localStorage.getItem("userNotifications"))
      : []
  );
  const [showNotifications, setShowNotifications] = useState(false);
  const [activeTab, setActiveTab] = useState("all");

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };
  useEffect(() => {
    // fetch notifications
    const fetchNotifications = async () => {
      try {
        const response = await api.get(`/notifications/`);
        if (response.status === 200) {
          setNotifications(response.data);
          console.log(response.data);
          // store notifications in local storage
          localStorage.setItem(
            "userNotifications",
            JSON.stringify(response.data)
          );
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    };
    fetchNotifications();

    // // update notifications every 5 minutes
    // const interval = setInterval(() => {
    //   fetchNotifications()
    // }, 3000)

    // // cleanup function
    // return () => {
    //   clearInterval(interval)
    // }
  }, []);

  return (
    <>
      <div onClick={toggleNotifications} className="notification">
        <div className="dot"></div>
        <Notification01Icon />
      </div>
      {showNotifications && (
        <div className="notifications-container">
          <h3>
            Notifications{" "}
            {loading && (
              <span>
                <Loading02Icon className="loading-icon" size={18} />
              </span>
            )}
          </h3>
          <div className="notifications-tabs">
            <div
              className={`tab ${activeTab === "all" ? "active" : ""}`}
              onClick={() => setActiveTab("all")}
            >
              <span>All</span>
            </div>
            <div
              className={`tab ${activeTab === "incidents" ? "active" : ""}`}
              onClick={() => setActiveTab("incidents")}
            >
              {" "}
              <span>Incidents </span>
            </div>
            <div
              className={`tab ${activeTab === "complaints" ? "active" : ""}`}
              onClick={() => setActiveTab("complaints")}
            >
              <span>Complaints </span>
            </div>
            <div
              className={`tab ${activeTab === "other" ? "active" : ""}`}
              onClick={() => setActiveTab("other")}
            >
              <span>Other </span>
            </div>
          </div>
          {notifications && notifications.length > 0 ? (
            // show notifications
            <div className="tab-content">
              {activeTab === "all" ? (
                <NotificationList
                  loading={loading}
                  setLoading={setLoading}
                  notifications={notifications}
                  setNotifications={setNotifications}
                />
              ) : activeTab === "incidents" ? (
                <NotificationList
                  loading={loading}
                  setLoading={setLoading}
                  notifications={notifications.filter(
                    (notification) =>
                      notification.notification_category === "incident"
                  )}
                  setNotifications={setNotifications}
                />
              ) : activeTab === "complaints" ? (
                <NotificationList
                  loading={loading}
                  setLoading={setLoading}
                  notifications={notifications.filter(
                    (notification) =>
                      notification.notification_category === "complaint"
                  )}
                  setNotifications={setNotifications}
                />
              ) : activeTab === "other" ? (
                <NotificationList
                  loading={loading}
                  setLoading={setLoading}
                  notifications={notifications.filter(
                    (notifications) =>
                      notifications.notification_category !== "incident" &&
                      notifications.notification_category !== "complaint"
                  )}
                  setNotifications={setNotifications}
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className="no-notifications">
              <SmileDizzyIcon />
              No notifications found
            </div>
          )}
        </div>
      )}
    </>
  );
};

export const NotificationList = ({
  notifications,
  setNotifications,
  setLoading,
  loading,
}) => {
  const [viewAll, setViewAll] = useState(false);

  const handleViewAll = () => {
    setViewAll(!viewAll);
  };

  const handleClearNotifications = async (notification) => {
    setLoading(true);

    //updating notifications
    const updatedNotifications = notifications.filter(
      (n) => n.id !== notification.id
    );
    setNotifications(updatedNotifications);
    localStorage.setItem(
      "userNotifications",
      JSON.stringify(updatedNotifications)
    );

    try {
      const notifications_ids = [notification.id];
      const deleteData = {
        notifications_ids: notifications_ids,
      };
      console.log("ids: ", notifications_ids);
      const response = await api.post(`/notifications/clear/`, deleteData);
      if (response.status === 200) {
        setLoading(false);
        console.log("notifications cleared successfully");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error clearing notifications", error);
    }
  };

  const markAllRead = async () => {
    setLoading(true);
    localStorage.removeItem("userNotifications", []);
    const notifications_ids = notifications.map((n) => n.id);
    try {
      const response = await api.put(`/notifications/mark-read/`, {
        notifications_ids: notifications_ids,
      });
      if (response.status === 200) {
        console.log(response);
        setLoading(false);
      }
    } catch (error) {
      console.log("error clearing notifications: ", error);
      setLoading(false);
    }
  };

  const markRead = async (notification) => {
    setLoading(true);
    const notifications_ids = [notification.id];

    try {
      const response = await api.put(`/notifications/mark-read/`, {
        notifications_ids: notifications_ids,
      });
      if (response.status === 200) {
        console.log(response);
        if (
          notification.notification_category &&
          notification.notification_category === "incident"
        ) {
          window.location.href = `/incident/general/${notification.item_id}`;
        } else if (
          notification.notification_category &&
          notification.notification_category === "complaints"
        ) {
          window.location.href = `/complaints/${notification.item_id}`;
        } else {
          window.location.reload();
        }
      }
    } catch (error) {
      console.log("error clearing notifications: ", error);
      setLoading(false);
    } finally {
      // window.location.href
    }
  };

  const getNotificationLink = (notification) => {
    return;
    let notLink;
  };
  return (
    <div className="notification-list">
      {notifications &&
        notifications
          .slice(0, viewAll ? 1000 : 4)
          .map((notification, index) => (
            <div className="notification-container">
              <div className="notification-content">
                <div className={`not-icon ${notification.notification_type}`}>
                  {notification.notification_category === "incident" ? (
                    <Dumbbell01Icon size={12} />
                  ) : notification.notification_category === "complaint" ? (
                    <Sad02Icon size={12} />
                  ) : (
                    <InformationCircleIcon size={12} />
                  )}
                </div>
                <p className="message">{notification.message}</p>
              </div>
              <div className="time-status">
                <small>{formatDateTime(notification.created_at)}</small>
                <div className="status">
                  {notification.is_read ? (
                    <div className="read">
                      {" "}
                      <TickDouble02Icon size={18} /> <span>Read</span>
                    </div>
                  ) : (
                    <div className="unread">
                      <TickDouble02Icon size={18} />
                      <span>Unread</span>
                    </div>
                  )}
                </div>
              </div>
              <div
                onClick={() => markRead(notification)}
                className="action-icon"
              >
                {notification.is_read ? (
                  <Delete01Icon
                    onClick={() => handleClearNotifications(notification)}
                    size={20}
                  />
                ) : (
                  <>
                    {" "}
                    <span className="view-details">View details</span>
                    <ArrowRight01Icon size={20} />
                  </>
                )}
              </div>
            </div>
          ))}

      <div className="notifications-actions">
        <button
          disabled={loading}
          type="button"
          onClick={markAllRead}
          className="tertiary-button"
          style={{ padding: "12px", paddingBottom: "6px", paddingTop: "6px" }}
        >
          <span>Mark all as read</span>
          <TickDouble02Icon size={20} />
        </button>
        {notifications && notifications.length > 4 && (
          <div onClick={handleViewAll} className="action view-all">
            {viewAll ? (
              <>
                <span>View less</span>
                <ArrowUp01Icon size={20} />
              </>
            ) : (
              <>
                <span>View all</span>
                <ArrowDown01Icon size={20} />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
