import { ArrowRight04Icon } from "hugeicons-react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_URL } from "../../api";
import api from "../../api";
import toast from "react-hot-toast";
import ModifyPageLoader from "../../pages/dashboard/incidents/generalIncident/modify/loader";

const DashboardProgress = () => {
  const [progressValues, setProgressValues] = useState([]);
  const [generalIncidentData, setGeneralIncidentData] = useState([]);
  const [failedFetchingOverviewData, setFailedFetchingOverviewData] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchGeneralIncidentData = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/overview/general-incident/`
        );
        if (response.status === 200) {
          console.log(response.data.data);
          setGeneralIncidentData(response.data.data);
          setProgressValues(new Array(response.data.data.length).fill(0));
          setIsLoading(false);
        }
      } catch (error) {
        setFailedFetchingOverviewData(true);
        setIsLoading(false);
        console.error(error);
        if (error.response.data) {
          window.customToast.error(error.response.data.message);
        } else {
          window.customToast.error("Failed to fetch incident reports");
        }
      }
    };

    fetchGeneralIncidentData();
  }, []);

  useEffect(() => {
    if (generalIncidentData.length > 0) {
      const duration = 2000; // Duration in ms
      const increments = generalIncidentData.map(
        (el) => el.percentage / (duration / 100)
      );
      let startValues = new Array(generalIncidentData.length).fill(0);
      let interval;

      const updateProgress = () => {
        let allComplete = true;
        startValues = startValues.map((value, index) => {
          const newValue = value + increments[index];
          if (newValue >= generalIncidentData[index].percentage) {
            return generalIncidentData[index].percentage;
          } else {
            allComplete = false;
            return newValue;
          }
        });

        setProgressValues(startValues);

        if (allComplete) {
          clearInterval(interval);
        }
      };

      interval = setInterval(updateProgress, 10);

      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [generalIncidentData]);
  return isLoading ? (
    <ModifyPageLoader />
  ) : failedFetchingOverviewData ? (
    "Error fetching the data"
  ) : (
    <div className="general-incident-progress">
      <div className="header-row">
        <h4>General Incidents </h4>
        <Link className="view-details">
          <span>View Details</span> <ArrowRight04Icon />
        </Link>
      </div>
      <div className="progress-container">
        {generalIncidentData.length > 0 &&
          generalIncidentData.map((el, index) => (
            <div key={index} className="progress-col">
              <span>{el.type}</span>
              <div className="progress-row">
                <div className="progress-bar">
                  <progress
                    className="progress"
                    value={progressValues[index]}
                    max={100}
                  />
                </div>

                <div className="progress-info">
                  <div className="progress-number">
                    {Math.round(progressValues[index])}%
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default DashboardProgress;
