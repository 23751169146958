import { Cancel01Icon } from "hugeicons-react";
import React, { useState } from "react";

const ErrorMessage = ({ errorFetching }) => {
  const [closePopup, setClosePopup] = useState(true);

  const handleTogglePopup = () => {
    setClosePopup(false);
  };
  return (
    closePopup && (
      <div className="error-message col">
        {errorFetching.length > 0 &&
          errorFetching.map((error, index) => {
            return <p key={index}>{error}</p>;
          })}

        <Cancel01Icon className="remove-icon" onClick={handleTogglePopup} />
      </div>
    )
  );
};

export default ErrorMessage;
