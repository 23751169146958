import React, { useEffect, useState } from "react";
import "../../assets/css/main/main.css";
import "../../assets/css/pages/drugReactionPage/drugReactionPage.css";
import api, { API_URL } from "../../api";
import {
  CancelSquareIcon,
  EyeIcon,
  FilterHorizontalIcon,
  PencilEdit02Icon,
} from "hugeicons-react";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import CustomSelectInput from "./forms/inputs/customSelect";
import { useNavigate } from "react-router-dom";
import CustomDatePicker from "./forms/inputs/datePicker";
import { usePermission } from "../../contexts/permissionsContext";

function formatTimeWithAMPM(timeString) {
  // Parse the time string into hours, minutes, and seconds
  const [hoursStr, minutesStr, secondsStr] = timeString.split(":");
  const hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);

  // Calculate AM/PM
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Format the time string
  return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${ampm}`;
}

const handleSearch = (items, searchString) => {
  if (searchString.length > 2) {
    const results = items.filter((item) => {
      return (
        item.patient_name.toLowerCase().includes(searchString.toLowerCase()) ||
        item.incident_type_outcome[0]?.outcome_type
          .toLowerCase()
          .includes(searchString.toLowerCase())
      );
    });
    return results;
  }

  return [];
};

const handleFilter = (items, searchType, filterByDate) => {
  const results = items.filter((item) => {
    const matchesType =
      !searchType ||
      (item.incident_type_outcome[0]?.outcome_type &&
        item.incident_type_outcome[0]?.outcome_type
          .toLowerCase()
          .includes(searchType.toLowerCase()));

    const matchesFilterByDate =
      !filterByDate ||
      (item.incident_date && item.incident_date.includes(filterByDate));

    return matchesType && matchesFilterByDate;
  });

  return results;
};

function GrievanceInvestigationTab() {
  const permission = usePermission();
  const navigate = useNavigate();
  const [errorFetching, setErrorFetching] = useState("");
  const [isFetching, setIsFetching] = useState(true);
  const [grievanceInvestigationData, setGrievanceInvestigationData] = useState(
    []
  );
  const [searchResults, setSearchResults] = useState("");
  const [resultsFound, setResultsFound] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  //filters
  const [filterByOutcome, setFilterByOutcome] = useState("");
  const [filterByDate, setFilterByDate] = useState("");
  const [clearFilters, setClearFilters] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);

  // row click
  const handleRowClick = (grievanceInvestigationId) => {
    navigate(`/incident/grievance-investigation/${grievanceInvestigationId}`);
  };
  const navigateToModify = (grievanceInvestigationId) => {
    navigate(
      `/incident/grievance/investigation/${grievanceInvestigationId}/modify/`
    );
    return;
  };

  // allow actions column to be not clickable
  const handleNonClickableColumnClick = (event) => {
    event.stopPropagation();
  };

  const toggleOpenFilters = () => {
    setOpenFilters(!openFilters);
  };

  const handleFilterByDate = (value) => {
    if (value !== "") {
      setFilterByDate(format(value, "yyyy-MM-dd"));
    } else {
      setFilterByDate("");
    }
    setClearFilters(false);
  };

  const handleFilterByOutcome = (value) => {
    setFilterByOutcome(value);
    setClearFilters(false);
  };

  const handleFilters = () => {
    setClearFilters(true);
    setIsSearching(true);
    setOpenFilters(false);
    if (filterByOutcome !== "" || filterByDate !== "") {
      const results = handleFilter(
        grievanceInvestigationData,
        filterByOutcome,
        filterByDate
      );
      setSearchResults(results);
    } else {
      setIsSearching(false);
    }
  };

  const handleClearFilters = () => {
    setOpenFilters(false);
    setIsSearching(false);
    setClearFilters(false);
    handleFilterByOutcome("");
    handleFilterByDate("");
  };

  const search = (string) => {
    if (Object.keys(string).length < 2) {
      setIsSearching(false);
    }
    if (Object.keys(string).length > 2) {
      setIsSearching(true);
      const results = handleSearch(grievanceInvestigationData, string);
      setSearchResults(results);
    }
  };

  useEffect(() => {
    const fetchGrievanceInvestigationData = async () => {
      try {
        setIsFetching(true);
        const response = await api.get(
          `${API_URL}/incidents/grievance-investigation/`
        );
        if (response.status === 200) {
          setGrievanceInvestigationData(response.data);
          setIsFetching(false);
        }
      } catch (error) {
        console.error(error);
        if (error.response.data.error) {
          setErrorFetching(error.response.data.error);
          setIsFetching(false);
        } else {
          setErrorFetching("An error occurred while fetching incident data.");
          setIsFetching(false);
        }
      }
    };
    fetchGrievanceInvestigationData();
    setIsFetching(false);
  }, []);

  return isFetching ? (
    <div className="getting-data">
      <p>Getting data..</p>
    </div>
  ) : (
    <div>
      {errorFetching ? (
        <div className="error-message">
          <p>{errorFetching}</p>
        </div>
      ) : (
        <div className="tab-container incidents-tab">
          <div className="tab-header">
            <div className="title-container-action">
              <div className="title-container">
                <h2 className="title">Grievance Investigation List </h2>
                <p>{grievanceInvestigationData.length} incident available </p>
              </div>
            </div>

            {/* <div className="filters">
              {openFilters ? (
                <div className="filters-popup">
                  <div onClick={toggleOpenFilters} className="close-icon">
                    <CancelSquareIcon size={24} variant={"stroke"} />
                  </div>

                  <h3>Filter incident data</h3>
                  <div className="filter-buttons">
                    <CustomSelectInput
                      options={["Mild", "Moderate", "Severe"]}
                      selected={filterByOutcome}
                      setSelected={handleFilterByOutcome}
                      placeholder={"outcome"}
                    />
                    <CustomDatePicker
                      selectedDate={filterByDate}
                      setSelectedDate={setFilterByDate}
                    />
                    <div className="pop-up-buttons">
                      {clearFilters ? (
                        <button
                          onClick={handleClearFilters}
                          className="outline-button"
                        >
                          <CancelSquareIcon size={20} variant={"stroke"} />
                          Close & Clear
                        </button>
                      ) : (
                        <button
                          onClick={handleFilters}
                          className="secondary-button"
                        >
                          <div className="icon">
                            <FilterHorizontalIcon
                              size={20}
                              variant={"stroke"}
                            />
                          </div>
                          <span>Filter</span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="search-input incident-search">
                <i className="fa-solid fa-magnifying-glass"></i>
                <input
                  onChange={(e) => {
                    search(e.target.value);
                  }}
                  // value={searchString}
                  type="search"
                  name="systemSearch"
                  id="systemSearch"
                  placeholder="Search the system"
                />
              </div>
              <button
                onClick={toggleOpenFilters}
                className="date-filter-button"
              >
                <div className="icon">
                  <FilterHorizontalIcon size={24} variant={"stroke"} />
                </div>
                <span>Filter</span>
              </button>
            </div> */}
          </div>

          <div className="incident-list">
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>ID</th>
                  <th>Conducted By</th>
                  <th>Date & Time</th>
                  <th>Severity</th>
                  <th>Care Level</th>
                  <th>Follow up</th>
                  <th className="action-col">Action</th>
                </tr>
              </thead>
              {isSearching ? (
                <tbody>
                  {searchResults.length > 0 ? (
                    searchResults.map((data, index) => (
                      <tr key={index} onClick={() => handleRowClick(data.id)}>
                        <td>{index + 1}</td>
                        <td>{data.id}</td>
                        <td>{data.conducted_by || "-"}</td>

                        <td>{data.start_date ? `${data.start_date} ` : "-"}</td>
                        <td>{data.severity || "Not specified"}</td>

                        <td>{data.care_level || "Not specified"}</td>
                        <td>{data.follow_up || "Not specified"}</td>
                        <td
                          onClick={(event) =>
                            handleNonClickableColumnClick(event)
                          }
                          className="action-col"
                        >
                          <div className="table-actions">
                            {/* {(permission.includes("Super User") ||
                              permission.includes("Admin") ||  
                              permission.includes("Manager")) && (
                              <PencilEdit02Icon
                                size={20}
                                onClick={() => navigateToModify(data.id)}
                              />
                            )} */}
                            {(permission.includes("Super User") ||
                              permission.includes("Admin") ||
                              permission.includes(
                                "Quality - Risk Manager"
                              )) && (
                              <EyeIcon
                                size={20}
                                onClick={() => handleRowClick(data.id)}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>Nothing found</td>
                    </tr>
                  )}
                </tbody>
              ) : (
                <tbody>
                  {grievanceInvestigationData?.map((data, index) => (
                    <tr key={index} onClick={() => handleRowClick(data.id)}>
                      <td>{index + 1}</td>
                      <td>{data.id}</td>
                      <td>{data.conducted_by || "-"}</td>

                      <td>{data.start_date ? `${data.start_date} ` : "-"}</td>
                      <td>{data.severity || "Not specified"}</td>

                      <td>{data.care_level || "Not specified"}</td>
                      <td>{data.follow_up || "Not specified"}</td>
                      <td
                        onClick={(event) =>
                          handleNonClickableColumnClick(event)
                        }
                        className="action-col"
                      >
                        <div className="table-actions">
                          {(permission.includes("Super User") ||
                            permission.includes("Admin") ||
                            permission.includes("Manager")) && (
                            <PencilEdit02Icon
                              size={20}
                              onClick={() => navigateToModify(data.id)}
                            />
                          )}
                          {(permission.includes("Super User") ||
                            permission.includes("Admin") ||
                            permission.includes("Quality - Risk Manager")) && (
                            <EyeIcon
                              size={20}
                              onClick={() => handleRowClick(data.id)}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default GrievanceInvestigationTab;
