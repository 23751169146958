import React, { useEffect, useState } from "react";
import "../../assets/css/main/main.css";
import api, { API_URL } from "../../api";
import TableActionsPopup from "../general/popups/tableActionPopup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import SliceText from "../general/sliceText";

import {
  MoreHorizontalSquare01Icon,
  FilterHorizontalIcon,
  CancelSquareIcon,
  PencilEdit01Icon,
  PencilEdit02Icon,
  EyeIcon,
} from "hugeicons-react";
import { useNavigate } from "react-router-dom";
import { usePermission } from "../../contexts/permissionsContext";

const handleSearch = (items, searchString) => {
  if (searchString.length > 2) {
    const results = items.filter((item) => {
      return (
        (item.name_of_injured_staff &&
          item.name_of_injured_staff
            .toLowerCase()
            .includes(searchString.toLowerCase())) ||
        (item.incident_type &&
          item.incident_type
            .toLowerCase()
            .includes(searchString.toLowerCase())) ||
        (item.follow_up &&
          item.follow_up.toLowerCase().includes(searchString.toLowerCase()))
      );
    });
    return results;
  }

  return [];
};

const handleFilter = (
  items,
  searchType,
  searchStatus,
  searchCareLevel,
  filterByDate
) => {
  const results = items.filter((item) => {
    const matchesStatus =
      !searchStatus ||
      (item.patient_visitor_name &&
        item.patient_visitor_name
          .toLowerCase()
          .includes(searchStatus.toLowerCase()));
    const matchesType =
      !searchType ||
      (item.incident_type &&
        item.incident_type.toLowerCase().includes(searchType.toLowerCase()));
    const matchesCareLevel =
      !searchCareLevel ||
      (item.care_level &&
        item.care_level.toLowerCase().includes(searchCareLevel.toLowerCase()));
    const matchesFilterByDate =
      !filterByDate ||
      (item.date_of_event && item.date_of_event.includes(filterByDate));

    return (
      matchesStatus && matchesType && matchesCareLevel && matchesFilterByDate
    );
  });

  return results;
};

const HealthInvestigationTab = () => {
  const permission = usePermission();
  const [errorFetching, setErrorFetching] = useState("");
  const [isFetching, setIsFetching] = useState(true);
  const [incidentData, setIncidentData] = useState([]);
  const [searchResults, setSearchResults] = useState("");
  const [resultsFound, setResultsFound] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [filterByDate, setFilterByDate] = useState(false);
  const navigate = useNavigate();

  // filters
  const [filterByType, setFilterByType] = useState("");
  const [filterByStatus, setFilterByStatus] = useState("");
  const [filterByCareLevel, setFilterByCareLevel] = useState("");
  const [clearFilters, setClearFilters] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);

  const [openAction, setOpenAction] = useState(false);
  const [openActionIndex, setOpenActionIndex] = useState("");

  const handleRowClick = (incidentId) => {
    navigate(`/incident/employee/investigation/${incidentId}`);
  };
  const navigateToModify = (incidentId) => {
    navigate(`/incident/employee/investigation/${incidentId}/modify/`);
  };

  const handleNonClickableColumnClick = (event) => {
    event.stopPropagation();
  };

  const toggleOpenFilters = () => {
    setOpenFilters(!openFilters);
  };
  const toggleAction = (index) => {
    setOpenActionIndex(index);
    setOpenAction(!openAction);
  };

  const search = (string) => {
    setIsSearching(true);
    if (Object.keys(string).length < 2) {
      setIsSearching(false);
    }
    if (Object.keys(string).length > 2) {
      const results = handleSearch(incidentData, string);
      setSearchResults(results);
    }
  };

  const handleFilterByDate = (value) => {
    if (value !== "") {
      setFilterByDate(format(value, "yyyy-MM-dd"));
    } else {
      setFilterByDate("");
    }
    setClearFilters(false);
  };

  const handleFilterByType = (value) => {
    setFilterByType(value);
    setClearFilters(false);
  };
  const handleFilterByStatus = (value) => {
    setFilterByStatus(value);
    setClearFilters(false);
  };
  const handleFilterByCareLevel = (value) => {
    setFilterByCareLevel(value);
    setClearFilters(false);
  };
  const handleFilters = () => {
    setClearFilters(true);
    setIsSearching(true);
    setOpenFilters(false);
    if (
      filterByType !== "" ||
      filterByStatus !== "" ||
      filterByCareLevel !== "" ||
      filterByDate !== ""
    ) {
      const results = handleFilter(
        incidentData,
        filterByType,
        filterByStatus,
        filterByCareLevel,
        filterByDate
      );
      setSearchResults(results);
    } else {
      setIsSearching(false);
    }
  };

  const handleClearFilters = () => {
    setOpenFilters(false);
    setIsSearching(false);
    setClearFilters(false);
    handleFilterByType("");
    handleFilterByCareLevel("");
    handleFilterByStatus("");
    handleFilterByDate("");
  };

  useEffect(() => {
    const fetchIncidentData = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/employee_health_investigation/`
        );
        if (response.status === 200) {
          setIncidentData(response.data.incidents);
          setIsFetching(false);
          console.log(response.data.incidents);
        }
      } catch (error) {
        if (error.response.data.error) {
          setErrorFetching(error.response.data.error);
          setIsFetching(false);
        } else {
          setErrorFetching("An error occurred while fetching incident data.");
          setIsFetching(false);
        }
      }
    };
    fetchIncidentData();
    setIsFetching(false);
  }, []);

  return isFetching ? (
    <div className="getting-data">
      <p>Getting data..</p>
    </div>
  ) : (
    <div>
      {errorFetching ? (
        <div className="error-message">
          <p>{errorFetching}</p>
        </div>
      ) : (
        <div className="tab-container incidents-tab">
          <div className="tab-header">
            <div className="title-container-action">
              <div className="title-container">
                <h2 className="title">
                  Employee Health Incident Investigation Form
                </h2>
                <p>{incidentData.length} incidents available </p>
              </div>
            </div>

            {/* <div className="filters">
              {openFilters ? (
                <div className="filters-popup">
                  <div onClick={toggleOpenFilters} className="close-icon">
                    <CancelSquareIcon size={24} variant={"stroke"} />
                  </div>

                  <h3>Filter incident data</h3>
                  <div className="filter-buttons">
                    <select
                      onChange={(e) => handleFilterByCareLevel(e.target.value)}
                      value={filterByCareLevel}
                      name="status"
                      id="status"
                      defaultValue={""}
                    >
                      <option value="">Filter by care Level </option>
                      <option value="resolved">Primary Care</option>
                      <option value="unresolved">Secondary Care</option>
                      <option value="unresolved">Secondary Care</option>
                      <option value="unresolved">Tertiary Care</option>
                      <option value="unresolved">Acute Care</option>
                    </select>

                    <DatePicker
                      selected={filterByDate}
                      onChange={(date) => handleFilterByDate(date)}
                      placeholderText="Select a date"
                      dateFormat="yyy-MM-dd"
                    />
                    <div className="pop-up-buttons">
                      {clearFilters ? (
                        <button
                          onClick={handleClearFilters}
                          className="outline-button"
                        >
                          <CancelSquareIcon size={20} variant={"stroke"} />
                          Close & Clear
                        </button>
                      ) : (
                        <button
                          onClick={handleFilters}
                          className="secondary-button"
                        >
                          <div className="icon">
                            <FilterHorizontalIcon
                              size={20}
                              variant={"stroke"}
                            />
                          </div>
                          <span>Filter</span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="search-input incident-search">
                <i className="fa-solid fa-magnifying-glass"></i>
                <input
                  onChange={(e) => {
                    search(e.target.value);
                  }}
                  // value={searchString}
                  type="search"
                  name="systemSearch"
                  id="systemSearch"
                  placeholder="Search the system"
                />
              </div>
              <button
                onClick={toggleOpenFilters}
                className="date-filter-button"
              >
                <div className="icon">
                  <FilterHorizontalIcon size={24} variant={"stroke"} />
                </div>
                <span>Filter</span>
              </button>
            </div> */}
          </div>

          <div className="incident-list">
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Injured staff</th>
                  <th>Date & Time</th>
                  <th>Nature of injury</th>
                  <th>Severity</th>
                  <th>Follow up</th>
                  <th>Care level</th>
                  <th className="action-col">Action</th>
                </tr>
              </thead>
              {isSearching ? (
                <tbody>
                  {searchResults.length > 0 ? (
                    searchResults.map((incident, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td className="first-col">
                          {incident.name_of_injured_staff || "-"}
                        </td>
                        <td>{incident.date_of_event || "Not provided"}</td>
                        <td>{incident.nature_of_injury || "Not provided"}</td>
                        <td>Not specified</td>
                        <td>Not specified</td>
                        <td>
                          {" "}
                          <p
                            className={`follow-up ${
                              index < 2 ? "in-progress" : "Open"
                            }`}
                          >
                            {incident.follow_up || "Not specified"}
                          </p>
                        </td>
                        <td
                          onClick={(event) =>
                            handleNonClickableColumnClick(event)
                          }
                          className="action-col"
                        >
                          <div className="table-actions">
                            {(permission.includes("Super User") ||
                              permission.includes("Admin") ||
                              permission.includes("Manager")) && (
                              <PencilEdit02Icon
                                size={20}
                                onClick={() => navigateToModify(incident.id)}
                              />
                            )}

                            {(permission.includes("Super User") ||
                              permission.includes("Admin") ||
                              permission.includes("Manager")) && (
                              <EyeIcon
                                size={20}
                                onClick={() => handleRowClick(incident.id)}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <p>Nothing found</p>
                  )}
                </tbody>
              ) : (
                <tbody>
                  {incidentData.map((incident, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {SliceText(incident.name_of_injured_staff || "-", 20)}
                      </td>
                      <td>{incident.date_of_event || "Not provided"}</td>
                      <td>
                        {SliceText(
                          incident.nature_of_injury || "Not Provided",
                          20
                        )}
                      </td>
                      <td>{incident.severity || "Not provided"}</td>
                      <td>{incident.care_level || "Not provided"}</td>
                      <td>
                        {" "}
                        <p
                          className={`follow-up ${
                            index < 2 ? "in-progress" : "Open"
                          }`}
                        >
                          {incident.follow_up || "Not specified"}
                        </p>
                      </td>
                      <td
                        onClick={(event) =>
                          handleNonClickableColumnClick(event)
                        }
                        className="action-col"
                      >
                        <div className="table-actions">
                          {(permission.includes("Super User") ||
                            permission.includes("Admin") ||
                            permission.includes("Manager")) && (
                            <PencilEdit02Icon
                              size={20}
                              onClick={() => navigateToModify(incident.id)}
                            />
                          )}

                          {(permission.includes("Super User") ||
                            permission.includes("Admin") ||
                            permission.includes("Manager")) && (
                            <EyeIcon
                              size={20}
                              onClick={() => handleRowClick(incident.id)}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default HealthInvestigationTab;
