import React, { useEffect, useState } from "react";
import DashBoardContainer from "../dashboardContainer";
import { Link } from "react-router-dom";
import { ArrowRight01Icon, GoogleDocIcon, Note02Icon } from "hugeicons-react";
import api from "../../../api";

import "../../../assets/css/pages/facilities/facilities.css";
import ModifyPageLoader from "../incidents/generalIncident/modify/loader";
import { usePermission } from "../../../contexts/permissionsContext";
const FacilitiesPageComponent = () => {
  const [facilities, setFacilities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { permissions } = usePermission();
  useEffect(() => {
    setErrorMessage("");

    const fetchFacilities = async () => {
      try {
        const response = await api.get("/facilities/");
        if (response.status === 200) {
          setFacilities(response.data.data);
          console.log(response.data.data);
          setIsLoading(false);
          localStorage.setItem(
            "facilities",
            JSON.stringify(response.data.data)
          );
        }
      } catch (error) {
        if (error.response) {
          setErrorMessage(
            error.response.data.message ||
              error.response.data.error ||
              "Error fetching facilities data"
          );
        } else {
          setErrorMessage("Unknown error fetching facilities data");
        }
        setIsLoading(false);
        console.error(error);
      }
    };
    fetchFacilities();
  }, []);
  return isLoading ? (
    <ModifyPageLoader />
  ) : (
    <div className="facilities-page-content dashboard-page-content">
      <h2>Facilities</h2>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {successMessage && (
        <div className="success-message">{successMessage}</div>
      )}

      <div className="facilities-list">
        {facilities &&
          facilities.length > 0 &&
          facilities.map((facility, index) => (
            <Link
              to={`/facilities/${facility.id}/`}
              key={index}
              className="facility-item"
              onClick={() => {
                localStorage.setItem("facilityName", facility.name);
              }}
            >
              <div className="icon">
                <Note02Icon />
              </div>
              <div className="title-content">
                <h3>{facility.name || "No name"}</h3>
                <small>{facility.email || "No email"}</small>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
};
const BreadCrumbs = () => {
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link>
      <ArrowRight01Icon />
      <Link className="current-page">Facilities</Link>
    </div>
  );
};
const FacilitiesPage = () => {
  return (
    <DashBoardContainer
      content={<FacilitiesPageComponent />}
      breadCrumbs={<BreadCrumbs />}
    />
  );
};

export default FacilitiesPage;
