import { ArrowDown01Icon, Search01Icon } from "hugeicons-react";
import React, { useRef, useState, useEffect } from "react";

const SearchableEmployeeSelect = ({
    options = [],
    value,
    onChange,
    placeholder = 'Select option',
    searchPlaceholder = 'Search...',
    error,
    className = '',
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const dropdownRef = useRef(null);

    const selectedOption = options.find(opt => opt.value === value);

    const filteredOptions = options.filter(option =>
        option?.label.toLowerCase().includes(searchTerm?.toLowerCase())
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleSelect = (option) => {
        onChange(option);
        setSearchTerm('');
        setIsOpen(false);
    };

    return (
        <div className={`searchable-select ${className}`} ref={dropdownRef}>
            <div
                className={`select-trigger ${error ? 'error' : ''} ${isOpen ? 'open' : ''}`}
                onClick={() => setIsOpen(!isOpen)}
            >
                <span className="selected-value">
                    {selectedOption ? selectedOption.label : placeholder}
                </span>
                <ArrowDown01Icon className="trigger-icon" />
            </div>

            {isOpen && (
                <div className="select-dropdown">
                    <div className="search-container">
                        <Search01Icon className="search-icon" />
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder={searchPlaceholder}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </div>

                    <div className="options-container">
                        {filteredOptions.length > 0 ? (
                            filteredOptions.map((option) => (
                                <div
                                    key={option.value}
                                    className={`option ${value === option.value ? 'selected' : ''}`}
                                    onClick={() => handleSelect(option)}
                                >
                                    <p>{option.label}</p>
                                    <small>{option.email || 'No email found'}</small>
                                </div>
                            ))
                        ) : (
                            <div className="no-options">
                                No options found
                            </div>
                        )}
                    </div>
                </div>
            )}

            {error && <div className="error-message">{error}</div>}
        </div>
    );
};

export default SearchableEmployeeSelect