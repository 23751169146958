import { useState } from "react";
import toast from "react-hot-toast";

export const validateStep = (fieldsObject) => {
  const missingFields = [];

  for (const field in fieldsObject) {
    if (!fieldsObject[field]) {
      missingFields.push(field);
    }
  }

  if (missingFields.length > 0) {
    const message = `Please enter : ${missingFields.join(", ")}`;
    window.customToast.error(message);

    return false;
  }
  return true;
};

// import { useState } from "react"
// import toast from "react-hot-toast"

// export const validateFirstStep = (
//     victimType, name, sex, incidentDate, medicalMr, address, state, zipCode, phoneNumber
// ) => {
//     const missingFields = []

//     const fields = {
//         "victim type": victimType,
//         "name": name,
//         "sex": sex,
//         "incident date": incidentDate,
//         "medical MR": medicalMr,
//         "address": address,
//         "state": state,
//         "zip code": zipCode,
//         "phone number": phoneNumber,
//     }

//     for (const field in fields) {
//         if (!fields[field]) {
//             missingFields.push(field);
//         }
//     }

//     if (missingFields.length > 0) {
//         const message = `Please enter : ${missingFields.join(', ')}`;
//         window.customToast.error(message);

//         return false;
//     }
//     return true
// }
