import {
  ArrowRight04Icon,
  CheckmarkSquare01Icon,
  EyeIcon,
  PencilEdit02Icon,
  SquareIcon,
  Tap01Icon,
} from "hugeicons-react";
import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../api";
import { API_URL } from "../../api";
import toast from "react-hot-toast";
import ModifyPageLoader from "../../pages/dashboard/incidents/generalIncident/modify/loader";
import DateFormatter from "../../pages/dashboard/incidents/dateFormatter";
import { usePermission } from "../../contexts/permissionsContext";

const ComplaintsTable = () => {
  const [failedFetchingOverviewData, setFailedFetchingOverviewData] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [complaintsData, setComplaintsData] = useState([]);

  const permission = usePermission();
  useEffect(() => {
    const fetchComplaints = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/overview/complaints/`
        );
        if (response.status === 200) {
          console.log("Complaints", response.data.data);
          setComplaintsData(response.data.data.slice(0, 6));
          setIsLoading(false);
        }
      } catch (error) {
        setFailedFetchingOverviewData(true);
        setIsLoading(false);
        console.error(error);
        if (error.response.data) {
          window.customToast.error(error.response.data.message);
        } else {
          window.customToast.error("Failed to fetch incident reports");
        }
      }
    };
    fetchComplaints();
  }, []);
  return isLoading ? (
    <ModifyPageLoader />
  ) : failedFetchingOverviewData ? (
    "Error fetching the data"
  ) : (
    <div className="complaints">
      <div className="header-row">
        <h4>Complaints</h4>
        <Link className="view-details">
          <span>View All</span> <ArrowRight04Icon />
        </Link>
      </div>
      <table className="complaints-table">
        <thead>
          <tr>
            <th>ID</th>

            <th>Patient name </th>

            <th>Date of complaint</th>

            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {complaintsData.map((complaint, index) => (
            <tr key={index}>
              <td>{complaint?.id}</td>
              <td>{complaint?.patient_name || "Not provided"}</td>

              <td>
                {(
                  <div>
                    <DateFormatter dateString={complaint?.date_of_complaint} />
                  </div>
                ) || "-"}
              </td>

              <td>
                <div className="table-actions">
                  {(permission.includes("Super User") ||
                    permission.includes("Admin") ||
                    permission.includes("Quality - Risk Manager")) &&
                    !complaint.resolved_by_staff && (
                      <PencilEdit02Icon
                        size={20}
                        // onClick={() => navigateToModify(complaint.id)}
                      />
                    )}

                  {(permission.includes("Super User") ||
                    permission.includes("Admin") ||
                    permission.includes("Quality - Risk Manager")) && (
                    <EyeIcon
                      size={20}
                      //   onClick={() => handleRowClick(complaint.id)}
                    />
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mobile-table">
        {complaintsData &&
          complaintsData.map((incident, index) => (
            <IncidentTableCard incident={incident} />
          ))}
      </div>
    </div>
  );
};

export default ComplaintsTable;

const IncidentTableCard = ({
  incident,
  items,
  selectedItems,
  handleSelectedItems,
  navigateToModify,
  handleRowClick,
}) => {
  return (
    <div className={`table-card `}>
      <div className="card-header">
        <div className="id-number">
          <span>ID</span>
          <span>{incident?.id}</span>
        </div>

        <div className="card-actions">
          <Tap01Icon />
          <span>View more</span>
        </div>
      </div>
      <div className="card-content-items">
        <div className="item">
          <label htmlFor="">Patient Name: </label>
          <span>{incident?.patient_name || "Not provided"}</span>
        </div>
        <div className="item">
          <label htmlFor="">MRN: </label>
          <span>{incident?.medical_record_number || "Not provided"}</span>
        </div>
        <div className="item">
          <label htmlFor="">Date of complaint: </label>
          <span>{incident.date_of_complaint || "Not provided"}</span>
        </div>
      </div>
    </div>
  );
};
