import React, { useEffect, useState } from 'react'
import DashBoardContainer from '../dashboardContainer'
import { Link } from 'react-router-dom'
import api, { exportExcel, formatDateTime } from '../../../api'
import ModifyPageLoader from '../incidents/generalIncident/modify/loader'

import '../../../assets/css/pages/tickets/support_tickets.css'
import DateFormatter from '../incidents/dateFormatter'
import SupportForm from '../../../components/dashaboard/forms/supportForm'
import { ArrowRight01Icon, CheckmarkSquare01Icon, Delete01Icon, FileExportIcon, FolderOpenIcon, Loading02Icon, PlusSignIcon, RepeatIcon, SquareIcon } from 'hugeicons-react'


const SupportTicketsContainer = () => {
    const [warningMessage, setWarningMessage] = useState("")
    const [supportTickets, setSupportTickets] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [deleting, setDeleting] = useState(false)
    const [itemDelete, setItemDelete] = useState({})
    const [errorMessage, setErrorMessage] = useState("")
    const [selectedTickets, setSelectedTickets] = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const [filteredTickets, setFilteredTickets] = useState([])
    const [showSupportForm, setShowSupportForm] = useState(false)

    const handleShowSupportForm = () => {
        setShowSupportForm(!showSupportForm)
    }

    const handleSearch = (searchString) => {
        setSearchTerm(searchString);

        if (Object.keys(searchString).length > 0) {
            const filteredTickets = selectedTickets && supportTickets.filter(ticket =>
                ticket.title.toLowerCase().includes(searchString.toLowerCase())
            );

            setFilteredTickets(filteredTickets); // Ensure this state is defined
        }
    };


    const handleSelectedTickets = (ticket) => {
        if (!selectedTickets.includes(ticket)) {
            setSelectedTickets([...selectedTickets, ticket])
        } else {
            // remove ticket from selected tickets
            setSelectedTickets(selectedTickets.filter((item) => item.id !== ticket.id))
        }
    }

    const handleAllTicketsSelect = () => {
        if (selectedTickets === supportTickets) {
            setSelectedTickets([])
        }
        else {
            setSelectedTickets(supportTickets)
        }
    }

    const handleExport = () => {
        const data = selectedTickets.map(item => ({
            ...item,
            created_at: formatDateTime(item.created_at),
            updated_at: formatDateTime(item.updated_at)
        }));
        exportExcel(data)
    }

    const handleDelete = async (ticket) => {
        setItemDelete(ticket)
        setDeleting(true)

        try {
            const response = await api.delete(`/support/${ticket.id}/delete/`)
            if (response.status === 200) {
                setSupportTickets(supportTickets.filter((item) => item.id !== ticket.id))
                setDeleting(false)
                setWarningMessage(`Ticket with id: ${ticket.id} is deleted successfully`)
            }
        } catch (error) {
            if (error.response) {
                setErrorMessage(error.response.data.error || error.response.data.message || 'Error deleting your ticket')
            } else {
                setErrorMessage('Unknown error deleting your ticket')
            }
            console.log(error)
            setDeleting(false)
            setTimeout(() => {
                setErrorMessage("")
                setWarningMessage("")
            }, 5000);
        }
    }

    useEffect(() => {
        const fetchUserTickets = async () => {
            try {
                setErrorMessage("")
                const response = await api.get(`/support/`)
                if (response.status === 200) {
                    console.log(response.data)
                    setSupportTickets(response.data)
                    setIsLoading(false)
                }
            } catch (error) {
                if (error.response) {
                    setErrorMessage(error.response.data.message || error.response.error || 'Error getting tickets')
                } else {
                    setErrorMessage('Unknown error getting tickets')
                }
                setIsLoading(false)
                console.log(error)
            }
        }
        fetchUserTickets()
    }, [])


    return isLoading ? <ModifyPageLoader /> : (
        <div className='dashboard-page-content tickets-page'>
            {
                showSupportForm &&
                <SupportForm handleShowPopup={handleShowSupportForm} />
            }
            {
                errorMessage && <div className="error-message">{errorMessage}</div>
            }
            {
                warningMessage && <div className="warning-message">{warningMessage}</div>
            }
            <h2>Your Support tickets</h2>
            <small>{supportTickets && supportTickets.length} tickets found</small>

            <div className="table-filters">
                <input onChange={(e) => handleSearch(e.target.value)} value={searchTerm} type="search" name="" id="" placeholder='Search ticket by subject or priority' className='search' />
                <div className={`filter-actions ${selectedTickets && selectedTickets.length > 0 ? 'hide' : ''}`}>
                    <button onClick={handleShowSupportForm} className="primary-button new">
                        <PlusSignIcon />
                        <span>Submit a ticket</span>
                    </button>


                    {
                        selectedTickets && selectedTickets.length > 0 ?
                            <>
                                <button onClick={() => handleExport()} type="button" className="secondary-button">
                                    <FileExportIcon size={26} />
                                    <span>Export {selectedTickets.length}</span>
                                </button>
                            </>
                            : ''
                    }

                </div>
            </div>
            {
                supportTickets && supportTickets.length > 0
                    ? <>
                        <table className='table-tickets'>
                            <thead>
                                <tr>
                                    <th>{supportTickets === selectedTickets ? <CheckmarkSquare01Icon onClick={handleAllTicketsSelect} size={20} color='orange' /> : <SquareIcon size={20} onClick={handleAllTicketsSelect} />} </th>
                                    <th>No</th>
                                    <th>Ticket ID</th>
                                    <th>Priority level</th>
                                    <th>Subject</th>
                                    <th>Date submitted</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {searchTerm ? (
                                    filteredTickets.length > 0 ? (
                                        filteredTickets.map((ticket, index) => (
                                            <tr key={index} className={`${selectedTickets && selectedTickets.includes(ticket) ? 'selected' : ''}`}>
                                                <td onClick={() => handleSelectedTickets(ticket)}>
                                                    {selectedTickets && selectedTickets.includes(ticket) ? <CheckmarkSquare01Icon color='orange' size={20} /> : <SquareIcon size={20} />}
                                                </td>
                                                <td>{index + 1}</td>
                                                <td>{ticket.id}</td>
                                                <td>{ticket.priority}</td>
                                                <td>{ticket.title}</td>
                                                <td><DateFormatter dateString={ticket.created_at} /></td>
                                                <td>{ticket.status}</td>
                                                <td>
                                                    {deleting && itemDelete === ticket ? (
                                                        <Loading02Icon color='red' className='loading-icon' />
                                                    ) : (
                                                        <Delete01Icon onClick={() => handleDelete(ticket)} color='#F64E60' />
                                                    )}
                                                    <abbr title="Re-open"><RepeatIcon color='#5F8D4E' /></abbr>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="8" className='no-tickets'>
                                                <h3 className="title">No tickets with '{searchTerm}' in them found</h3>
                                            </td>
                                        </tr>
                                    )
                                ) : (
                                    supportTickets.map((ticket, index) => (
                                        <tr key={index} className={`${selectedTickets && selectedTickets.includes(ticket) ? 'selected' : ''}`}>
                                            <td onClick={() => handleSelectedTickets(ticket)}>
                                                {selectedTickets && selectedTickets.includes(ticket) ? <CheckmarkSquare01Icon color='orange' size={20} /> : <SquareIcon size={20} />}
                                            </td>
                                            <td>{index + 1}</td>
                                            <td>{ticket.id}</td>
                                            <td>{ticket.priority}</td>
                                            <td>{ticket.title}</td>
                                            <td><DateFormatter dateString={ticket.created_at} /></td>
                                            <td>{ticket.status}</td>
                                            <td>
                                                {deleting && itemDelete === ticket ? (
                                                    <Loading02Icon color='red' className='loading-icon' />
                                                ) : (
                                                    <Delete01Icon onClick={() => handleDelete(ticket)} color='#F64E60' />
                                                )}
                                                <abbr title="Re-open"><RepeatIcon color='#5F8D4E' /></abbr>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>


                        </table>

                        <div className="mobile-tickets">
                            <div className="mobile-select-actions">
                                <button type="button" className="tertiary-button">
                                    {supportTickets === selectedTickets ? <CheckmarkSquare01Icon onClick={handleAllTicketsSelect} size={20} color='orange' /> : <SquareIcon size={20} onClick={handleAllTicketsSelect} />}
                                    <span>Select all</span>
                                </button>
                            </div>
                            {
                                searchTerm
                                    ? <div className="tickets-list">
                                        {
                                            filteredTickets.length > 0
                                                ? <div className="tickets">
                                                    {
                                                        filteredTickets.map((ticket, index) => (
                                                            <div className="ticket">
                                                                <div key={index} className="number-priority">
                                                                    <div className="number">
                                                                        {selectedTickets && selectedTickets.includes(ticket) ? <CheckmarkSquare01Icon color='orange' size={20} /> : <SquareIcon size={20} />}
                                                                        <p>{ticket.priority}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="title-date-actions">
                                                                    <div className="title-date">
                                                                        <h4>{ticket.title}</h4>
                                                                        <small>{ticket.created_at}</small>
                                                                    </div>
                                                                    <div className="actions">
                                                                        {deleting && itemDelete === ticket ? (
                                                                            <Loading02Icon color='red' className='loading-icon' />
                                                                        ) : (
                                                                            <Delete01Icon onClick={() => handleDelete(ticket)} color='#F64E60' />
                                                                        )}
                                                                        <abbr title="Re-open"><RepeatIcon color='#5F8D4E' /></abbr>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }

                                                </div>
                                                : <div className="no-tickets">
                                                    Nothing found
                                                </div>
                                        }
                                    </div>
                                    : <div className="tickets-list">

                                        <div className="tickets">
                                            {
                                                supportTickets.map((ticket, index) => (
                                                    <div className="ticket">
                                                        <div key={index} className="number-priority">
                                                            <div className="number">
                                                                {selectedTickets && selectedTickets.includes(ticket) ? <CheckmarkSquare01Icon onClick={() => handleSelectedTickets(ticket)} color='orange' size={20} /> : <SquareIcon onClick={() => handleSelectedTickets(ticket)} size={20} />}
                                                            </div>
                                                            <p>{ticket.priority}</p>
                                                        </div>
                                                        <div className="title-date-actions">
                                                            <div className="title-date">
                                                                <h4>{ticket.title}</h4>
                                                                <small>{ticket.created_at}</small>
                                                            </div>
                                                            <div className="ticket-actions">
                                                                {deleting && itemDelete === ticket ? (
                                                                    <Loading02Icon color='red' className='loading-icon' />
                                                                ) : (
                                                                    <Delete01Icon onClick={() => handleDelete(ticket)} color='#F64E60' />
                                                                )}
                                                                <abbr title="Re-open"><RepeatIcon color='#5F8D4E' /></abbr>
                                                            </div>
                                                        </div>

                                                        <p className="description">
                                                            {ticket.description}
                                                        </p>
                                                    </div>
                                                ))
                                            }

                                        </div>
                                    </div>
                            }

                        </div>
                    </>
                    : <div className='no-tickets'>
                        <FolderOpenIcon />
                        <h3 className="title">No tickets found</h3>
                        <p>This may be cause you haven't posted any ticket, or deleted them. You can submit a ticket by clicking the button below</p>
                        <button onClick={handleShowSupportForm} type="button" className='primary-button'>
                            <PlusSignIcon />
                            <span>Submit a ticket</span>
                        </button>
                    </div>
            }

        </div>
    )
}

const BreadCrumbs = () => {
    return (
        <div className="breadcrumbs">
            <Link to={'/'}>Overview</Link> <ArrowRight01Icon /> <Link className='current-page'>Support</Link>

        </div>
    )
}
const SupportTickets = () => {
    return (
        <DashBoardContainer content={<SupportTicketsContainer />} breadCrumbs={<BreadCrumbs />} />
    )
}

export default SupportTickets