import React from "react";
import toast from "react-hot-toast";
import { useState, useEffect } from "react";
import api from "../../api";
import { API_URL } from "../../api";
import ModifyPageLoader from "../../pages/dashboard/incidents/generalIncident/modify/loader";
import { BarChart } from "@mui/x-charts";
import { Link } from "react-router-dom";
import { ArrowRight04Icon } from "hugeicons-react";

const GrievanceGraph = () => {
  const [grievanceData, setGrievanceData] = useState([]);

  const [failedFetchingOverviewData, setFailedFetchingOverviewData] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchGrievanceData = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/overview/grievance-incident/`
        );
        if (response.status === 200) {
          console.log("Grievance Graph", response.data.data);
          setGrievanceData(response.data.data);
          setIsLoading(false);
        }
      } catch (error) {
        setFailedFetchingOverviewData(true);
        setIsLoading(false);
        console.error(error);
        if (error.response.data) {
          window.customToast.error(error.response.data.message);
        } else {
          window.customToast.error("Failed to fetch incident reports");
        }
      }
    };
    fetchGrievanceData();
  }, []);
  function BasicBars() {
    return (
      <BarChart
        style={{ width: "100%", height: "100%" }}
        xAxis={[
          {
            scaleType: "band",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
        ]}
        series={[
          {
            data: grievanceData.map((data, index) => {
              return data.total_incidents;
            }),
            color: "#F87C47",
          },
        ]}
      />
    );
  }
  return isLoading ? (
    <ModifyPageLoader />
  ) : failedFetchingOverviewData ? (
    "Error fetching the data"
  ) : (
    <div className="grievance-graph">
      <div className="header-row edit-header-row">
        <h4>Grievance Form </h4>
        <Link className="view-details">
          <span>View Details</span> <ArrowRight04Icon />
        </Link>
      </div>
      <div
        className="main-graph"
        style={{ width: "100%", height: "100%", maxHeight: "320px" }}
      >
        <BasicBars />
      </div>
    </div>
  );
};

export default GrievanceGraph;
