import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashBoardContainer from "../dashboardContainer";
import IncidentDetailsHeader from "./incidentDetails/incidentDetailsHeader";
import IncidentDetails from "./incidentDetails/incidentDetails";
import IncidentTabs from "./incidentDetails/incidentDetailsTabs";
import api, { API_URL } from "../../../api";
import "../../../assets/css/pages/incidents/general/generalIncidentDetailsPage.css";
import MedicationDetailsContentTab from "./medicationIncident/medication";
import MedicationGeneralInfo from "./medicationIncident/medicationGeneralInfo";
import MedicationOtherInformation from "./medicationIncident/medicationOtherInformation";

import EmployeeDetails from "./employeeincident/employeeSimpleDetails";
import toast from "react-hot-toast";
import EmployeeInvestigationContentTab from "./healthIncidentInvestigation/employeeHealthInvestigation";

import EmployeeInvestigationGeneralInfo from "./healthIncidentInvestigation/employeeInvestigationGeneralInfo";
import EmployeeInvestigationOtherInformation from "./healthIncidentInvestigation/employeeInvestigationOtherInfo";

import IncidentsTab from "../../../components/incidents/employeeTab";
import EmployeeInvestigationDocumentHistory from "./healthIncidentInvestigation/employeeInvestigationDocumentHistory";
import EmployeeInvestigationReviews from "./healthIncidentInvestigation/employeeInvestigationReviews";
import { ArrowRight01Icon } from "hugeicons-react";
import { Link } from "react-router-dom";
import { FacilityDetailsBreadCrumbs } from "./DrugReactionDetails";

const EmployeeInvestigationDetailsContent = () => {
  // const { medicationId } = useParams();

  const { incidentId } = useParams();
  const [isFetching, setIsFetching] = useState(true);
  const [incidentDetails, setIncidentDetails] = useState({});
  const [incidentStatus, setIncidentStatus] = useState({});
  const employee_investigation_id = localStorage.getItem(
    "employee_investigation_id"
  );

  useEffect(() => {
    const fetchIncidentDetails = async () => {
      setIsFetching(true);
      try {
        const response = await api.get(
          `${API_URL}/incidents/employee_health_investigation/${employee_investigation_id}/`
        );
        if (response.status === 200) {
          setIncidentDetails(response.data.data);
          console.log(response.data.data);
          setIsFetching(false);
        }
        console.log(incidentDetails);
      } catch (error) {
        console.log(error);
        setIsFetching(false);
      }
    };

    fetchIncidentDetails();
  }, []);
  useEffect(() => {
    const getIncidentReviews = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/employee_health_investigation/${employee_investigation_id}/reviews/`
        );
        if (response.status === 200) {
          localStorage.setItem("incidentReviewsCount", response.data.length);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          window.customToast.error("Authentication error");
        } else {
          window.customToast.error("Failed to fetch incident reviews");
          console.error(error);
        }
      }
    };
    getIncidentReviews();
  }, []);
  useEffect(() => {
    const getDocumentHistory = async () => {
      try {
        const response = await api.get(
          `${API_URL}/activities/list/${employee_investigation_id}/`
        );
        if (response.status === 200) {
          localStorage.setItem("documentHistoryCount", response.data.length);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          window.customToast.error("Authentication error");
        } else {
          window.customToast.error("Failed to fetch document History");
          console.error(error);
        }
      }
    };
    getDocumentHistory();
  }, []);
  return (
    <div className="incident-details-page">
      {isFetching ? (
        <div className="fetching-data">Loading data</div>
      ) : (
        <div className="incident-details">
          <IncidentDetailsHeader
            data={incidentDetails}
            incidentDetailsId={employee_investigation_id}
            apiLink={"employee_health_investigation"}
            sendTo={"send-to-department"}
            managerAccess={true}
          />
          <div className="details">
            <EmployeeDetails
              data={incidentDetails}
              fullName={incidentDetails.name_of_injured_staff}
              location={incidentDetails.event_location}
              IncidentDate={incidentDetails.date_of_event}
              incidentTime={incidentDetails.time_of_event}
              status={incidentDetails.status}
              incidentDetails={
                <EmployeeInvestigationContentTab data={incidentDetails} />
              }
            />
            <IncidentTabs
              data={incidentDetails}
              statuses={incidentStatus}
              generalInformation={
                <EmployeeInvestigationGeneralInfo
                  data={incidentDetails}
                  incidentStatuses={incidentStatus}
                />
              }
              otherInformation={
                <EmployeeInvestigationOtherInformation data={incidentDetails} />
              }
              documentHistory={
                <EmployeeInvestigationDocumentHistory
                  incidentId={employee_investigation_id}
                />
              }
              reviews={
                <EmployeeInvestigationReviews
                  incidentId={employee_investigation_id}
                />
              }
              documents={"No documents available"}
            />
          </div>
        </div>
      )}
    </div>
  );
};

// export default EmployeeInvestigationDetailsContent;
const BreadCrumbs = () => {
  const { incidentId } = useParams();
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link> <ArrowRight01Icon />
      <Link to={"/incidents/"}>Incidents</Link> <ArrowRight01Icon />
      <Link to={"/incident/employee/investigation/"}>
        Employee Investigation
      </Link>{" "}
      <ArrowRight01Icon />
      <Link className="current-page"> #{incidentId}</Link>
    </div>
  );
};

const EmployeeInvestigationIncidentDetails = () => {
  const { incidentId } = useParams();
  const changeBreadCrumbs = localStorage.getItem("changeBreadCrumbs");
  return (
    <div>
      <DashBoardContainer
        content={<EmployeeInvestigationDetailsContent />}
        breadCrumbs={
          changeBreadCrumbs ? (
            <FacilityDetailsBreadCrumbs incidentID={incidentId} />
          ) : (
            <BreadCrumbs />
          )
        }
      />
    </div>
  );
};

export default EmployeeInvestigationIncidentDetails;
