import { Hold03Icon, Timer01Icon } from "@hugeicons/react-pro";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { validateStep } from "../validators/generalIncidentForm";
import api, {
  API_URL,
  calculateAge,
  checkCurrentAccount,
  cleanedData,
} from "../../../api";

import RichTexField from "./inputs/richTexField";
import CustomDatePicker from "./inputs/datePicker";
import LostAndFoundSuccess from "../../successMessage/lostfoundsuccess";
import FormCompleteMessage from "./formCompleteMessage";
import { useRef } from "react";
import postDocumentHistory from "./documentHistory/postDocumentHistory";
import CustomTimeInput from "./inputs/customTimeInput";
import {
  Cancel01Icon,
  CheckmarkSquare01Icon,
  CheckmarkSquare02Icon,
  SquareIcon,
} from "hugeicons-react";
import { FacilityCard } from "../../../pages/dashboard/dashboardContainer";
import DraftPopup from "./draftPopup";

const LostAndFoundForm = ({ togglePopup }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const currentStepRef = useRef(currentStep);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("lost-property");
  const [reporterFirstName, setReporterFirstName] = useState("");
  const [reporterLastName, setReporterLastName] = useState("");
  const [patientFirstName, setPatientFirstName] = useState("");
  const [patientLastName, setPatientLastName] = useState("");
  const [dateReporting, setDateReporting] = useState("");
  const [timeReporting, setTimeReporting] = useState("");
  const [dateFound, setDateFound] = useState("");
  const [dateLetterWasSent, setDateLetterWasSent] = useState("");
  const [letterSentToWho, setLetterSentToWho] = useState("");
  const [dateBirth, setDateBirth] = useState("");
  const [age, setAge] = useState("");

  const [timeFound, setTimeFound] = useState("");
  const [relationship, setRelationship] = useState("");
  const [descriptionOfProperty, setDescriptionOfProperty] = useState("");
  const [location, setLocation] = useState("");
  const [locationReturned, setLocationReturned] = useState("");
  const [personWhoFoundProperty, setPersonWhoFoundProperty] = useState("");
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [checkboxReturnedChecked, setCheckboxReturnedChecked] = useState(false);
  const [propertyReturnedTo, setPropertyReturnedTo] = useState("");
  const [dateReturned, setDateReturned] = useState("");
  const [timeReturned, setTimeReturned] = useState("");
  const [unclaimedPropertyDisposal, setUnclaimedPropertyDisposal] =
    useState("");
  const [nameOfPersonReportingLoss, setNameOfPersonReportingLoss] =
    useState("");
  const [actionTaken, setActionTaken] = useState("");

  useEffect(() => {
    currentStepRef.current = currentStep;
  }, [currentStep]);

  useEffect(() => {
    localStorage.setItem("updateNewIncident", "false");
    console.log(localStorage.getItem("updateNewIncident"));
    const handleKeyDown = (event) => {
      // Check if Ctrl or Alt key is pressed
      if (event.key === "Enter") {
        event.preventDefault();
        if (currentStepRef.current < 3) {
          document.getElementById("continue-button").click();
        } else if (currentStepRef.current === 3) {
          document.getElementById("save-button").click();
        } else {
          return;
        }
      }

      if (event.ctrlKey || event.altKey) {
        switch (event.key) {
          case "s": // Ctrl + S
            event.preventDefault(); // Prevent default browser action
            if (currentStepRef.current < 3) {
              document.getElementById("continue-button").click();
            } else if (currentStepRef.current === 3) {
              document.getElementById("save-button").click();
            } else {
              return;
            }
            break;
          case "b":
            event.preventDefault();
            if (currentStepRef.current > 1 && currentStepRef.current <= 3) {
              document.getElementById("back-button").click();
            }
            console.log(currentStepRef.current);
            break;
          case "f": // Ctrl + F
            event.preventDefault(); // Prevent default browser action
            document.getElementById("name").focus();
            break;
          case "e": // Ctrl + E
            event.preventDefault(); // Prevent default browser action
            document.getElementById("email").focus();
            break;
          default:
            break;
        }
      }
    };

    // Add event listener when component mounts
    document.addEventListener("keydown", handleKeyDown);

    // Clean up event listener when component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const handleChange = (e) => {
    setSelectedOption(e.target.value);
    setCurrentStep(1);
  };

  const handleDateOfBirth = (date) => {
    const calculatedAge = calculateAge(date);
    setDateBirth(date);
    setAge(calculatedAge);
  };

  async function postStepOne() {
    const data = {
      current_step: currentStep,
      facility: checkCurrentAccount(),
      person_taking_report_info: {
        user_data: {
          first_name: reporterFirstName,
          last_name: reporterLastName,
        },
      },
      item_description: descriptionOfProperty,
      date_reported: dateReporting,
      time_reported: timeReporting,
      relation_to_patient: relationship,
      person_reporting_info: {
        user_data: {
          first_name: patientFirstName,
          last_name: patientLastName,
        },
        profile_data: {
          age: age,
          date_of_birth: dateBirth,
        },
      },

      status: "Draft",
    };

    try {
      const response = await api.post(
        `${API_URL}/incidents/lost_and_found/new/`,
        cleanedData(data)
      );

      if (response.status === 200 || response.status === 201) {
        localStorage.setItem("lost_found_id", response.data.Incident.id);
        window.customToast.success("Data posted successfully");
        localStorage.setItem("updateNewIncident", "true");
        console.log(localStorage.getItem("updateNewIncident"));
        if (currentStep <= 4) {
          setCurrentStep(currentStep + 1);
        }

        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      window.customToast.error("Failed to post data");
    }
  }

  async function patchData(data) {
    try {
      const lostFoundId = localStorage.getItem("lost_found_id");
      const response = await api.patch(
        `${API_URL}/incidents/lost_and_found/${lostFoundId}/update/`,
        cleanedData(data)
      );

      if (response.status === 200 || response.status === 201) {
        window.customToast.success("Data posted successfully");

        setIsLoading(false);
        if (currentStep <= 4) {
          setCurrentStep(currentStep + 1);
        }

        if (currentStep === 4) {
          postDocumentHistory(lostFoundId, "added a new incident", "create");
          localStorage.setItem("updateNewIncident", "false");
        }

        setIsLoading(false);
      }

      console.log(response.data);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      window.customToast.error("Failed to post data");
      window.customToast.error(error.message);
    }
  }

  const handleSaveChange = async () => {
    if (currentStep === 3) {
      // if (!checkboxChecked && !unclaimedPropertyDisposal) {
      //   window.customToast.error("Enter unclaimed property disposal");
      //   isValid = false;
      //   setIsLoading(false);
      // }
      let data;
      if (!unclaimedPropertyDisposal) {
        data = {
          current_step: currentStep,
          status: "Open",
        };
      } else {
        data = {
          current_step: currentStep,
          disposal_of_unclaimed_property: unclaimedPropertyDisposal,
          status: "Open",
          date_letter_sent: dateLetterWasSent,
          letter_sent_to: letterSentToWho,
        };
      }

      setIsLoading(true);
      patchData(data);

      // if (isValid) {

      // }
    }
  };

  const handleNextStep = () => {
    if (currentStep === 1) {
      const isValid = validateStep({
        "Reporter first name": reporterFirstName,
        "Reporter last name": reporterLastName,
        "Patient first name": patientFirstName,
        "Patient last name": patientLastName,
        "Date Reporting": dateReporting,
        "Time Reporting": timeReporting,
        "Description Of Property": descriptionOfProperty,
      });

      if (isValid) {
        setIsLoading(true);
        if (localStorage.getItem("updateNewIncident") === "false") {
          postStepOne();
        }

        if (localStorage.getItem("updateNewIncident") === "true") {
          patchData({
            current_step: currentStep,
            person_taking_report_info: {
              user_data: {
                first_name: reporterFirstName,
                last_name: reporterLastName,
              },

              profile_data: {
                age: age,
                date_of_birth: dateBirth,
              },
            },
            item_description: descriptionOfProperty,
            date_reported: dateReporting,
            time_reported: timeReporting,
            relation_to_patient: relationship,
            person_reporting_info: {
              user_data: {
                first_name: patientFirstName,
                last_name: patientLastName,
              },
            },

            status: "Draft",
          });
        }
      }
    }

    if (currentStep === 2) {
      const isValid = validateStep({
        action_taken: actionTaken,
        ...(checkboxReturnedChecked && {
          returned_to: propertyReturnedTo,
          date_returned: dateReturned,
          time_returned: timeReturned,
          location_returned: locationReturned,
        }),
        ...(checkboxChecked && {
          location_found: location,
          date_found: dateFound,
          time_found: timeFound,
          found_by: personWhoFoundProperty,
        }),
      });

      const data = {
        current_step: currentStep,
        action_taken: actionTaken,
        ...(checkboxReturnedChecked && {
          returned_to: propertyReturnedTo,
          date_returned: dateReturned,
          time_returned: timeReturned,
        }),
        ...(checkboxChecked && {
          location_found: location,
          date_found: dateFound,
          time_found: timeFound,
          found_by: personWhoFoundProperty,
        }),
      };
      if (isValid) {
        setIsLoading(true);
        patchData(data);
      }
    }
  };

  const handlePreviousStep = () => {
    currentStep > 1 ? setCurrentStep(currentStep - 1) : setCurrentStep(1);
  };

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
  };

  const handleCheckboxReturn = () => {
    setCheckboxReturnedChecked(!checkboxReturnedChecked);
  };

  return (
    <div className="forms-container">
      <div className="forms-header">
        <h2>Lost and Found Property Report</h2>
        <Cancel01Icon
          className="close-popup"
          onClick={() => {
            togglePopup();
            localStorage.setItem("updateNewIncident", "false");
          }}
        />
        {currentStep < 4 ? (
          <div className="form-steps">
            <div className={currentStep === 1 ? "step current-step" : "step"}>
              <div className="icon">
                <i className="fa-solid fa-circle-check"></i>
              </div>
              <div className="name">
                <p className="step-name">Step 1/3</p>
              </div>
            </div>
            <div className="divider"></div>
            <div className={currentStep === 2 ? "step current-step" : "step"}>
              <div className="icon">
                <i className="fa-solid fa-circle-check"></i>
              </div>
              <div className="name">
                <p className="step-name">Step 2/3</p>
              </div>
            </div>
            <div className="divider"></div>
            <div className={currentStep === 3 ? "step current-step" : "step"}>
              <div className="icon">
                <i className="fa-solid fa-circle-check"></i>
              </div>
              <div className="name">
                <p className="step-name">Step 3/3</p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <FacilityCard />
        <DraftPopup
          incidentString="lost_and_found"
          incidentType="lost_and_found"
        />
      </div>

      <form className="newIncidentForm">
        {currentStep === 1 && (
          <div className="step">
            <div className="half">
              <div className="field">
                <label htmlFor="reporterFirstName">
                  Person taking report first name:
                </label>
                <input
                  onChange={(e) => setReporterFirstName(e.target.value)}
                  value={reporterFirstName}
                  type="text"
                  name="reporterFirstName"
                  id="reporterFirstName"
                  placeholder="Enter first name"
                />
              </div>
              <div className="field">
                <label htmlFor="reporterLastName">
                  Person taking report last name:
                </label>
                <input
                  onChange={(e) => setReporterLastName(e.target.value)}
                  value={reporterLastName}
                  type="text"
                  name="reporterLastName"
                  id="reporterLastName"
                  placeholder="Enter last name"
                />
              </div>
            </div>

            <div className="half">
              <div className="date-of-injury field">
                <label htmlFor="dateBirth">Date of birth</label>

                <CustomDatePicker
                  selectedDate={dateBirth}
                  setSelectedDate={handleDateOfBirth}
                />
              </div>

              <div className="field job-title">
                <label htmlFor="age">Age</label>
                <input
                  onChange={(e) => setAge(e.target.value)}
                  value={age}
                  type="text"
                  name="age"
                  id="age"
                  placeholder="Enter age"
                />
              </div>
            </div>

            <div className="half">
              <div className="field">
                <label htmlFor="dateOfReporting">Date Reported</label>
                <CustomDatePicker
                  selectedDate={dateReporting}
                  setSelectedDate={setDateReporting}
                />
              </div>

              <div className="field">
                <label htmlFor="timeOfReporting">Time Reported</label>
                <CustomTimeInput setTime={setTimeReporting} />
              </div>
            </div>
            <div className="col">
              <label htmlFor="patientFirstName">
                Name of Patient, Patient Representative, or other individual
                reporting loss:
              </label>
              <div className="half">
                <div className="field name">
                  <input
                    onChange={(e) => setPatientFirstName(e.target.value)}
                    value={patientFirstName}
                    type="text"
                    name="patientFirstName"
                    id="patientFirstName"
                    placeholder="Enter first name"
                  />
                </div>
                <div className="field name">
                  <input
                    onChange={(e) => setPatientLastName(e.target.value)}
                    value={patientLastName}
                    type="text"
                    name="patientLastName"
                    id="patientLastName"
                    placeholder="Enter last name"
                  />
                </div>
              </div>
            </div>
            <div className="field name">
              <label htmlFor="relationship">
                Relationship to Patient (if applicable):
              </label>
              <input
                onChange={(e) => setRelationship(e.target.value)}
                value={relationship}
                type="text"
                name="relationship"
                id="relationship"
                placeholder="Enter Relationship"
              />
            </div>
            <div className="field name">
              <label htmlFor="descriptionOfProperty">
                Full description of the missing, lost, or misplaced property
                (including money):
              </label>
              <RichTexField
                value={descriptionOfProperty}
                onEditorChange={setDescriptionOfProperty}
              />
            </div>
          </div>
        )}

        {currentStep === 2 && (
          <div className="step">
            <div className="field name">
              <label htmlFor="actionTaken">
                Actions taken to locate the missing, lost, or misplaced
                property:
              </label>
              <RichTexField
                value={actionTaken}
                onEditorChange={setActionTaken}
              />
            </div>

            <div onClick={handleCheckboxChange} className="field checkbox">
              {checkboxChecked ? (
                <CheckmarkSquare02Icon size={20} />
              ) : (
                <SquareIcon size={20} />
              )}
              <p>Check if missing, lost, or misplaced property was found</p>
            </div>
            {checkboxChecked && (
              <>
                <div className="field name">
                  <label htmlFor="location">
                    Location where property was found:
                  </label>
                  <input
                    onChange={(e) => setLocation(e.target.value)}
                    value={location}
                    type="text"
                    name="location"
                    id="location"
                    placeholder="Enter location found"
                  />
                </div>
                <div className="half">
                  <div className="field">
                    <label htmlFor="dateFound">Date Property Found</label>
                    <CustomDatePicker
                      selectedDate={dateFound}
                      setSelectedDate={setDateFound}
                    />
                  </div>

                  <div className="field">
                    <label htmlFor="timeFound">Time property Found</label>
                    <CustomTimeInput setTime={setTimeFound} />
                  </div>
                </div>
                <div className="field name">
                  <label htmlFor="personWhoFoundProperty">
                    Name of person who found property:
                  </label>
                  <input
                    onChange={(e) => setPersonWhoFoundProperty(e.target.value)}
                    value={personWhoFoundProperty}
                    type="text"
                    name="personWhoFoundProperty"
                    id="personWhoFoundProperty"
                    placeholder="Enter Name"
                  />
                </div>
              </>
            )}

            <div onClick={handleCheckboxReturn} className="field checkbox">
              {checkboxReturnedChecked ? (
                <CheckmarkSquare02Icon size={20} />
              ) : (
                <SquareIcon size={20} />
              )}
              <p>Check if missing, lost, or misplaced property was returned</p>
            </div>

            {checkboxReturnedChecked && (
              <>
                <div className="half">
                  <div className="field name">
                    <label htmlFor="location">
                      Location where property was returned:
                    </label>
                    <input
                      onChange={(e) => setLocationReturned(e.target.value)}
                      value={locationReturned}
                      type="text"
                      name="locationReturned"
                      id="locationReturned"
                      placeholder="Enter location returned"
                    />
                  </div>
                  <div className="field name">
                    <label htmlFor="propertyReturnedTo">
                      Property returned to:
                    </label>
                    <input
                      onChange={(e) => setPropertyReturnedTo(e.target.value)}
                      value={propertyReturnedTo}
                      type="text"
                      name="propertyReturnedTo"
                      id="propertyReturnedTo"
                      placeholder="Enter Name"
                    />
                  </div>
                </div>

                <div className="half">
                  <div className="field">
                    <label htmlFor="dateReturned">
                      Date property was returned
                    </label>
                    <CustomDatePicker
                      selectedDate={dateReturned}
                      setSelectedDate={setDateReturned}
                    />
                  </div>

                  <div className="field">
                    <label htmlFor="timeReturned">Time property returned</label>
                    <CustomTimeInput setTime={setTimeReturned} />
                  </div>
                </div>
              </>
            )}
          </div>
        )}

        {currentStep === 3 && (
          <div className="step">
            <div className="field name">
              <label htmlFor="unclaimedPropertyDisposal">
                Disposal of Unclaimed Property
              </label>
              <RichTexField
                value={unclaimedPropertyDisposal}
                onEditorChange={setUnclaimedPropertyDisposal}
              />
            </div>
            <div className="half">
              <div className="field">
                <label htmlFor="dateReturned">Date letter was sent</label>
                <CustomDatePicker
                  selectedDate={dateLetterWasSent}
                  setSelectedDate={setDateLetterWasSent}
                />
              </div>

              <div className="field">
                <label htmlFor="letterSentTo">Letter was sent to</label>
                <input
                  onChange={(e) => setLetterSentToWho(e.target.value)}
                  value={letterSentToWho}
                  type="text"
                  name="letterSentTo"
                  id="letterSentTo"
                  placeholder="Enter person"
                />
              </div>
            </div>
          </div>
        )}

        {currentStep === 4 && <FormCompleteMessage />}
      </form>
      <div className="buttons">
        {currentStep > 1 && currentStep <= 3 && (
          <button
            onClick={handlePreviousStep}
            id="back-button"
            className="secondary-button"
          >
            <i className="fa-solid fa-arrow-left"></i>
            <span>Back</span>
          </button>
        )}

        {currentStep === 3 ? (
          <button
            className="primary-button"
            id="save-button"
            onClick={handleSaveChange}
          >
            <span>{isLoading ? "Processing..." : "Save Incident"}</span>
            <i className="fa-solid fa-arrow-right"></i>
          </button>
        ) : (
          currentStep < 3 && (
            <button
              onClick={handleNextStep}
              id="continue-button"
              className="primary-button"
            >
              <span>{isLoading ? "Processing..." : "Save & Continue"}</span>
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default LostAndFoundForm;
