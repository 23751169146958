import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import DashBoardContainer from "../dashboardContainer";
import IncidentDetailsHeader from "./incidentDetails/incidentDetailsHeader";
import IncidentDetails from "./incidentDetails/incidentDetails";
import IncidentTabs from "./incidentDetails/incidentDetailsTabs";
import GeneralIncidentDetailsContentTab from "./generalIncident/incidentDetails";
import api, { API_URL } from "../../../api";
import "../../../assets/css/pages/incidents/general/generalIncidentDetailsPage.css";
import GeneralIncidentOtherInformation from "./generalIncident/otherInformation";
import GeneralIncidentGeneralInformation from "./generalIncident/generalInformation";
import GeneralIncidentReviews from "./generalIncident/generalIncidentReviews";
import GeneralIncidentDocumentHistory from "./generalIncident/generalIncidentDocumentHistory";
import toast from "react-hot-toast";
import { ArrowRight01Icon, Download01Icon } from "hugeicons-react";
import FilesList from "../../../components/documents/filesList";
import { FacilityDetailsBreadCrumbs } from "./DrugReactionDetails";

const GeneralIncidentDetailsContent = () => {
  const { incidentId } = useParams();
  const [isFetching, setIsFetching] = useState(true);
  const [incidentDetails, setIncidentDetails] = useState({});
  const [latestIncidentDetails, setLatestIncidentDetails] = useState({});
  const [useOriginalVersion, setUseOriginalVersion] = useState(true);
  const [currentIncidentData, setCurrentIncidentData] = useState({});

  // Fetch incident details based on the selected version
  const fetchIncidentDetails = async () => {
    setIsFetching(true);
    try {
      let response;

      // Fetch the original version of the incident
      if (useOriginalVersion) {
        response = await api.get(`${API_URL}/incidents/general/${incidentId}/`);
        setIncidentDetails(response.data); // Store the original data
        setCurrentIncidentData(response.data); // Set current data for UI
        console.log(response.data);

        if (response.status === 404) {
          return;
        }
      } else {
        // Fetch the latest modified version of the incident
        const res = await api.get(
          `${API_URL}/incidents/general/${incidentId}/`
        );
        const latestIncident = res.data.modifications.versions.find((mod) => {
          return mod.latest === true;
        });

        if (latestIncident) {
          response = await api.get(
            `${API_URL}/incidents/general/${incidentId}/versions/${latestIncident.id}/`
          );
          console.log(response.data);
          console.log(latestIncident);
        } else {
          response = res;
        }

        setLatestIncidentDetails(response.data); // Store the latest modified version
        setCurrentIncidentData(response.data); // Set current data for UI
      }

      setIsFetching(false); // Stop loading state
    } catch (error) {
      console.error("Error fetching incident details:", error);
      setIsFetching(false);
    }
  };

  // UseEffect to fetch data when either the incidentId or useOriginalVersion changes
  useEffect(() => {
    fetchIncidentDetails(); // Fetch incident data when version toggles or incidentId changes
  }, [incidentId, useOriginalVersion]); // Dependencies trigger re-fetch

  return (
    <div className="incident-details-page">
      {isFetching ? (
        <div className="fetching-data">Loading data...</div>
      ) : (
        <div className="incident-details">
          <IncidentDetailsHeader
            data={useOriginalVersion ? incidentDetails : latestIncidentDetails}
            incidentDetailsId={incidentId}
            apiLink={"general"}
            sendTo={"send-to-department"}
            useOriginalVersion={useOriginalVersion}
            setCurrentIncidentData={setCurrentIncidentData} // Update UI on toggle
          />
          <div className="details">
            <IncidentDetails
              data={currentIncidentData} // Use current incident data here
              fullName={
                (currentIncidentData?.patient_visitor?.user?.last_name || "") +
                " " +
                (currentIncidentData?.patient_visitor?.user?.first_name || "")
              }
              sex={currentIncidentData?.patient_visitor?.gender || ""}
              IncidentDate={currentIncidentData?.incident_date}
              IncidentDOB={currentIncidentData?.patient_visitor?.date_of_birth}
              IncidentPatientAge={currentIncidentData?.patient_visitor?.age}
              incidentTime={currentIncidentData?.incident_time}
              incidentCategory={currentIncidentData?.category}
              incidentDetails={
                <GeneralIncidentDetailsContentTab data={currentIncidentData} />
              }
            />
            <IncidentTabs
              data={currentIncidentData}
              statuses={currentIncidentData}
              generalInformation={
                <GeneralIncidentGeneralInformation
                  data={currentIncidentData}
                  incidentStatuses={currentIncidentData}
                />
              }
              otherInformation={
                <GeneralIncidentOtherInformation data={currentIncidentData} />
              }
              reviews={<GeneralIncidentReviews />}
              documentHistory={
                <GeneralIncidentDocumentHistory incidentId={incidentId} />
              }
              documents={<IncidentDocuments incidentId={incidentId} />}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const IncidentDocuments = ({ incidentId }) => {
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await api.get(
          `/incidents/general/${incidentId}/documents/`
        );
        if (response.status === 200) {
          setDocuments(response.data);
          console.log("Fetched: ", response.data);
          localStorage.setItem("incidentDocumentCount", response.data.length);
        }
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };
    fetchDocuments();
  }, []);

  return (
    <FilesList
      setDocuments={setDocuments}
      documents={documents}
      showDownload={true}
    />
  );
};

const BreadCrumbs = () => {
  const { incidentId } = useParams();
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link> <ArrowRight01Icon />
      <Link to={"/incidents/"}>Incidents</Link> <ArrowRight01Icon />
      <Link to={"/incident/general/"}>General Incidents List</Link>{" "}
      <ArrowRight01Icon />
      <Link className="current-page"> #{incidentId}</Link>
    </div>
  );
};

const GeneralIncidentDetails = () => {
  const { incidentId } = useParams();
  const changeBreadCrumbs = localStorage.getItem("changeBreadCrumbs");
  return (
    <div>
      <DashBoardContainer
        content={<GeneralIncidentDetailsContent />}
        breadCrumbs={
          changeBreadCrumbs ? (
            <FacilityDetailsBreadCrumbs incidentID={incidentId} />
          ) : (
            <BreadCrumbs />
          )
        }
      />
    </div>
  );
};

export default GeneralIncidentDetails;
