import { Alert01Icon, Cancel01Icon, CheckmarkCircle02Icon, InformationCircleIcon } from 'hugeicons-react'
import React, { useState } from 'react'

const SuccessMessage = ({ message, hideMessage, handleHideMessage }) => {
    const [showMessage, setShowMessage] = useState(true)

    const toggleMessage = () => {
        setShowMessage(false)
        handleHideMessage()
    }

    return (
        <>
            {message && showMessage && <div className="success-message">
                <div className="message">
                    <CheckmarkCircle02Icon className='message-icon' />
                    <p>{message}</p>
                </div>
                {
                    hideMessage && <Cancel01Icon onClick={toggleMessage} className='message-icon' />
                }
            </div>}
        </>
    )
}

export default SuccessMessage
