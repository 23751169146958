import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashBoardContainer from "../dashboardContainer";
import IncidentDetailsHeader from "./incidentDetails/incidentDetailsHeader";
import IncidentDetails from "./incidentDetails/incidentDetails";
import IncidentTabs from "./incidentDetails/incidentDetailsTabs";
// import GeneralIncidentDetailsContentTab from "./generalIncident/incidentDetails";
import api, { API_URL } from "../../../api";

import "../../../assets/css/pages/incidents/general/generalIncidentDetailsPage.css";
// import GeneralIncidentOtherInformation from "./generalIncident/otherInformation";
// import GeneralIncidentGeneralInformation from "./generalIncident/generalInformation";
import MedicationDetailsContentTab from "./medicationIncident/medication";
import MedicationGeneralInfo from "./medicationIncident/medicationGeneralInfo";
import MedicationOtherInformation from "./medicationIncident/medicationOtherInformation";
import MedicationDocumentHistory from "./medicationIncident/medicationDocumentHistory";
import MedicationReviews from "./medicationIncident/medicationReviews";
import toast from "react-hot-toast";
import { ArrowRight01Icon } from "hugeicons-react";
import { Link } from "react-router-dom";
import FilesList from "../../../components/documents/filesList";
import { FacilityDetailsBreadCrumbs } from "./DrugReactionDetails";

const MedicationDetailsContent = () => {
  const { medicationId } = useParams();
  const [isFetching, setIsFetching] = useState(true);
  const [incidentDetails, setIncidentDetails] = useState({});
  const [incidentStatus, setIncidentStatus] = useState({});
  const [latestIncidentDetails, setLatestIncidentDetails] = useState({});
  const [useOriginalVersion, setUseOriginalVersion] = useState(true);
  const [currentIncidentData, setCurrentIncidentData] = useState({});

  const fetchIncidentDetails = async () => {
    setIsFetching(true);

    try {
      let response;
      // Fetch the original version of the incident
      if (useOriginalVersion) {
        response = await api.get(
          `${API_URL}/incidents/medication_error/${medicationId}/`
        );
        setIncidentDetails(response.data); // Store the original data
        setCurrentIncidentData(response.data); // Set current data for UI
        console.log(response.data);
      } else {
        // Fetch the latest modified version of the incident
        const res = await api.get(
          `${API_URL}/incidents/medication_error/${medicationId}/`
        );
        const latestIncident = res.data.modifications.versions.find((mod) => {
          return mod.latest === true;
        });

        if (latestIncident) {
          response = await api.get(
            `${API_URL}/incidents/medication_error/${medicationId}/versions/${latestIncident.id}/`
          );
          console.log(response.data);
          console.log(latestIncident);
        } else {
          response = res;
        }

        setLatestIncidentDetails(response.data); // Store the latest modified version
        setCurrentIncidentData(response.data); // Set current data for UI
      }
      setIsFetching(false);
    } catch (error) {
      console.log(error);
      setIsFetching(false);
    }

    // try {
    //   const response = await api.get(
    //     `${API_URL}/incidents/medication_error/${medicationId}/`
    //   );
    //   if (response.status === 200) {
    //     //   setIncidentStatus(response.data.statuses);
    //     setIncidentDetails(response.data.data);
    //     console.log(response.data.data);
    //     setIsFetching(false);
    //   }
    //   console.log(incidentDetails);
    // } catch (error) {
    //   console.log(error);
    //   setIsFetching(false);
    // }
  };

  useEffect(() => {
    fetchIncidentDetails(); // Fetch incident data when version toggles or incidentId changes
  }, [medicationId, useOriginalVersion]);

  useEffect(() => {
    const getIncidentReviews = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/medication_error/${medicationId}/reviews/`
        );
        if (response.status === 200) {
          localStorage.setItem("incidentReviewsCount", response.data.length);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          window.customToast.error("Authentication error");
        } else {
          window.customToast.error("Failed to fetch incident reviews");
          console.error(error);
        }
      }
    };
    getIncidentReviews();
  }, []);
  useEffect(() => {
    const getDocumentHistory = async () => {
      try {
        const response = await api.get(
          `${API_URL}/activities/list/${medicationId}/`
        );
        if (response.status === 200) {
          localStorage.setItem("documentHistoryCount", response.data.length);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          window.customToast.error("Authentication error");
        } else {
          window.customToast.error("Failed to fetch document History");
          console.error(error);
        }
      }
    };
    getDocumentHistory();
  }, []);
  return (
    <div className="incident-details-page">
      {isFetching ? (
        <div className="fetching-data">Loading data</div>
      ) : (
        <div className="incident-details">
          {incidentDetails.modifications ? (
            <IncidentDetailsHeader
              data={
                useOriginalVersion ? incidentDetails : latestIncidentDetails
              }
              incidentDetailsId={medicationId}
              apiLink={"medication_error"}
              sendTo={"send-to-department"}
              managerAccess={false}
              useOriginalVersion={useOriginalVersion}
              setCurrentIncidentData={setCurrentIncidentData}
            />
          ) : (
            ""
          )}

          <div className="details">
            <IncidentDetails
              data={currentIncidentData}
              fullName={
                currentIncidentData.patient
                  ? `${currentIncidentData.patient?.user?.last_name} ${currentIncidentData.patient?.user?.first_name}`
                  : null
              }
              sex={currentIncidentData.patient?.user?.gender}
              IncidentDate={currentIncidentData.date_of_error}
              incidentTime={currentIncidentData.time_of_error}
              incidentCategory={currentIncidentData.category}
              incidentDetails={
                <MedicationDetailsContentTab data={currentIncidentData} />
              }
              hasSex={false}
            />
            <IncidentTabs
              data={currentIncidentData}
              statuses={incidentStatus}
              generalInformation={
                <MedicationGeneralInfo
                  data={currentIncidentData}
                  incidentStatuses={incidentStatus}
                />
              }
              otherInformation={
                <MedicationOtherInformation data={currentIncidentData} />
              }
              documentHistory={
                <MedicationDocumentHistory incidentId={medicationId} />
              }
              reviews={<MedicationReviews incidentId={medicationId} />}
              documents={<IncidentDocuments incidentId={medicationId} />}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const IncidentDocuments = ({ incidentId, apiLink }) => {
  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await api.get(
          `/incidents/medication_error/${incidentId}/documents/`
        );
        if (response.status === 200) {
          setDocuments(response.data);
          console.log(response.data);
          localStorage.setItem("incidentDocumentCount", response.data.length);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchDocuments();
  }, []);
  return <FilesList documents={documents} showDownload={true} />;
};
const BreadCrumbs = () => {
  const { medicationId } = useParams();
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link> <ArrowRight01Icon />
      <Link to={"/incidents/"}>Incidents</Link> <ArrowRight01Icon />
      <Link to={"/incident/medication_error/"}>Medication Error List</Link>{" "}
      <ArrowRight01Icon />
      <Link className="current-page">#{medicationId}</Link>
    </div>
  );
};
const MedicationDetails = () => {
  const { medicationId } = useParams();
  const changeBreadCrumbs = localStorage.getItem("changeBreadCrumbs");
  return (
    <div>
      <DashBoardContainer
        content={<MedicationDetailsContent />}
        breadCrumbs={
          changeBreadCrumbs ? (
            <FacilityDetailsBreadCrumbs incidentID={medicationId} />
          ) : (
            <BreadCrumbs />
          )
        }
      />
    </div>
  );
};

export default MedicationDetails;
