import React from "react";

const GrievanceIncidentGeneralInfo = ({ data, incidentStatuses }) => {
  return (
    <div className="incident-type-data">
      <div className="general-col">
        <div className="general-sub-col">
          <h3>Complaint or Concern</h3>
          <small>{data.complaint_or_concern}</small>
        </div>
        <div className="general-sub-col">
          <h3>Initial corrective actions taken to resolve the complaint</h3>
          <small>{data.initial_corrective_actions}</small>
        </div>
        <div className="street">
          <small>Title</small>
          <h4>{data.title || "Not provided"}</h4>
        </div>
        <div className="general-sub-col">
          <h3>Outcome</h3>
          <small>{data.outcome}</small>
        </div>
        <div className="general-sub-col">
          <h3>Adverse patient outcome</h3>
          <small>{data.adverse_patient_outcome ? "Yes" : "No"}</small>
        </div>

        <div className="address"></div>
      </div>
    </div>
  );
};

export default GrievanceIncidentGeneralInfo;
