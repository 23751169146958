import React, { useEffect, useState } from "react";
import DashBoardContainer from "./dashboardContainer";
import { Link } from "react-router-dom";
import {
  ArrowRight01Icon,
  GoogleDocIcon,
  LayersLogoIcon,
  ListViewIcon,
  PencilEdit02Icon,
  Sad01Icon,
  SquareLock02Icon,
} from "hugeicons-react";
import api, { calculateAge } from "../../api";
import "../../assets/css/pages/profile/profile.css";
import ProfileReports from "../../components/profile/profileReports";
import UserComplaints from "../../components/profile/profileComplaints";
import ProfileDocuments from "../../components/profile/profileDocuments";
import { DraftsTab } from "./incidentTrackingPage";
import ChangePassword from "../../components/profile/froms/changePassword";
import EditProfileForm from "../../components/profile/froms/editProfleForm";
import PreferencesForm from "../../components/preferences/NewPreference";

const ProfilePageContent = () => {
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);
  const [showUpdateUserForm, setShowUpdateUserForm] = useState(false);
  const [showPreferencesForm, setShowPreferencesForm] = useState(false);
  const [age, setAge] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      setError("");
      try {
        const response = await api.get(`accounts/profile/`);
        setProfile(response.data);
        console.log(response.data);
        setIsLoading(false);
        localStorage.setItem("userId", response.data.id);

        if (response.data.profile.date_of_birth) {
          setAge(calculateAge(response.data.profile.date_of_birth));
        }
      } catch (error) {
        if (error.response) {
          setError(
            error.response.data.message ||
              error.response.data.error ||
              "Error fetching profile data"
          );
        }
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchProfile();
  }, []);

  return isLoading ? (
    "..."
  ) : (
    <div className="dashboard-page-content profile-page">
      {showUpdateUserForm ? (
        <EditProfileForm setShowUpdateUserForm={setShowUpdateUserForm} />
      ) : (
        ""
      )}
      {showChangePasswordForm ? (
        <ChangePassword setShowChangePasswordForm={setShowChangePasswordForm} />
      ) : (
        ""
      )}

      {showPreferencesForm && (
        <div className="pop-up">
          <PreferencesForm setShowPreferencesFrom={setShowPreferencesForm} />
        </div>
      )}
      {error && <div className="error-message">{error}</div>}
      <div className="profile-information">
        <div className="basic-info">
          <div className="profile-info">
            <img
              src={
                profile?.profile_url
                  ? profile?.profile_url
                  : "/images/branding/logo.png"
              }
              alt=""
              className="profile-image"
            />
            <h3 className="full-name">
              {profile?.last_name} {profile?.first_name}
            </h3>
            <small className="email">{profile?.email}</small>
            <div className="positions">
              {profile?.permissions?.length > 3 ? (
                <>
                  {profile?.permissions
                    ?.slice(0, 3)
                    .map((permission, index) => (
                      <div key={index} className="position">
                        <div className="dot"></div>
                        <small>{permission}</small>
                      </div>
                    ))}
                </>
              ) : (
                profile?.permissions?.slice(0, 3).map((permission, index) => (
                  <div key={index} className="position">
                    <div className="dot"></div>
                    <small>{permission}</small>
                  </div>
                ))
              )}
            </div>
            <div className="profile-actions">
              <Link
                className="button primary-button"
                onClick={(e) => setShowUpdateUserForm(true)}
              >
                <PencilEdit02Icon />
                <span>Edit profile</span>
              </Link>
              <button
                onClick={(e) => setShowChangePasswordForm(true)}
                type="button"
                className="tertiary-button"
              >
                <SquareLock02Icon />
                <span>Change password</span>
              </button>
            </div>
          </div>

          <div className="personal-info">
            <div
              onClick={() => setShowPreferencesForm(true)}
              className="edit close-icon"
            >
              <PencilEdit02Icon />
              <p>Edit</p>
            </div>
            <h2>Preferences</h2>
            <div className="profile-items">
              <div className="item">
                <small>Timezone</small>
                <p>{profile.preferences?.timezone || "UTC | Default"}</p>
              </div>
            </div>
          </div>
          <div className="personal-info">
            <h2>Person information</h2>
            <div className="profile-items">
              <div className="item">
                <small>First name</small>
                <p>{profile.first_name || "Not provided"}</p>
              </div>
              <div className="item">
                <small>Last name</small>
                <p>{profile.last_name || "Not provided"}</p>
              </div>
              <div className="item">
                <small>Date of birth</small>
                <p>{profile.profile.date_of_birth || "Not provided"}</p>
              </div>
              <div className="item">
                <small>Gender</small>
                <p>{profile.profile.gender || "Not provided"}</p>
              </div>
              <div className="item">
                <small>Age</small>
                <p>{age !== null ? age : "Not provided"}</p>
              </div>
              <div className="item">
                <small>Birth country</small>
                <p>{profile.profile.birth_country || "Not provided"}</p>
              </div>
              <div className="item">
                <small>Address</small>
                <p>{profile.profile.address || "Not provided"}</p>
              </div>
              <div className="item">
                <small>Phone number</small>
                <p>{profile.profile.phone_number || "Not provided"}</p>
              </div>
              <div className="full">
                <small>Permissions</small>
                <div className="permissions">
                  {profile?.permissions?.map((permission, index) => (
                    <p className="permission">{permission}</p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ProfileTabs userId={profile.id} />
      </div>
    </div>
  );
};

const ProfileTabs = ({ userId }) => {
  const [activeTab, setActiveTab] = useState("drafts");

  if (activeTab === "drafts") {
    localStorage.removeItem("changeBreadCrumbs");
  }
  useEffect(() => {
    if (localStorage.getItem("setDraftActive") === "drafts") {
      setActiveTab("drafts");
      localStorage.removeItem("setDraftActive");
    }
  }, []);

  return (
    <div className="profile-data">
      <div className="tabs-list">
        <div
          onClick={() => setActiveTab("reports")}
          className={`tab ${activeTab === "reports" ? "active" : ""}`}
        >
          <ListViewIcon size={20} />
          <p> Submitted reports</p>
        </div>
        <div
          onClick={() => {
            setActiveTab("drafts");
            localStorage.removeItem("changeBreadCrumbs");
          }}
          className={`tab ${activeTab === "drafts" ? "active" : ""}`}
        >
          <LayersLogoIcon size={20} />
          <p> Drafts reports</p>
        </div>
        <div
          onClick={() => setActiveTab("complaints")}
          className={`tab ${activeTab === "complaints" ? "active" : ""}`}
        >
          {" "}
          <Sad01Icon size={20} /> <p>Complaints</p>
        </div>
        <div
          onClick={() => setActiveTab("documents")}
          className={`tab ${activeTab === "documents" ? "active" : ""}`}
        >
          <GoogleDocIcon />
          <p>Documents</p>
        </div>
      </div>

      {activeTab === "reports" && (
        <div className="tabs-content">
          <h3>Your reports</h3>
          <ProfileReports userId={userId} />
        </div>
      )}
      {activeTab === "drafts" && (
        <div className="tabs-content">
          <h3>Your drafts</h3>
          <DraftsTab />
        </div>
      )}
      {activeTab === "complaints" && (
        <div className="tabs-content">
          <h3>Your complaints</h3>
          <UserComplaints />
        </div>
      )}
      {activeTab === "documents" && (
        <div className="tabs-content">
          <h3>Your documents</h3>
          <ProfileDocuments />
        </div>
      )}
    </div>
  );
};
const BreadCrumbs = () => {
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link> <ArrowRight01Icon />{" "}
      <Link className="current-page">Profile</Link>
    </div>
  );
};
const ProfilePage = () => {
  return (
    <DashBoardContainer
      content={<ProfilePageContent />}
      breadCrumbs={<BreadCrumbs />}
    />
  );
};

export default ProfilePage;
