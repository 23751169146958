import DateFormatter from "../dateFormatter";

function formatTimeWithAMPM(timeString) {
  // Parse the time string into hours, minutes, and seconds
  const [hoursStr, minutesStr, secondsStr] = timeString.split(":");
  const hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);

  // Calculate AM/PM
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Format the time string
  return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${ampm}`;
}

function DrugReactionOtherInformation({ data }) {
  return (
    <div className="flex-column">
      <div className="flex-column">
        <h2>General Classification of Reaction</h2>
        <small>{data.incident_type_classification || "Not Provided"}</small>
      </div>
      <div className="flex-row">
        <div className="flex-column">
          <h3>Outcome</h3>
          <small>{data?.outcome_type || "Not Provided"}</small>
        </div>
        <div className="flex-column">
          <h3>Description</h3>
          <small>{data?.description || "Not Provided"}</small>
        </div>
        <div className="flex-column">
          <h3>Anaphylaxis /ADR Outcome</h3>
          <small>{data?.anaphylaxis_outcome || "Not Provided"}</small>
        </div>
        <div className="flex-column">
          <h3>Adverse event to be reported to the FDA</h3>
          <small>
            {data?.adverse_event_to_be_reported_to_FDA
              ? "Yes"
              : "No" || "Not Provided"}
          </small>
        </div>
      </div>
      <h3>Notification</h3>
      <div className="flex-row">
        <div className="flex-column">
          <h3>Name of physician notified</h3>
          <small>
            {data?.name_of_physician_notified
              ? `${data?.name_of_physician_notified?.user?.last_name} ${data?.name_of_physician_notified.user?.first_name} `
              : "Not Provided"}
          </small>
        </div>
        <div className="flex-column">
          <h3>Date/Time physcian notified</h3>
          <small>
            {data?.date_physician_was_notified &&
            data?.time_physician_was_notified ? (
              <span>
                <DateFormatter dateString={data?.date_physician_was_notified} />
                , {formatTimeWithAMPM(data?.time_physician_was_notified)}
              </span>
            ) : (
              "Not Provided"
            )}
          </small>
        </div>
      </div>
      <div className="flex-row">
        <div className="flex-column">
          <h3>Name of family notified</h3>
          <small>
            {" "}
            {data.name_of_family_notified
              ? `${data?.name_of_family_notified.user?.last_name} ${data?.name_of_family_notified.user?.first_name} `
              : "Not Provided"}
          </small>
        </div>
        <div className="flex-column">
          <h3>Date/Time family notified</h3>
          <small>
            {data?.date_family_was_notified &&
            data?.time_family_was_notified ? (
              <span>
                <DateFormatter dateString={data?.date_family_was_notified} />,{" "}
                {formatTimeWithAMPM(data?.time_family_was_notified)}
              </span>
            ) : (
              "Not Provided"
            )}
          </small>
        </div>
      </div>

      <div className="flex-row">
        <div className="flex-column">
          <h3>Notified by</h3>
          <small>{data?.notified_by || "Not Provided"}</small>
        </div>
        <div className="flex-column">
          <h3>Brief summary of incident</h3>
          <small
            dangerouslySetInnerHTML={{
              __html: data?.brief_summary_incident || "Not Provided",
            }}
          ></small>
        </div>
      </div>

      <div className="flex-row">
        <div className="flex-column">
          <h3>Immediate action taken</h3>
          <small
            dangerouslySetInnerHTML={{
              __html: data?.immediate_actions_taken || "Not Provided",
            }}
          ></small>
        </div>
        <div className="flex-column">
          <h3>Severity rating</h3>
          <small
            dangerouslySetInnerHTML={{
              __html: data?.severity_rating || "Not Provided",
            }}
          ></small>
        </div>
      </div>
    </div>
  );
}

export default DrugReactionOtherInformation;
