import { ArrowLeft02Icon, Delete01Icon, Delete02Icon, Loading02Icon } from 'hugeicons-react'
import React, { useState } from 'react'
import api from '../../../api'

const DeleteComplaint = ({ name, id, closeForm }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState("")
    const [successMessage, setSuccessMessage] = useState()
    const handleDelete = async () => {
        setIsLoading(true)
        try {
            // delete complaint
            setError("")
            setSuccessMessage("")
            const response = await api.delete(`/complaints/${id}/delete/`)
            if (response.status === 200) {
                setSuccessMessage("Complaint deleted successfully. Reloading the page..")
                setTimeout(() => {
                    closeForm()
                    window.location.reload()
                }, 3000);
            }
        } catch (error) {
            console.error(error)
            if (error.response) {
                setError(error.response.data.message || error.response.data.error || 'Error while deleting the complaint')
            } else (
                setError('Unknown error while deleting the complaint')
            )
        }
        setIsLoading(false)

    }
    return (
        <div className='delete-form'>
            <h3>Delete Complaint</h3>
            <p>Are you sure you want to delete this {name} with id {id}?</p>
            {
                error && <p className='error-message'>{error}</p>
            }
            {
                successMessage && <p className='success-message'>{successMessage}</p>
            }
            <div className="buttons">
                <button onClick={closeForm} type="button" className="tertiary-button"> <ArrowLeft02Icon size={16} /> Cancel</button>
                <button onClick={handleDelete} type="button" className="delete-button">
                    {
                        isLoading ?
                            <>
                                <Loading02Icon className='loading-icon' size={18} /> Deleting
                            </>
                            : <><Delete01Icon size={18} /> Delete</>
                    }
                </button>
            </div>
        </div>
    )
}

export default DeleteComplaint
