import React, { useEffect, useState } from 'react';
import { API_URL } from '../../../api';
import axios from 'axios';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import SuccessMessage from '../../../components/messages/SuccessMessage';
import { Loading03Icon, QrCodeIcon } from 'hugeicons-react';

const MultiFactorAuthPage = () => {
    const [qrCode, setQrCode] = useState("");
    const [username, setUsername] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        const savedUsername = localStorage.getItem('setUsername');
        setUsername(savedUsername);

        const getQRCode = async () => {
            try {
                const response = await axios.get(`${API_URL}/accounts/token/mfa/${savedUsername}`, {
                    responseType: 'arraybuffer', // To handle binary data
                });

                const base64Image = btoa(
                    new Uint8Array(response.data).reduce(
                        (data, byte) => data + String.fromCharCode(byte),
                        ''
                    )
                );
                setQrCode(`data:image/png;base64,${base64Image}`);

                // Extract username if available in the response
                if (response.headers['username']) {
                    setUsername(response.headers['username']);
                }
            } catch (error) {
                console.error(error);
                if (error.response) {
                    setErrorMessage(
                        error.response.data.error ||
                        error.response.data.message ||
                        'Error getting QR code, please try again later'
                    );
                } else {
                    setErrorMessage('Failed to get QR code');
                }
            }
        };

        getQRCode();
    }, []);

    return (
        <div className='mfa-page'>
            <div className="container">
                <div className="instructions">
                    <h1>Scan the QR Code</h1>
                    <p> <strong>CHMC</strong> Requires Multi-Factor Authentication to access Quality Control.
                        Use your authenticator app  to scan this QR code and enable multi-factor authentication for your account.
                    </p>
                    <div className="help">
                        <h4>Steps</h4>
                        <div className="steps">
                            <div>1. Download and install your authenticator app. Download for <a href="">iOS</a> | <a href="">Andoid</a></div>
                            <div>2. Scan the QR code with your authenticator app.</div>
                            <div>3. Enter code from your app in a form below</div>
                            <div>4. Click "Enable MFA" to complete the process. We will log you in automatically</div>
                        </div>
                    </div>
                    <QRCodeForm />
                    {username && <p>Username: {username}</p>}
                    {/* {errorMessage && <ErrorMessage message={errorMessage} />} */}
                </div>
                {username &&
                    <div className="qr-code">
                        {qrCode ? <img src={qrCode} alt="QR Code" /> : <p>Loading...</p>}
                    </div>
                }
            </div>
        </div>
    );
};

export default MultiFactorAuthPage;


export const QRCodeForm = ({ refresh }) => {
    const [qrCode, setQrCode] = useState('');
    const [isQRCodeValid, setIsQRCodeValid] = useState(false);
    const [username, setUsername] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        const savedUsername = localStorage.getItem('setUsername');
        if (!savedUsername) {
            setErrorMessage('Error getting your username, please log in first');
        } else {
            setUsername(savedUsername);
        }
    }, []);

    const handleQrCode = (value) => {
        setQrCode(value);
        setIsQRCodeValid(value.length === 6);
    };

    const handleVerify = async () => {
        setIsLoading(true);
        const credentials = {
            "code": qrCode,
            "email": username
        }
        try {
            const response = await axios.post(`${API_URL}/accounts/token/mfa/${username}/verify/`, credentials);
            if (response.status === 200) {
                localStorage.setItem('access', response.data.access);
                localStorage.setItem('refresh', response.data.refresh);
                localStorage.setItem('userData', JSON.stringify(response.data.user_info));
                localStorage.setItem('activeAccount', JSON.stringify(response.data.user_info.facility));

                if (refresh) {
                    window.location.reload();
                } else {
                    window.location.href = '/';
                }
            }
        } catch (error) {
            console.error('Error verifying QR code:', error);
            setErrorMessage(
                error.response?.data?.message ||
                error.response?.data?.error ||
                'Error logging in. Please try again.'
            );
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {errorMessage && <ErrorMessage message={errorMessage} />}
            {successMessage && <SuccessMessage message={successMessage} />}
            <form className="qr-code-form">
                <label htmlFor="qrCode">Enter code here</label>
                <div className="half">
                    <input
                        value={qrCode}
                        onChange={(e) => handleQrCode(e.target.value)}
                        type="text"
                        name="qrCode"
                        id="qrCode"
                        placeholder="Enter code here"
                    />
                    <button
                        disabled={isLoading || !username || !isQRCodeValid}
                        onClick={handleVerify}
                        type="button"
                        className="primary-button"
                    >
                        {isLoading ? <Loading03Icon className="loading-icon" /> : <QrCodeIcon />}
                        Verify
                    </button>
                </div>
            </form>
        </>
    );
};
