import React from "react";
import "../../../../assets/css/pages/incidents/workplace/workplace.css";

const WorkPlaceDetailsContentTab = ({ data }) => {
  const parseIfNeeded = (value) => {
    if (typeof value === "string") {
      try {
        return JSON.parse(value);
      } catch {
        return value;
      }
    }
    return value;
  };

  const formatIncidentType = (incidentType) => {
    const parsedType = parseIfNeeded(incidentType);
    if (Array.isArray(parsedType)) {
      return parsedType.join("/");
    }
    return parsedType || "Not provided";
  };

  return (
    <div className="incident-details">
      <div className="number-mrn">
        <div className="phone-number">
          <small>Type of incident</small>
          <h5>
            {(() => {
              const parsedValue = parseIfNeeded(data.type_of_incident);
              console.log("parsed value is: ", parsedValue);
              // return Array.isArray(parsedValue)
              //   ? parsedValue.join("/ ") || "-"
              //   : parsedValue || "-";

              if (typeof parsedValue === "string") {
                return parsedValue;
              } else if (typeof parsedValue === "array") {
                if (parsedValue.length > 0) {
                  return parsedValue.join("/ ");
                } else {
                  return parsedValue[0];
                }
              } else if (typeof parsedValue === "object") {
                const objParsedValue = parseIfNeeded(parsedValue);

                if (objParsedValue) {
                  console.log(
                    "This is the real object parsed value: ",
                    objParsedValue
                  );

                  if (objParsedValue.length > 0) {
                    return objParsedValue.join("/ ");
                  } else {
                    return objParsedValue[0];
                  }
                } else {
                  return "Not Provided";
                }
              }
              return "Type";
            })()}
          </h5>
        </div>
        <div className="mrn">
          <small>Physical injury</small>
          <h5>{data.physical_injury_description || "Not provided"}</h5>
        </div>
      </div>
      <div className="mrn">
        <small>Description</small>
        <h5>{data.description || "Not provided"}</h5>
      </div>

      <div className="assaliant">
        <div className="first-assaliant">
          <h3>
            Incident Directed at and Initiated/ <br /> Committed By
          </h3>
        </div>
        {data.initiated_by &&
          data.initiated_by.map((person, index) => (
            <div key={person.id || index}>
              <h3>Assailant {index + 1}</h3>
              <div className="title-fields">
                <div className="individual-ttle">
                  <small>Name</small>
                  <h5>
                    {person?.party?.user?.last_name || "Not provided"}{" "}
                    {person?.party?.user?.first_name || "Not provided"}
                  </h5>
                </div>
                <div className="individual-ttle">
                  <small>Title</small>
                  <h5>{person?.title || "Not provided"}</h5>
                </div>
                <div className="individual-ttle">
                  <small>Phone Number</small>
                  <h5>{person?.party?.phone_number || "Not provided"}</h5>
                </div>
                <div className="individual-ttle">
                  <small>Email</small>
                  <h5>{person?.party?.user?.email || "Not provided"}</h5>
                </div>
                <div className="individual-ttle">
                  <small>Assailant Relationship to Victim(s):</small>
                  <h5>
                    {person?.assailant_relationship_to_patient ||
                      "Not provided"}
                  </h5>
                </div>
                <div className="individual-ttle">
                  <small>Background</small>
                  <h5>{person.assailant_background || "Not provided"}</h5>
                </div>
              </div>
              <br />
              <br />
            </div>
          ))}
      </div>
    </div>
  );
};

export default WorkPlaceDetailsContentTab;
