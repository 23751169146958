import React from "react";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import api from "../../api";
import { API_URL } from "../../api";
import ModifyPageLoader from "../../pages/dashboard/incidents/generalIncident/modify/loader";
import { Link } from "react-router-dom";
import { ArrowRight04Icon } from "hugeicons-react";
import { PieChart } from "@mui/x-charts";
import { Stack } from "@mui/material";
const StaffPieChart = () => {
  const [staffIncidentData, setStaffIncidentData] = useState([]);
  const [failedFetchingOverviewData, setFailedFetchingOverviewData] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchStaffIncidentData = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/overview/staff-incident/`
        );
        if (response.status === 200) {
          console.log(response.data);
          setStaffIncidentData(response.data);
          setIsLoading(false);
        }
      } catch (error) {
        setFailedFetchingOverviewData(true);
        setIsLoading(false);
        console.error(error);
        if (error.response.data) {
          window.customToast.error(error.response.data.message);
        } else {
          window.customToast.error("Failed to fetch incident reports");
        }
      }
    };
    fetchStaffIncidentData();
  }, []);

  const data = [
    {
      label: "Injury",
      value:
        staffIncidentData?.data?.length > 0 &&
        staffIncidentData?.data[0]?.percentage,
      color: "#145C9E",
    },
    {
      label: "Illness",
      value:
        staffIncidentData?.data?.length > 0 &&
        staffIncidentData?.data[1]?.percentage,
      color: "#F87C47",
    },
    {
      label: "Near miss",
      value:
        staffIncidentData?.data?.length > 0 &&
        staffIncidentData?.data[2]?.percentage,
      color: "#208781",
    },
  ];
  function PieChartWithPaddingAngle() {
    return (
      <Stack direction="row" className="pie-chart">
        <PieChart
          series={[
            {
              paddingAngle: 4,
              innerRadius: 70,
              outerRadius: 50,
              data,
            },
          ]}
          margin={{ right: 5 }}
          width={200}
          height={200}
          legend={{ hidden: true }}
        />
        <div className="label-center">
          <span>Incidents</span>
          <h4>
            {staffIncidentData["total incidents"] &&
              staffIncidentData["total incidents"]}
          </h4>
        </div>
      </Stack>
    );
  }
  return isLoading ? (
    <ModifyPageLoader />
  ) : failedFetchingOverviewData ? (
    "Error fetching the data"
  ) : (
    <div className="general-incident-progress">
      <div className="header-row">
        <h4>Staff Incident </h4>
        <Link className="view-details">
          <span>View Details</span> <ArrowRight04Icon />
        </Link>
      </div>
      <div className="pie-chart-container">
        <PieChartWithPaddingAngle />

        <div className="stats">
          <div className="stat">
            <div className="circle blue"></div>
            <span className="label">Injury:</span>
            <span className="value">
              {staffIncidentData?.data?.length > 0 &&
                staffIncidentData?.data[0]?.percentage}
              %
            </span>
          </div>
          <div className="stat">
            <div className="circle orange"></div>
            <span className="label">Illness:</span>
            <span className="value">
              {staffIncidentData?.data?.length > 0 &&
                staffIncidentData?.data[1]?.percentage}
              %
            </span>
          </div>
          <div className="stat">
            <div className="circle green"></div>
            <span className="label">Near miss:</span>
            <span className="value">
              {staffIncidentData?.data?.length > 0 &&
                staffIncidentData?.data[2]?.percentage}
              %
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffPieChart;
