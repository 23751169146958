import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import DashBoardContainer from "../../../dashboardContainer";
import api from "../../../../../api";
import ModifyGrievanceIncident from "../../../../../components/incidents/forms/modiy/grievenceIncident";
import { ArrowLeft01Icon, ArrowTurnBackwardIcon } from "hugeicons-react";
import BackToPage from "../../../../../components/incidents/backToPage";
import { ArrowRight01Icon } from "hugeicons-react";
import { FacilityBreadCrumbs } from "../../drugReactionIncident/modifyMedicalAdverseDrugReactionIncidentPage";

const ModifyGrievanceIncidentPageContent = () => {
  const [error, setError] = useState();
  const [incident, setIncident] = useState({});
  const [investigation, setInvestigation] = useState();
  const { incidentId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchIncidentData = async () => {
      try {
        const response = await api.get(`/incidents/grievance/${incidentId}/`);

        if (response.status === 200) {
          setInvestigation(response.data.investigation);
          setIncident(response.data.incident);
          setIsLoading(false);
          console.log(response.data.incident);
          console.log("weeeee");
        }
      } catch (error) {
        setError("Error getting incident data");
      }
    };
    fetchIncidentData();
  }, []);

  return (
    <div>
      {isLoading
        ? "Loading"
        : incident && (
            <ModifyGrievanceIncident
              data={incident}
              incidentId={incidentId}
              investigation={investigation}
            />
          )}
    </div>
  );
};
const BreadCrumbs = () => {
  const { incidentId } = useParams();
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link> <ArrowRight01Icon />
      <Link to={"/incidents/"}>Incidents</Link> <ArrowRight01Icon />
      <Link to={"/incident/grievance/"}>Grievance List</Link>{" "}
      <ArrowRight01Icon />
      <Link to={`/incident/grievance/${incidentId}/`}>#{incidentId}</Link>{" "}
      <ArrowRight01Icon />
      <Link className="current-page"> Modify</Link>
    </div>
  );
};

const ModifyGrievanceIncidentPage = () => {
  const changeBreadCrumbs = localStorage.getItem("changeBreadCrumbs");
  return (
    <DashBoardContainer
      content={<ModifyGrievanceIncidentPageContent />}
      breadCrumbs={
        changeBreadCrumbs ? <FacilityBreadCrumbs /> : <BreadCrumbs />
      }
    />
  );
};

export default ModifyGrievanceIncidentPage;
