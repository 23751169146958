import React, { useEffect, useState } from 'react'
import api from '../../../../api'
import CustomSelectInput from '../inputs/customSelect'
import { Cancel01Icon, CheckmarkSquare01Icon, FloppyDiskIcon, InformationCircleIcon, Loading02Icon, Locker01Icon, LockIcon, PlusSignIcon, SecurityPasswordIcon, SquareIcon, SquareLockPasswordIcon, UserIcon } from 'hugeicons-react'
import { permissionsList } from '../../../../constants'
import CustomDatePicker from '../inputs/datePicker'
import { useParams } from 'react-router-dom'
import SuccessMessage from '../../../messages/SuccessMessage'
import ErrorMessage from '../../../messages/ErrorMessage'
import WarningMessage from '../../../messages/WarningMessage'

const UpdateUserProfileForm = ({ toggleDepartmentForm }) => {
    const { userId } = useParams()
    const [profile, setProfile] = useState({})
    const [activeTab, setActiveTab] = useState("basic")
    const [showDepartmentsChoices, setShowDepartmentsChoices] = useState(false)
    const [showRolesContainer, setShowRolesContainer] = useState(false)

    const [selectedFacility, setSelectedFacility] = useState()
    const [currentFacility, setCurrentFacility] = useState("")
    const [facilities, setFacilities] = useState([])
    const [selectedFacilities, setSelectedFacilities] = useState([])
    const [fetchingFacilities, setFetchingFacilities] = useState(false)
    const [permissionFacility, setPermissionFacility] = useState({})

    const [departments, setDepartments] = useState([])
    const [selectedDepartments, setSelectedDepartments] = useState([])
    const [fetchingDepartments, setFetchingDepartments] = useState(true)
    const [positions, setPositions] = useState([])

    const [permissions, setPermissions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [updated, setUpdated] = useState(false)

    const [email, setEmail] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")

    const [zipCode, setZipCode] = useState("")
    const [city, setCity] = useState("")
    const [password, setPassword] = useState("")

    const [department, setDepartment] = useState("")
    const [selectedDepartment, setSelectedDepartment] = useState("")
    const [currentDepartment, setCurrentDepartment] = useState("")
    const [userRole, setUserRole] = useState("")
    const [userRoles, setUserRoles] = useState([])
    const [gender, setGender] = useState("")
    const [dateOfBirth, setDateOfBirth] = useState("")
    const [address, setAddress] = useState('')
    const [birthCountry, setBirthCountry] = useState("")
    const [profilePicture, setProfilePicture] = useState("")


    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState()

    const handleSetDepartment = (department) => {
        setDepartment(department)
    }
    useEffect(() => {
        //fetchPositions
        const fetchPositions = async () => {
            try {
                const response = await api.get('/accounts/permissions/all/')
                setPositions(response.data.permissions)
                console.log("positions", response.data.permissions)
            } catch (error) {
                console.error(error)
                setErrorMessage("Error getting positions")
            }
        }



        const fetchProfile = async () => {
            setErrorMessage("");

            try {
                const response = await api.get(`/accounts/profile/${userId}/`);
                const profileData = response.data;
                console.log("user data : ", response.data)
                console.log("user data : ", response.data)
                setProfile(profileData);
                setFirstName(profileData.first_name || "");
                setLastName(profileData.last_name || "");
                setEmail(profileData.email || "");
                setUserRoles(profileData.position.split(", ") || "")
                setBirthCountry(profileData.profile.birth_country || "");
                setPhoneNumber(profileData.profile.phone_number || "");
                setGender(profileData.profile.gender || "");
                setDateOfBirth(profileData.profile.date_of_birth || "");
                setAddress(profileData.profile.address || "");
                setCity(profileData.profile.city || "");
                setZipCode(profileData.profile.zip_code || "");
                setCurrentFacility(profileData.profile.facility || "");
                setCurrentDepartment(profileData.profile.department)
                setSelectedFacilities(profileData.profile.permissions.facilities)
                setSelectedDepartments(profileData.profile.permissions.departments)
            } catch (error) {
                setErrorMessage(
                    error.response?.data?.message || "Error fetching profile data"
                );
                console.log(error);
            }
        };
        fetchProfile();
        fetchPositions()

    }, [])


    const handleFormatDate = (date) => {
        if (!date) return "";
        const splitDate = date.split("-")
        const new_date = `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`
        console.log(new_date)
        return new_date
    }

    useEffect(() => {
        const fetchFacilities = async () => {
            try {
                setFetchingFacilities(true)
                const response = await api.get('/facilities/')
                if (response.status === 200) {
                    setFacilities(response.data.data.map(facility => (
                        {
                            id: facility.id,
                            name: facility.name
                        }
                    )))
                    console.log("facilities", response.data)
                }
                setFetchingFacilities(false)
            } catch (error) {
                setFetchingFacilities(false)
            }
        }
        fetchFacilities()
    }, [])

    const fetchDepartments = async (facilityId) => {
        if (facilityId) {
            try {
                setFetchingDepartments(true)
                const response = await api.get(`/facilities/${facilityId}/departments/`)
                if (response.status === 200) {
                    setDepartments(response.data.departments.map(department => (
                        {
                            id: department.id,
                            name: department.name
                        }
                    )))
                    console.log(response.data.departments)
                    setFetchingDepartments(false)
                }
            } catch (error) {
                console.error(error)
                setFetchingDepartments(false)
            }
        }
    }

    const handleSelectedUserFacility = (facility) => {
        setSelectedFacility(facility)
        console.log(facility)
        fetchDepartments(facility.id)
    }

    const handleAccessToFacilities = async (facility) => {
        console.log(facility)
        if (!selectedFacilities.map(fac => fac.id).includes(facility.id)) {
            setSelectedFacilities([...selectedFacilities, facility]);
            setShowDepartmentsChoices(true)
            fetchDepartments(facility.id)
            setPermissionFacility(facility)
        } else {
            const allDepartments = await fetchFacilityDepartments()
            setSelectedFacilities(allDepartments.filter(dep => dep.id != dep.id))
            setSelectedFacilities(selectedFacilities.filter(prevFacility => prevFacility.id !== facility.id));
        }
    };

    const handleAccessToDepartments = (department) => {
        if (!selectedDepartments.includes(department)) {
            setSelectedDepartments([...selectedDepartments, department]);
            const fac = permissions.filter(prevFac => prevFac = permissionFacility)
        } else {
            setSelectedDepartments(selectedDepartments.filter(prevDepartment => prevDepartment != department));
            // get all departments in this facility, and removed them from the list of selected departments


        }
    }

    const handleSelectedRole = (role) => {
        if (!userRoles.includes(role)) {
            setUserRoles([...userRoles, role]);
        } else {
            setUserRoles(userRoles.filter(prevRole => prevRole != role))
        }
    }


    const handleNewUser = async () => {
        setErrorMessage("")
        setSuccessMessage("")
        const departmentId = departments.find(dept => dept.name === department)?.id

        if (!firstName || !lastName || !email || !userRoles || (!department && !currentDepartment)) {
            setErrorMessage("First name, email, position, department and facility are required ")
            return
        }

        const userData = {
            "first_name": firstName,
            "last_name": lastName,
            "email": email,
            "phone_number": phoneNumber,

            "gender": gender,
            "date_of_birth": dateOfBirth,
            "address": address,
            "city": city,
            "zip_code": zipCode,
            "birth_country": birthCountry,

            "facility": selectedFacility,
            "department_id": department.id || currentDepartment.id,
            "roles": userRoles,
            "permissions": [
                { "facilities": selectedFacilities, },
                { "departments": selectedDepartments, }
            ],
            "password": password
        }

        try {
            setIsLoading(true)

            const response = await api.patch(`/accounts/profile/${userId}/update/`, userData)
            if (response.status === 200) {
                const container = document.getElementById("updateUserForm")
                container.scrollTop = 0
                setSuccessMessage("User is updated successfully")
                setIsLoading(false)
                setUpdated(true)
            }
        } catch (error) {
            if (error.response) {
                setErrorMessage(error.response.data.message || error.response.data.error || 'Error while creating a new user')
            } else {
                setErrorMessage('Unknown error creating a new user')
            }

            console.error(error)
            setIsLoading(false)
            setTimeout(() => {
                setErrorMessage("")
            }, 5000);

        }
    }

    const handleShowDepartmentChoices = (facilityId) => {
        setShowDepartmentsChoices(true)
        setSelectedFacility(facilityId)
    }

    const handleSetPermissions = () => {
        setShowDepartmentsChoices(false)
    }

    const handlePasswordChange = (e) => {
        e.preventDefault();
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
        }, 3000);
    }
    return (
        <div className="form" id='updateUserForm'>
            {
                successMessage && <SuccessMessage message={successMessage} />
            }
            {
                errorMessage && <ErrorMessage message={errorMessage} hideMessage={true} handleHideMessage={() => setErrorMessage("")} />
            }
            <Cancel01Icon className='close-icon' onClick={toggleDepartmentForm} />

            {
                showRolesContainer ?
                    <RolesContainer setShowRolesContainer={setShowRolesContainer} />
                    :
                    <form>
                        {/* <div className="form-tabs">
                            <div onClick={() => setActiveTab('basic')} className={`tab ${activeTab === 'basic' ? 'active' : ''}`}>
                                <UserIcon />
                                <span>Basic info</span>
                            </div>
                            <div onClick={() => setActiveTab('permissions')} className={`tab ${activeTab === 'permissions' ? 'active' : ''}`}>
                                <SquareLockPasswordIcon />
                                <span>Permissions</span>
                            </div>
                            <div onClick={() => setActiveTab('other')} className={`tab ${activeTab === 'other' ? 'active' : ''}`}>
                                <InformationCircleIcon />
                                <span>Other info</span>
                            </div>
                        </div> */}

                        {activeTab === 'basic' &&
                            <> <h3>Basic user information</h3>
                                <div className="half">
                                    <div className="field">
                                        <label htmlFor="">First name <span>*</span></label>
                                        <input value={firstName} type="text" name="firstName" id="firstName" placeholder='Enter user first name' onChange={(e) => setFirstName(e.target.value)} />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="">Last name <span>*</span></label>
                                        <input value={lastName} type="text" name="lastName" id="lastName" placeholder='Enter user last name' onChange={(e) => setLastName(e.target.value)} />
                                    </div>
                                </div>
                                <div className="half">
                                    <div className="field">
                                        <label htmlFor="">Email <span>*</span></label>
                                        <input value={email} type="email" name="email" id="email" placeholder='Enter user email' onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="">Phone number</label>
                                        <input value={phoneNumber} type="tel" name="phoneNumber" id="phoneNumber" placeholder='Enter user phone number' onChange={(e) => setPhoneNumber(e.target.value)} />
                                    </div>
                                </div>
                                <div className="half">

                                </div>
                                <>  <h3>Other user information</h3>
                                    <div className="half">
                                        <div className="field">
                                            <label htmlFor="">Sex</label>
                                            <CustomSelectInput options={["Male", "Female", "Other"]} placeholder={"Select sex"} selected={gender} setSelected={setGender} />
                                        </div>
                                        <div className="field">
                                            <label htmlFor="">Date of birth | {handleFormatDate(dateOfBirth)}</label>
                                            <CustomDatePicker selectedDate={dateOfBirth} setSelectedDate={setDateOfBirth} />

                                        </div>
                                    </div>
                                    <div className="half">
                                        <div className="field">
                                            <label htmlFor="">Address</label>
                                            <input value={address} type="text" name="address" id="address" placeholder='Enter user address' onChange={(e) => setAddress(e.target.value)} />
                                        </div>
                                        <div className="field">
                                            <label htmlFor="">Birth country</label>
                                            <input value={birthCountry} type="text" name="birthCountry" id="birthCountry" placeholder='Enter user birth country' onChange={(e) => setBirthCountry(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="half">
                                        <div className="field">
                                            <label htmlFor="">City</label>
                                            <input value={city} onChange={(e) => setCity(e.target.value)} type="text" name="city" id="city" placeholder='Enter user city' />
                                        </div>

                                        <div className="field">
                                            <label htmlFor="">Zip code</label>
                                            <input value={zipCode} onChange={(e) => setZipCode(e.target.value)} type="text" name="zipCode" id="zipCode" placeholder='Enter user zip code' />
                                        </div>
                                    </div>
                                </>
                                <> {
                                    showDepartmentsChoices
                                        ? <>
                                            <h4>User permissions for {permissionFacility?.name}</h4>
                                            <p>Choose which department(s) this user will access to</p>
                                            <div className="form-choices">
                                                {departments?.map((department, index) => (
                                                    <div key={index} onClick={() => handleAccessToDepartments(department)} className={`choice ${selectedDepartments.includes(department) ? 'checked' : ''}`}>
                                                        {selectedDepartments?.includes(department) ? <CheckmarkSquare01Icon /> : <SquareIcon />}
                                                        <span>{department.name}</span>
                                                    </div>
                                                ))}

                                            </div>
                                        </>
                                        : <>
                                            <h4>User permissions</h4>
                                            <div className="field">
                                                <label htmlFor="">Facility user belongs to <span>*</span> </label>
                                                <small>Currently in {selectedFacility?.name || currentFacility?.name}</small>
                                                <div className="form-choices">
                                                    {
                                                        facilities && facilities.map((fac, index) => (
                                                            <div key={index} onClick={() => handleSelectedUserFacility(fac)} className={`choice ${selectedFacility === fac ? 'selected' : ''}`}>
                                                                {
                                                                    selectedFacility === fac ? <CheckmarkSquare01Icon /> : <SquareIcon />
                                                                }
                                                                <span>{fac.name}</span>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="field">
                                                    <label htmlFor="">Department user belongs to <span>*</span> </label>
                                                    <small> Currently in  {selectedDepartment?.name || currentDepartment?.name}</small>

                                                    <select
                                                        name="department"
                                                        id="department"
                                                        onChange={(e) => handleSetDepartment(
                                                            departments.find(dept => dept.id === parseInt(e.target.value))
                                                        )}
                                                    >
                                                        <option value="">Select department</option>
                                                        {
                                                            departments && departments.map((department) => (
                                                                <option key={department.id} value={department.id}>
                                                                    {department.name}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                <div className="field">
                                                    <label htmlFor="">Position <span>*</span> <span onClick={() => setShowRolesContainer(true)} className='inline-link'>Learn more</span> </label>
                                                    <div className="form-choices">
                                                        {positions.map((position, index) => (
                                                            <div onClick={() => handleSelectedRole(position)} key={index} className={`choice ${userRoles.includes(position) ? 'selected' : ''}`}>
                                                                {
                                                                    userRoles.includes(position) ? <CheckmarkSquare01Icon /> : <SquareIcon />
                                                                }
                                                                {position}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <datalist id="positionsSuggestions">
                                                    </datalist>
                                                </div>
                                            </div>

                                            <h3>Select which facilities this user has access to</h3>
                                            <div className="hospitals form-choices">
                                                {
                                                    facilities && facilities.map((facility, index) => (
                                                        <div onClick={() => handleAccessToFacilities(facility)} key={index} className={`choice ${selectedFacilities.map(fac => fac.id).includes(facility.id) ? 'selected' : ''}`}>
                                                            {selectedFacilities && selectedFacilities.map(fac => fac.id).includes(facility.id) ? <CheckmarkSquare01Icon /> : <SquareIcon />}
                                                            <span>{facility.name}</span>
                                                            {/* <span>| 5</span> */}
                                                        </div>
                                                    ))
                                                }

                                            </div>
                                        </>
                                }
                                </>
                            </>
                        }
                        {/* {activeTab === 'other' &&
                        }
                        {activeTab === 'permissions' &&
                        } */}


                    </form>
            }


            {
                <>{showDepartmentsChoices
                    ? <button onClick={handleSetPermissions} type="button" className="secondary-button">
                        <PlusSignIcon />
                        <span>Set permissions</span>
                    </button>
                    : !showRolesContainer && activeTab === 'security' ? ''
                        : <div className="buttons">
                            {
                                updated &&
                                <button onClick={toggleDepartmentForm} type="button" className="secondary-button">
                                    <Cancel01Icon size={20} />
                                    <span>Close</span>
                                </button>
                            }
                            <button disabled={isLoading} onClick={handleNewUser} type="button" className="primary-button">
                                {
                                    isLoading ? <><Loading02Icon size={20} className='loading-icon' /> Saving</> : <><FloppyDiskIcon size={20} />Update user</>
                                }
                            </button>
                        </div>}

                </>
            }
        </div>
    )
}

export default UpdateUserProfileForm


const RolesContainer = ({ setShowRolesContainer }) => {
    const [roles, setRoles] = useState([])

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await api.get('/accounts/constants/')
                if (response.status === 200) {
                    console.log('permissions: ', response.data.data.permissions)
                    const flattenedPermissions = response.data.data.permissions.flat();
                    setRoles(flattenedPermissions)
                }
            } catch (error) {
                console.error(error)
            }
        }
        fetchRoles()
    }, [])

    return (
        <div className="roles-container">
            <h2>Roles details</h2>
            {roles.length ? (
                <div className="roles">
                    {
                        roles.map((perm, index) => (
                            <div key={index} className="role">
                                <h4>{perm.name}</h4>
                                <p>{perm.description}</p>
                            </div>
                        ))
                    }
                </div>
            ) : (
                <p>No roles available</p>
            )}
            <div onClick={() => setShowRolesContainer(false)} className="secondary-button hide-roles">
                <Cancel01Icon />
                <span>Close roles</span>
            </div>
        </div>
    )
}



const fetchFacilityDepartments = async (facilityId) => {
    if (facilityId) {
        try {
            const response = await api.get(`/facilities/${facilityId}/departments/`)
            if (response.status === 200) {
                return response.data.departments.map(department => (
                    {
                        id: department.id,
                        name: department.name
                    }
                ))
            }
        } catch (error) {
            console.error(error)
            return []
        }
    }
    return []
}