import React, { useEffect, useState } from "react";
import DashBoardContainer from "./dashboardContainer";
import { usePermission } from "../../contexts/permissionsContext";
import {
  Building01Icon,
  DashboardBrowsingIcon,
  Pdf01Icon,
  PlusSignIcon,
  UserGroupIcon,
  UserIcon,
} from "hugeicons-react";
import { Link } from "react-router-dom";
import FormChoicesPopup from "../../components/incidents/forms/incidentFormsChoices";

const WelcomePageContent = () => {
  const permissions = usePermission();
  const [userRole, setUserRole] = useState("");
  const [userData, setUserData] = useState(null);
  const [showReportFormChoices, setShowReportFormChoices] = useState(true);

  const rolePriority = [
    "Admin",
    "Super User",
    "Corporate",
    "Quality - Risk Manager",
    "Director",
    "Manager",
    "User",
    "User Editor",
  ];

  useEffect(() => {
    const userObj = localStorage.getItem("userData");
    if (userObj && userObj !== "undefined") {
      setUserData(JSON.parse(userObj));
    } else {
      console.error("No user data found in localStorage");
    }
  }, []);

  useEffect(() => {
    if (permissions) {
      for (let role of rolePriority) {
        if (permissions.includes(role)) {
          setUserRole(role);
          console.log(role);
          break;
        }
      }
    }
  }, [permissions]);

  const roleCards = {
    Admin: <AdminCard />,
    "Super User": <AdminCard />,
    Corporate: <CorporateCard />,
    "Quality - Risk Manager": <QualityRiskManagerCard />,
    Director: <DirectorCard />,
    Manager: <ManagerCard userData={userData} />,
    User: <UserCard />,
    "User Editor": <UserEditorCard />,
  };

  return (
    <>
      {/* <FormChoicesPopup /> */}
      <div>
        {userData && (
          <div className="welcome-page-content">
            <div className="quick-actions">
              <h1>Hi, {userData.first_name}</h1>
              <p>
                Welcome to the Cohesive Healthcare Management and Consulting
                quality control system. Based on your primary role{" "}
                <strong>{userRole}</strong>, here are quick actions
              </p>
              {roleCards[userRole] || <UserCard />}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const WelcomePage = () => {
  const [formChoicesOpen, setFormChoicesOpen] = useState(false);
  const handleShowFormsChoices = () => {
    setFormChoicesOpen(true);
    console.log(true);
  };

  useEffect(() => {
    handleShowFormsChoices();
  }, [formChoicesOpen]);
  return <DashBoardContainer content={<WelcomePageContent />} />;
};

export default WelcomePage;

const AdminCard = ({ formChoicesOpen, handleShowFormsChoices }) => {
  // handleShowFormsChoices()
  return (
    <div className="card">
      <div className="actions-grid">
        <Link to={"/admin/users/"} className="action">
          <UserGroupIcon className="icon" />
          <span>Manage users</span>
        </Link>
        <Link to={"/facilities/"} className="action">
          <Building01Icon />
          <span>Manage facilities</span>
        </Link>
        {/* <div className="action">
                    <PlusSignIcon />
                    <span>Submit a complaint</span>
                </div>
                <div className="action" onClick={handleShowFormsChoices}>
                    <PlusSignIcon />
                    <span>Submit new incident</span>
                </div> */}
      </div>
    </div>
  );
};

const CorporateCard = ({ formChoicesOpen }) => (
  <div className="card">
    <div className="actions-grid">
      <Link to={"/overview/"} className="action">
        <DashboardBrowsingIcon />
        <span>Overview</span>
      </Link>
      <Link to={"/facilities/"} className="action">
        {/* huge icon library */}
        <Building01Icon />
        <span>View facilities</span>
      </Link>
      {/* <div className="action">
                <PlusSignIcon />
                <span>Submit new incident</span>
            </div>
            <div className="action">
                <PlusSignIcon />
                <span>Submit a complaint</span>
            </div> */}
    </div>
  </div>
);

const DirectorCard = ({ formChoicesOpen }) => (
  <div className="card">
    <div className="actions-grid">
      {/* manage facility | submit new report incident */}
      <Link to={"/facilities/"} className="action">
        {/* huge icon library */}
        <Building01Icon />
        <span>View facility</span>
      </Link>
      <Link to={"/users/profile/"} className="action">
        <UserIcon />
        <span>View profile</span>
      </Link>

      {/* <div className="action">
                <PlusSignIcon />
                <span>Submit new incident</span>
            </div>
            <div className="action">
                <PlusSignIcon />
                <span>Submit a complaint</span>
            </div> */}
    </div>
  </div>
);

const UserCard = ({ formChoicesOpen }) => (
  <div className="card">
    <div className="actions-grid">
      {/* submit new incident | view profile */}
      {/* <div className="action">
                <PlusSignIcon />
                <span>Submit new incident</span>
            </div> */}
      <Link to={"/users/profile/"} className="action">
        <UserIcon />
        <span>View profile</span>
      </Link>

      {/* <div className="action">
                <PlusSignIcon />
                <span>Submit a complaint</span>
            </div> */}
    </div>
  </div>
);

const UserEditorCard = ({ formChoicesOpen }) => (
  <div className="card">
    <div className="actions-grid">
      {/* submit new incident | view profile */}
      {/* <div className="action">
                <PlusSignIcon />
                <span>Submit new incident</span>
            </div> */}
      <Link to={"/admin/users/"} className="action">
        <UserGroupIcon className="icon" />
        <span>Manage users</span>
      </Link>

      {/* <div className="action">
                <PlusSignIcon />
                <span>Submit a complaint</span>
            </div> */}
    </div>
  </div>
);
const ManagerCard = ({ formChoicesOpen, userData }) => (
  <div className="card">
    <div className="actions-grid">
      {/* view reports | submit new report | view profile */}
      <Link to={`/facilities/${userData.facility.id}/`} className="action">
        <Pdf01Icon />
        <span>View reports</span>
      </Link>

      <Link to={"/users/profile/"} className="action">
        <UserIcon />
        <span>View profile</span>
      </Link>

      {/* <div className="action">
                <PlusSignIcon />
                <span>Submit new incident</span>
            </div>
            
            <div className="action">
                <PlusSignIcon />
                <span>Submit a complaint</span>
            </div> */}
    </div>
  </div>
);

const QualityRiskManagerCard = ({ formChoicesOpen }) => (
  <div className="card">
    <div className="actions-grid">
      {/* view reports | view profile | manage incidents */}
      <Link to={"/incidents/"} className="action">
        <Pdf01Icon />
        <span>View reports</span>
      </Link>
      <Link to={"/users/profile/"} className="action">
        <UserIcon />
        <span>View profile</span>
      </Link>
      {/* <div className="action">
                <PlusSignIcon />
                <span>Submit new incident</span>
            </div>
            <div className="action">
                <PlusSignIcon />
                <span>Submit a complaint</span>
            </div> */}
    </div>
  </div>
);
