import React from "react";
import api from "../../api";
import { API_URL } from "../../api";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { ArrowRight04Icon } from "hugeicons-react";
import ModifyPageLoader from "../../pages/dashboard/incidents/generalIncident/modify/loader";
const WorkplaceChart = () => {
  const [failedFetchingOverviewData, setFailedFetchingOverviewData] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [workplaceData, setWorkplaceData] = useState([]);

  useEffect(() => {
    const fetchWorplaceData = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/overview/workplace-violence-incident/`
        );
        if (response.status === 200) {
          console.log("Workplace", response.data);
          setWorkplaceData(response.data);
          setIsLoading(false);
        }
      } catch (error) {
        setFailedFetchingOverviewData(true);
        setIsLoading(false);
        console.error(error);
        if (error.response.data) {
          window.customToast.error(error.response.data.message);
        } else {
          window.customToast.error("Failed to fetch incident reports");
        }
      }
    };
    fetchWorplaceData();
  }, []);
  return isLoading ? (
    <ModifyPageLoader />
  ) : failedFetchingOverviewData ? (
    "Error fetching the data"
  ) : (
    <div className="general-incident-progress">
      <div className="header-row">
        <h4>Workplace Violence Incidents </h4>
        <Link className="view-details">
          <span>View Details</span> <ArrowRight04Icon />
        </Link>
      </div>
      <div className="workplace-row">
        <span>Total Incidents:</span>
        <h4>{workplaceData?.total_incidents}</h4>
      </div>
      <div className="incident-blocks">
        <div
          className="block blue"
          style={{ width: `${workplaceData?.data[0]?.percentage + 10}%` }}
        >
          {workplaceData?.data[0]?.percentage}%
        </div>
        <div
          className="block green"
          style={{ width: `${workplaceData?.data[1]?.percentage + 10}%` }}
        >
          {workplaceData?.data[1]?.percentage}%
        </div>
        <div
          className="block light-blue"
          style={{ width: `${workplaceData?.data[2]?.percentage + 10}%` }}
        >
          {workplaceData?.data[2]?.percentage}%
        </div>
        <div
          className="block light-orange"
          style={{ width: `${workplaceData?.data[3]?.percentage + 10}%` }}
        >
          {workplaceData?.data[3]?.percentage}%
        </div>
        <div
          className="block orange"
          style={{ width: `${workplaceData?.data[4]?.percentage + 10}%` }}
        >
          {workplaceData?.data[4]?.percentage}%
        </div>
      </div>
      <div className="labels">
        <div className="label-col">
          <div className="label">
            <div className="circle blue"></div>
            <span>Type 1 (Criminal Intent/External)</span>
          </div>
          <div className="label">
            <div className="circle green"></div>
            <span>Type 2 (Patient/Family/Guest)</span>
          </div>
          <div className="label">
            <div className="circle light-blue"></div>
            <span>Type 3 (Worker on Worker)</span>
          </div>
        </div>
        <div className="label-col">
          <div className="label">
            <div className="circle light-orange"></div>
            <span>Type 4 (Domestic/Intimate Parter)</span>
          </div>
          <div className="label">
            <div className="circle orange"></div>
            <span>Type 5 (ideological)</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkplaceChart;
