import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashBoardContainer from "../../../dashboardContainer";
import api from "../../../../../api";
import toast from "react-hot-toast";
import ModifyEmployeeIncidentForm from "../../../../../components/incidents/forms/modiy/employeeIncident";
import ModifyPageLoader from "./loader";
import ModifyGrievanceInvestigationForm from "../../../../../components/incidents/forms/modiy/modifyGrievanceInvestigation";
import { ArrowRight01Icon } from "hugeicons-react";
import { Link } from "react-router-dom";
import { FacilityBreadCrumbs } from "../../drugReactionIncident/modifyMedicalAdverseDrugReactionIncidentPage";
const ModifyGrievanceInvestigationFoundPageContent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [incident, setIncident] = useState({});
  const { incidentId } = useParams();

  useEffect(() => {
    const fetchIncident = async () => {
      setIsLoading(true);

      try {
        const response = await api.get(
          `/incidents/grievance-investigation/${incidentId}/`
        );

        if (response.status === 200) {
          setIncident(response.data);
          setIsLoading(false);
          console.log("heeyeeyyeyeye");
          console.log("Real data ", response.data);
        }
      } catch (error) {
        if (error.response) {
          window.customToast.error(
            error.response.data.error ||
              error.response.data.message ||
              "Error while fetching incident"
          );
        } else {
          window.customToast.error("Unknown error fetching incident");
        }
        setIsLoading(false);
      }
    };
    fetchIncident();
  }, []);

  return isLoading ? (
    <ModifyPageLoader />
  ) : (
    incident && (
      <ModifyGrievanceInvestigationForm
        data={incident}
        incidentId={incidentId}
      />
    )
  );
};

const BreadCrumbs = () => {
  const { incidentId } = useParams();
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link> <ArrowRight01Icon />
      <Link to={"/incidents/"}>Incidents</Link> <ArrowRight01Icon />
      <Link to={"/incident/grievance/investigation/"}>
        Grievance Investigation List
      </Link>{" "}
      <ArrowRight01Icon />
      <Link to={`/incident/grievance-investigation/${incidentId}/`}>
        #{incidentId}
      </Link>{" "}
      <ArrowRight01Icon />
      <Link className="current-page"> Modify</Link>
    </div>
  );
};

const ModifyGrievanceInvestigationFoundPage = () => {
  const changeBreadCrumbs = localStorage.getItem("changeBreadCrumbs");
  return (
    <DashBoardContainer
      content={<ModifyGrievanceInvestigationFoundPageContent />}
      breadCrumbs={
        changeBreadCrumbs ? <FacilityBreadCrumbs /> : <BreadCrumbs />
      }
    />
  );
};

export default ModifyGrievanceInvestigationFoundPage;
